const API_ENDPOINT = 'https://www.love4you2.com/datingapp_api/public/api';

export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, result } = data;
  console.log('Monir...' + result);
  if (result.error) {
    // show error in payment form
    cb(result);
  } else {
    const paymentResponse = await stripePayment({
      payment_method_id: result.paymentMethod.id,
      name: result.paymentMethod.billing_details.name,
      email: result.paymentMethod.billing_details.email,
      amount: amount
    });
    console.log('Payment Response...' + paymentResponse);
    cb(paymentResponse);
  }
}

// place backend API call for payment
const stripePayment = async data => {
    console.log('data' + data);
   const res = await fetch(`${API_ENDPOINT}/paymentstripe`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return await res.json(); 
}