import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'reactstrap';


class AddMyvideo extends React.Component {

    constructor() {
        super();
        this.state = {
            selectedFile: null,
            email: localStorage.getItem("emailaddress"),
            loggdin: '',
            fields: {},
            errors: {},
            dataviewprofileimage: [],
            dateviedo:[]
        }

    }

    componentDidMount() {

        axios.get(base.BASE_URL + '/viewprofileimage/' + this.state.email).then(res => {
            this.setState({ dataviewprofileimage: res.data });
        });

        axios.get(base.BASE_URL + '/allvideouser').then(res => {
            this.setState({ dateviedo: res.data });
        });


    }
    render() {
        if (this.state.loggdin) {
            return <Redirect to="/profile" />
        }
        return (
            <div>
                <Header />
                <div className="main_content">
                    <div className="mcontainer">
                    <div class="flex justify-between items-center relative md:mb-4 mb-3">
                    <div class="flex-1">
                        <h2 class="text-2xl font-semibold"> Add your own video </h2>
                        <nav class="responsive-nav border-b md:m-0 -mx-4">
                            <ul>
                                <li class="active"><a href="#" class="lg:px-2">  Video  </a></li>
                           
                            </ul>
                        </nav>
                    </div>
                    <Link to="/Videoupload" uk-toggle class="flex items-center justify-center z-10 h-10 w-10 rounded-full bg-blue-600 text-white absolute right-0"
                    data-tippy-placement="left" title="Create New Album">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                    </Link>
                </div>

                        <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">
                        
                        {this.state.dateviedo.map((result) => {
                                return (
<div>
  <div className="grid-cols-6">
  
  <iframe
      width="853"
      height="480"
      src={result.videourl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  {result.notes}
  {result.profileurl}
</div>
</div>


                                )
                            })}



{/* 
                            {this.state.dataviewprofileimage.map((result) => {
                                return (
<div>
  <div className="bg-green-400 max-w-full lg:h-56 h-48 rounded-lg relative overflow-hidden shadow uk-transition-toggle">
    <img src={`${base.IMAGE_URL}/${result.com_logo}`} className="w-full h-full absolute object-cover inset-0" />
    <div className="-bottom-12 absolute bg-gradient-to-b from-transparent h-1/2 to-gray-800 uk-transition-slide-bottom-small w-full" />
    <div className="absolute bottom-0 w-full p-3 text-white uk-transition-slide-bottom-small flex items-center">
      <div className="flex-1"> 
        <div className="text-lg"> {result.firstname} {result.lastname}</div>
        <div className="flex space-x-3 lg:flex-initial text-sm">
          <a href="#">  Like</a>
          <a href="#">  Comment </a>
          <a href="#">  Share </a> 
        </div>
      </div> 
      <i className="btn-down text-2xl uil-cloud-download px-1" />
    </div>
  </div>
</div>

                                )
                            })} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddMyvideo;