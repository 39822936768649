import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';


export class Forgetpassword extends Component {
  constructor() {
    super();
  //  let loggdin = false;
    this.register = this.register.bind(this);
    this.state = {
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {},
      datauserprofile: [],
      image: 'maleavter.png',
      loggdin:'stop'
    }
  }
  componentDidMount() {
    // alert(this.state.email);

  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    // alert(this.state.fields["email"]);

    if (!this.state.fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof this.state.fields["email"] !== "undefined") {
      let lastAtPos = this.state.fields["email"].lastIndexOf('@');
      let lastDotPos = this.state.fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (this.state.fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  register() {
    if (this.handleValidation()) {
      // alert("Form submitted");
      //////////////////////////////////////////////////////////////////////
      fetch(base.BASE_URL + '/forgetpassword', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.fields["email"]
        })
      }).then((Response) => Response.json())
        .then((Result) => {
          if (Result.message == 'emailfound') {
            alert('Your password is sended to your email. please check inbox or spam');

            this.setState({ loggdin: 'start' })
          }
          else {
            alert('Email Address not Found')
          }

        })

      /////////////////////////////////////////////////////////////////////
    } else {
      // alert("Form has errors.")
    }


  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    if (this.state.loggdin == 'start') {
      return <Redirect to="/Login" />
    }
    return (
      /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/
      <div>

<div className="lg:flex max-w-5xl min-h-screen mx-auto p-6 py-10">
  <div className="flex flex-col items-center lg: lg:flex-row lg:space-x-10">
    <div className="lg:mb-12 flex-1 lg:text-left text-center">
      <img src="assets/images/logo.png" alt className="lg:mx-0 lg:w-52 mx-auto w-40" />
      <p className="font-medium lg:mx-0 md:text-2xl mt-6 mx-auto sm:w-3/4 text-xl"> Connect with friends and the world around you on Socialite.</p>
    </div>
    <div className="lg:mt-0 lg:w-96 md:w-1/2 sm:w-2/3 mt-10 w-full">
      <Form className="p-6 space-y-4 relative bg-white shadow-lg rounded-lg"> 
      <h3>Forget Password</h3>
      <p>Enter Your Email address and get recovery password link into your email.</p>
        <input type="email" placeholder="Email or Phone Number" className="with-border" 
         onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}
        />
         <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
{/*         <input type="password" placeholder="Password" className="with-border" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}/>
        <span style={{ color: "red" }}>{this.state.errors["password"]}</span> */}
        <button type="button" className="bg-blue-600 font-semibold p-3 rounded-md text-center text-white w-full" onClick={this.register}>
         Submit
        </button>
        <Link to="/Login" className="text-blue-500 text-center block"> Back to Login </Link>
        <hr className="pb-3.5" />
        <div className="flex">
          <Link to="/Registration" type="button" className="bg-green-600 hover:bg-green-500 hover:text-white font-semibold py-3 px-5 rounded-md text-center text-white mx-auto" uk-toggle>
            Create New Account
          </Link>
        </div>
      </Form>
{/*       <div className="mt-8 text-center text-sm"> <a href="#" className="font-semibold hover:underline"> Create a Page </a> for a celebrity, band or business </div> */}
    </div>
  </div>
</div>


{/* This is the modal */}
<div id="register" uk-modal>
  <div className="uk-modal-dialog uk-modal-body rounded-xl shadow-2xl p-0 lg:w-5/12">
    <button className="uk-modal-close-default p-3 bg-gray-100 rounded-full m-3" type="button" uk-close />
    <div className="border-b px-7 py-5">
      <div className="lg:text-2xl text-xl font-semibold mb-1"> Forget Password</div>
      <div className="text-base text-gray-600"> It’s quick and easy.</div>
    </div>
    <form className="p-7 space-y-5">
      <div className="grid lg:grid-cols-2 gap-5">
        <input type="text" placeholder="Your Name" className="with-border" />
        <input type="text" placeholder="Last  Name" className="with-border" />
      </div>
      <input type="email" placeholder="Info@example.com" className="with-border" />
      <input type="password" placeholder="******" className="with-border" />
      <div className="grid lg:grid-cols-2 gap-3">
        <div>
          <label className="mb-0"> Gender </label>
          <select className="selectpicker mt-2 with-border">
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>
        <div>
          <label className="mb-2"> Phone: optional</label>
          <input type="text" placeholder="+543 5445 0543" className="with-border" />
        </div>
      </div>
      <p className="text-xs text-gray-400 pt-3">By clicking Sign Up, you agree to our
        <a href="#" className="text-blue-500">Terms</a>, 
        <a href="#">Data Policy</a> and 
        <a href="#">Cookies Policy</a>. 
        You may receive SMS Notifications from us and can opt out any time.
      </p>
      <div className="flex">
        <button type="button" className="bg-blue-600 font-semibold mx-auto px-10 py-3 rounded-md text-center text-white">
          Get Started
        </button>
      </div>
    </form>
  </div>
</div>


      </div>
    )
  }
}

export default Forgetpassword