import React, { useState } from "react";

import Paypal from "./Paypal";
import Header from '../Layout/Header'
import { Button, Row, Col, Container } from 'react-bootstrap';

function Paypalpage() {
  const [checkout, setCheckOut] = useState(false);

  return (
    <div>
      <Header />
      <div className="main_content">
      <div className="mcontainer">
      {checkout ? (
    <div className="grid-cols-6">
        <Paypal />

        </div>
      ) : (

        <div className="grid-cols-6">

              <h3 style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
            
                Your Selected {localStorage.getItem("packagename")}   {localStorage.getItem("packageprice")} USD

              </h3>

              <button
                onClick={() => {
                  setCheckOut(true);
                }} style={{ backgroundColor: '#bd3434', color: '#ffffff', 
                width: 150, borderRadius: 15, padding: 8, height: 40, marginTop: 20 }}
              >
                Pay with Paypal
              </button>

        </div>

      )}
       </div>
      </div>
    </div>
  );
}

export default Paypalpage;