//import React, { Component } from 'react';
import React, {useEffect,useState} from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Inboxdetails from "./Inboxdetails";
import Loader from "./Loader";
import EndMsg from "./EndMsg";

import InfiniteScroll from 'react-infinite-scroll-component';

//https://www.codecheef.org/article/laravel-react-js-pagination-example-from-scratch
//export default class findpeople extends Component {
  function Inbox() {
    let history = useHistory();
    const [state, setData] = useState({users: ''});
    const [email, setEmail] = useState(localStorage.getItem("emailaddress"));
   // const [pageNumber, setPageNumber] = useState({pageNumber: '1'});



    const [items, setItems] = useState([]);

    const [hasMore, sethasMore] = useState(true);
  
    const [page, setpage] = useState(1);

    //https://react-hook-form.com/get-started
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
    } = useForm();


    const onSubmit = async (data, pageNumber) => {
   //   alert(pageNumber);
      console.log(data);
    //  fetchData();
    //history.push("/Createpost");
    history.push("/Findbyage/" + data.fromage + "/" + data.toage);
    //return <Redirect to="/Createpost" />
    //window.location.href = "Findbyage/" + data.fromage + "/" + data.toage + "";
    }; // your form submit function which will invoke after successful validation




  useEffect(() => {
    const getComments = async () => {
      const res = await fetch(
        base.BASE_CHATURL + `/inbox.php?page=${page}&limit=18&email=${email}`
      );
      const data = await res.json();
     // alert(data.length);
      if(data == 'No Data Found')
      {
        return
      }
      else
      {
        setItems(data);
      }
      
    };

    getComments();
  }, []);

  const fetchComments = async () => {
    const res = await fetch(  base.BASE_CHATURL + `/inbox.php?page=${page}&limit=18&email=${email}`);
    const data = await res.json();
    return data;
  };

  const fetchData = async () => {
    const commentsFormServer = await fetchComments();
   // alert(commentsFormServer.length);
    setItems([...items, ...commentsFormServer]);
    
    //if (commentsFormServer.length === 0 || commentsFormServer.length < 20) {
      if (commentsFormServer.length === 0 || commentsFormServer.length < 18) {
      sethasMore(false);
    }
    setpage(page + 1);
  };


    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            {/* Main people List */}


            <div class="flex justify-between items-center relative md:mb-4 mb-3">
              <div class="flex-1">
              <h2 class="text-2xl font-semibold"> Message Inbox </h2>
              <table style={{ width: '100%' }}>
                  <tr>
                    <td>All Inobx</td>
                  </tr>
                </table>
              </div>

            </div>

         
        {/*   <div className="card col-sm-12"> */}

<InfiniteScroll
      dataLength={items.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
/*       loader={items.map == 'No Data Found' ? '' : <Loader />} */
      endMessage={<EndMsg />}
    >
         <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">
{/*       <div className="container">
        <div className="row m-2"> */}
        {
          items.map.length == 13 ?
         'ffffff'
          :
         
          items.map((item) => {
            return <Inboxdetails key={item.id} item={item}/>
          })
        }
         
            </div>
{/*         </div>
      </div> */}
    </InfiniteScroll>

        

          </div>
    
      </div>
      </div>

    );
  }
  export default Inbox;
