import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'reactstrap';


class Dashboradimage extends React.Component {

    constructor() {
        super();
        this.handlecity = this.handlecity.bind(this);
        this.handlecountry = this.handlecountry.bind(this);
        this.handlestate = this.handlestate.bind(this);
        this.state = {
            selectedFile: null,
            email: localStorage.getItem("emailaddress"),
            loggdin: '',
            fields: {},
            errors: {},
            file: null,
            country: '',
            countrylist: [],
            statelist: [],
            states: '',
            citylist: [],
            statesname: '',
            countryname: '',
            cityname: '',
            city: '',
            uploadstatus:''
        }

    }
    componentDidMount() {
       // alert(this.state.email)
       axios.get(base.BASE_URL + '/countrylist').then(result => {
        this.setState({ countrylist: result.data });
      });
  
    }
    handlecountry(event) {
        this.setState({
          country: event.target.value
        })
    
        this.setState({ statelist: [] });
        ///////////////////////////////
        axios.get(base.BASE_URL + '/statelist/' + event.target.value).then(result => {
          this.setState({ statelist: result.data });
        });
        ///////////////////////////////
      }
      handlestate(event) {
        this.setState({
          states: event.target.value
        })
        this.setState({ citylist: [] });
        //////////////////////////////////
        axios.get(base.BASE_URL + '/citieslist/' + event.target.value).then(result => {
          this.setState({ citylist: result.data });
        });
        ////////////////////////////////
    
      }
      handlecity(event) {
        this.setState({
          city: event.target.value
        })
    
      }
    
    fileSelect = event => {
        this.setState({ selectedFile: event.target.files[0], file : URL.createObjectURL(event.target.files[0]) })
        console.log(URL.createObjectURL(event.target.files[0]))
    }
    fileUpload = () => {
        const fd = new FormData();
        fd.append('image', this.state.selectedFile, this.state.selectedFile.name);
        fd.append('email', this.state.email);
        fd.append('aboutme', this.state.fields["aboutme"]);
        fd.append('lookingfor', this.state.fields["lookingfor"]);
        fd.append('country', this.state.country)
        fd.append('states', this.state.states)
        fd.append('city', this.state.city)
        axios.post(base.BASE_URL + '/registration_facebookimage', fd
        ).then(res => {
            // alert(res.message);
            if (res.status == '200') {

                this.setState({ loggdin: true, uploadstatus: 'ok' })
               // alert('successfully');
            }
            else {
                alert('Failed To Upload')
            }
            console.log(res);
        }
        );

    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    /*     onSubmit(){
            alert(this.state.selectedImage);
            const formData = { image: this.state.selectedImage }
          //  let endpoint = "http://192.168.0.102/datingapp_api/image/imageupload.php";
            let endpoint = base.BASE_URL + "/profileimageupload";
             axios.post(endpoint, formData, {
             }).then((res) => {
                console.log('File uploaded!');
            })
        }
      */
    render() {
        if (this.state.email == null) {
            return <Redirect to="/login" />
        }
        if (this.state.uploadstatus == 'ok') {
            return <Redirect to="/Dashboard" />
        }
        return (
            <div>
                {/*  <Header /> */}
                <div className="main_content">
                    <div className="mcontainer">
                        <div className="" style={{ backgroundColor: '#fff' }}>

                            <div className="text-center py-3 border-b">
                                <h3 className="text-lg font-semibold" style={{ textAlign: 'left', marginLeft: 10 }}>
                                    Complete Your Profile Information
                                </h3>
                                <button className="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" />
                            </div>
                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 10 }}>
                            <p>Upload Your Profile Picture</p>
                                </div>
                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 100 }}>
                               
                               <input type="file" accept="image/*" onChange={this.fileSelect} title="Choose your profile picture" />
                              

                                <img src={this.state.file} style={{ height: 50, width: 50 }}/>

                            </div>
                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 200 }}>

                                <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                                    rows={5} placeholder=" write something about your self"
                                    style={{ borderWidth: 1, backgroundColor: '#eaeaea' }}
                                    onChange={this.handleChange.bind(this, "aboutme")} value={this.state.fields["aboutme"]}
                                />

                            </div>

                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 200 }}>

                                <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                                    rows={5} placeholder=" What are you looking for  "
                                    style={{ borderWidth: 1, backgroundColor: '#eaeaea' }}
                                    onChange={this.handleChange.bind(this, "lookingfor")} value={this.state.fields["lookingfor"]}
                                />

                            </div>
                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 200 }}>
                            <table style={{ width: '100%' }}>
                  <tr>
                    <td>Country</td><td>State</td><td>City</td>
                  </tr>
                  <tr>
                    <td>
                      <select className="with-border" onChange={this.handlecountry}>


                        <option selected value={this.state.country}>{this.state.countryname}</option>
                        {
                          this.state.countrylist.map((result) => {
                            return (

                              <option value={result.id}>{result.name}</option>

                            )
                          })}
                      </select>
                    </td>
                    <td>
                      <select className="with-border" onChange={this.handlestate}>
                        <option selected value={this.state.states}>{this.state.statesname}</option>
                        {
                          this.state.statelist.map((result) => {
                            return (
                              <option value={result.id}>{result.name}</option>
                            )
                          })}
                      </select>

                    </td>
                    <td>
                      <select className="with-border" onChange={this.handlecity}>
                        <option selected value={this.state.city}>{this.state.cityname}</option>
                        {
                          this.state.citylist.map((result) => {
                            return (
                              <option value={result.id}>{result.name}</option>
                            )
                          })}
                      </select>
                    </td>
                  </tr>
                </table>

                            </div>

                            <div className="bsolute bottom-0 p-4 space-x-4 w-full">
                                <button onClick={this.fileUpload} 
                                className="bg-green-600 hover:bg-green-500 hover:text-white font-semibold py-3 px-5 rounded-md text-center text-white mx-auto">Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboradimage;