import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';

import * as base from "../global";
import Sliderbar from "./Sliderbar";
import Footer from "../Footer";



export default class Freedating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      automemberid: this.props.match.params.id,
      seourl: this.props.match.params.seourl,
      bloglist:[]
    };
  }
  componentDidMount() {

    axios.get(base.BASE_URL + '/blogsingle/' + this.props.match.params.id).then(res => {
      this.setState({ bloglist: res.data });
    });

  }

  render() {
  
    return (
      <div>
        <div className="bg-white py-4 shadow dark:bg-gray-800">
          <div className="max-w-6xl mx-auto">
            <div className="flex items-center lg:justify-between justify-around">
              <Link to="/">
                <img src="./assets/images/logo.png" alt="" class="w-32" />
              </Link>
              <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                <Link to="/Login" className="py-3 px-4"> Login</Link>
                <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                  style={{ backgroundColor: '#b50a0a' }}
                >Register</Link>
              </div>
            </div>
          </div>
        </div>

        <div className=''>
          <div class="flex flex-row">
            <div style={{ width: '60%', marginLeft: '8%' }}>
              <h1 style={{ fontSize: 22, marginTop: 20}}> {this.state.seourl} </h1>

              {this.state.bloglist.map((result) => {
                
                return (
               
                    <div style={{ marginBottom: 15, whiteSpace: 'pre-line' }}>
                    {result.description} 
                    </div>
                
                )
              })}


            </div>
            <div style={{ width: '22%', padding: 10 }}>
              <h3 style={{ marginBottom: 20, fontSize: 24 }}>Recent Blog</h3>
              <Sliderbar />
            </div>
          </div>

        </div> {/* End Contained */}
        <Footer />
      </div>
    );
  }
}

