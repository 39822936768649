import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'reactstrap';


class Photopost extends React.Component {

    constructor() {
        super();
        this.state = {
            selectedFile: null,
            email: localStorage.getItem("emailaddress"),
            loggdin: '',
            fields: {},
            errors: {},
            preview:''
        }

    }

    fileSelect = event => {
        this.setState({ selectedFile: event.target.files[0] })
        console.log(event.target.files[0])
         // image preview
 var file = event.target.files[0];
 var reader = new FileReader();
 var url = reader.readAsDataURL(file);

  reader.onloadend = function (e) {
     this.setState({
      preview: [reader.result]
     })
   }.bind(this);
 console.log(url) // Would see a path?
 // end image previou
    }
    fileUpload = () => {
        if(this.state.selectedFile == null)
        {
          alert('Insert the Image');
        }
        else
        {
        const fd = new FormData();
        fd.append('image', this.state.selectedFile, this.state.selectedFile.name);
        fd.append('email', this.state.email);
        fd.append('notes', this.state.fields["notes"]); 
        axios.post(base.BASE_URL + '/photopost', fd
        ).then(res => {
            // alert(res.message);
            if (res.status == '200') {

                this.setState({ loggdin: true })
            }
            else {
                alert('Failed To Upload')
            }
            console.log(res);
        }
        );
    }

    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
      }

    /*     onSubmit(){
            alert(this.state.selectedImage);
            const formData = { image: this.state.selectedImage }
          //  let endpoint = "http://192.168.0.102/datingapp_api/image/imageupload.php";
            let endpoint = base.BASE_URL + "/profileimageupload";
             axios.post(endpoint, formData, {
             }).then((res) => {
                console.log('File uploaded!');
            })
        }
      */
    render() {
        if (this.state.loggdin) {
            return <Redirect to="/profile" />
        }
        return (
            <div>
                <Header />
                <div className="main_content">
                    <div className="mcontainer">
                        <div className="" style={{ backgroundColor: '#fff' }}>

                            <div className="text-center py-3 border-b">
                                <h3 className="text-lg font-semibold" style={{ textAlign: 'left', marginLeft: 10 }}>
                                    Photo Post
                                </h3>
                                <button className="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" />
                            </div>
                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 100 }}>
  <input type="file" onChange={this.fileSelect} />

{
this.state.preview == '' ?
''
:
<img src={this.state.preview} style={{ width: 100, height: 100 }} /> 
}


                            </div>
                            <div className="flex flex-1 items-start space-x-4 p-5" style={{ height: 200 }}>
                                
                              


                                <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                                    rows={5} placeholder=" write something "
                                    style={{ borderWidth: 1, borderColor:'silver' }}
                                    onChange={this.handleChange.bind(this, "notes")} value={this.state.fields["notes"]}
                                />

                            </div>
                            <div className="bsolute bottom-0 p-4 space-x-4 w-full">
                                <button onClick={this.fileUpload} className="bg-green-600 hover:bg-green-500 hover:text-white font-semibold py-3 px-5 rounded-md text-center text-white mx-auto">POST</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Photopost;