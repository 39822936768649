import React, { useEffect, useState } from "react";
import {auth,provider} from "./firebase";
import {signInWithPopup} from "firebase/auth";
//import Home from "./Home";
import axios from 'axios';
import * as base from "./global";
import { useHistory } from 'react-router-dom';

const GoogleLogin =() => {
  const history = useHistory();

    const [value,setValue] = useState('')
    const [isloading, setIsloading] = useState(false)
    const handleClick =()=>{
     // alert('')
     setIsloading(true)

        signInWithPopup(auth,provider).then((data)=>{
           // setValue(data.user.email)
           //firstName lastName fullName email
          //  console.log(JSON.stringify(data))
          //  alert(data._tokenResponse.firstName)
           // localStorage.setItem("email",data.user.email)
           const formdata = {
            firstname: data._tokenResponse.firstName,
            lastname: data._tokenResponse.lastName,
            email: data._tokenResponse.email,
            password: 'abc999793',
            gender: '',
            xdate: '01',
            month: '01',
            year: '2023',
            interestedin: ''
          }
          axios.post(base.BASE_URL + "/usersignup", formdata, {
          }).then(res => {
            console.log('KKKKKK :' + res);
            console.log('data....' + res.data)
            console.log('data..file..' + res.data.message);
            //res.statusText 
            if (res.data.message == 'Registration completed successfully') {
              localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
              localStorage.setItem("emailaddress", data.user.email)
    
              setIsloading(false)
              history.push('/Dashboard');

            }
            else if (res.data.message == 'email already exits') {
              setIsloading(false)
              //alert('Email is Already exit, please try to login')
             // history.push('/Dashboard'); //Dashboradimage
             localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
             localStorage.setItem("emailaddress", data.user.email)
   
             setIsloading(false)
             history.push('/Dashboard');

            }
            else {
              setIsloading(false)
              alert('Failed');
            }
          })
    

        }).catch((error) => {
          alert('Error ' + error)
          // Handle Errors here.
         // const errorCode = error.code;
         // const errorMessage = error.message;
          // The email of the user's account used.
         // const email = error.customData.email;
          // The AuthCredential type that was used.
         // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    }

    useEffect(()=>{
        //setValue(localStorage.getItem('email'))
    })

return (
    <div>
       {/*  {value?<Home/>: */}
       {/*  <button onClick={handleClick}>Signin With Google</button> */}
        <button type="button" className="bg-blue-600 font-semibold p-3 rounded-md text-center text-white w-full" 
        onClick={handleClick}
        disabled={isloading ? true : false}
        >
           {isloading ? 'checking...' : 'Continue with Google'}
        </button>
       {/*  } */}
    </div>
);
}
export default GoogleLogin;

