// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging"; // message for push notification

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDikorvxIbTsFKp6aQcpcYTUw5zz3MOBjs",
  authDomain: "love4you2.firebaseapp.com",
  projectId: "love4you2",
  storageBucket: "love4you2.appspot.com",
  messagingSenderId: "987679976917",
  appId: "1:987679976917:web:4aebbbd9b38cf85550e15c",
  measurementId: "G-J8V33R5SM1"
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//google signin
const auth = getAuth(app)
const provider = new GoogleAuthProvider();

//firebase messaging
const messaging = getMessaging(app);

//Handle messagin when apps is forground
/* const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  }); 
 */


  export {auth,provider, messaging};
