import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format } from "timeago.js";


import Viewpostpagedetails from './Viewpostpagedetails'

export default class Viewpostpage extends Component {

  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.resendemail = this.resendemail.bind(this);
    // this.changecomment = this.changecomment.bind(this);
    // this.changelike = this.changelike.bind(this);
    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      datafiveuser: [],
      show: false,
      count: '0',
      email: localStorage.getItem("emailaddress"),
      photos: [],
      datafavouritelist:[],
      loading: false,

      page: 0,

      prevY: 0,
      autoid: 0,
      xlike: 1,
      xcomments: 0,
      likeshow: false,
      checkuserverify: ''
    }


  }
  componentDidMount() {
    //  alert(this.state.email)
     if(this.state.email == null){
      // this.setState({  })
     }
     else
     {
      axios.get(base.BASE_URL + '/favouritlist_my_tbl/' + this.state.email).then(res => {
        this.setState({ datafavouritelist: res.data });
      });
  

    }


    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datafiveuser: res.data });
    });

    //start post pagination with scroll
    this.getPhotos(this.state.page); // post pagination
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options

    );

    this.observer.observe(this.loadingRef);

    // end start post pagination with scroll

  }
  //changelike = ev => {
  changelike(autoid, countlike, xcommentscount) {
    //   alert(autoid); user.countlike, user.xcommentscount
    let likep = countlike + this.state.xlike;
    fetch(base.BASE_URL + '/insertlikes', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        autoid: autoid
      })
    }).then((Response) => Response.json())
      .then((Result) => {
        if (Result.message == 'success') {
          //  alert('LIke has been submited successfully');
          this.setState({ xlike: likep, likeshow: true });
          // this.getPhotos(this.state.page);
        }
        else {
          //  alert('Failed to insert like')
        }

      })

  }

  handleObserver(entities, observer) {
    //start post pagination with scroll
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {

      ///  const lastPhoto = this.state.photos[this.state.photos.length - 1];
      const lastPhoto = this.state.photos[this.state.photos.length - 1];
      // const lastPhoto = 1;
      //  alert(lastPhoto + 'tttttttt')

      //  const curPage = lastPhoto.albumId;
      const curPage = this.state.page + 1;

      this.getPhotos(curPage);

      this.setState({ page: curPage });

    }

    this.setState({ prevY: y });
    //end start post pagination with scroll
  }




  // //start post pagination with scroll
  //allpost
  //https://www.pluralsight.com/guides/how-to-implement-infinite-scrolling-with-reactjs
  getPhotos(page) {
    this.setState({ loading: true });
    const pages = this.state.page + 1;
    axios
      .get(base.BASE_CHATURL + `/postlist.php?page=${pages}&limit=5`)
      .then(res => {
        //  alert(res.data);
        // alert(pages);
        if (res.data == 'No Data Found') {
          // alert(res.data);   
          this.setState({ loading: false, page: 0 });
        }
        else {
          // console.log('dddddddd' + res.data);
          this.setState({ photos: [...this.state.photos, ...res.data] });
          // alert(res.data[0].countlike);
        }

      });
  }
  // //start post pagination with scroll
  routeChange() {
    this.setState({ count: '1' });
  }
  resendemail() {
//////////////////////////////////////////////////////////////////

fetch(base.BASE_URL + '/resendemail', {
  method: 'post',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    email: this.state.email
  })
}).then((Response) => Response.json())
  .then((Result) => {
    if (Result.message == 'success') {
        alert('Check Your email and verify your email, check the spam or inbox');

    }
    else {
        alert('Failed to send Request')
    }

  })


/////////////////////////////////////////////////////////////////
  }

  /*   EditData(result) {
      alert(result.email);
      this.setState({ name: result.name, address: result.address, contact: result.contact, 
        email: result.email, id: result._id, Buttontxt: 'Update' });
    }
   */
  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }
    if (this.state.count == '1') {
      return <Redirect to="/Createpost" />
    }


    // Additional css

    const loadingCSS = {
      height: "100px",
      margin: "30px"
    };
    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };





    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">

          <div className="mcontainer">
            {/*  Feeds  */}
            <div className="lg:flex lg:space-x-10">
              <div className="lg:w-3/4 lg:px-20 space-y-7">

                <div className="card lg:mx-0 p-4" uk-toggle="target: #create-post-modal">
                  <div className="flex space-x-3">
                    {/* <img src="assets/images/avatars/avatar-2.jpg" className="w-10 h-10 rounded-full" /> */}
                    <input placeholder="What's Your Mind ? Hamse!"
                      className="bg-gray-100 hover:bg-gray-200 flex-1 h-10 px-6 rounded-full"
                      onClick={this.routeChange}
                    /*  onClick={() => this.handleModal()} */
                    />
                  </div>
                  <div className="grid grid-flow-col pt-3 -mx-1 -mb-1 font-semibold text-sm">
                    <Link to="/Photopost" className="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer">
                      <svg className="bg-blue-100 h-9 mr-2 p-1.5 rounded-full text-blue-600 w-9 -my-0.5 hidden lg:block"
                        data-tippy-placement="top" title="Tooltip" fill="none" stroke="currentColor"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                      Photo/Video
                    </Link>
                    <div className="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer" onClick={this.routeChange}>
                      <svg className="bg-green-100 h-9 mr-2 p-1.5 rounded-full text-green-600 w-9 -my-0.5 hidden lg:block" uk-tooltip="title: Messages ; pos: bottom ;offset:7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" title aria-expanded="false"> <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" /></svg>
                      Tag Friend
                    </div>
                    <div className="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer" onClick={this.routeChange}>
                      <svg className="bg-red-100 h-9 mr-2 p-1.5 rounded-full text-red-600 w-9 -my-0.5 hidden lg:block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                      Fealing /Activity
                    </div>
                  </div>
                </div>




                {this.state.photos.map(user => (
                  <div className="card lg:mx-0 uk-animation-slide-bottom-small">
                    {/* post header*/}
                    <div className="flex justify-between items-center lg:p-4 p-2.5">
                      <div className="flex flex-1 items-center space-x-4">
                      <Link to={`/viewinformation/${user.id}/${user.firstname}/${user.lastname}`}>
                          <img src={`${base.IMAGE_URL}/${user.com_logo}`}
                            className="bg-gray-200 border border-white rounded-full w-10 h-10"
                          />
                          {/*  <img src="assets/images/avatars/avatar-2.jpg"
                            className="bg-gray-200 border border-white rounded-full w-10 h-10" /> */}
                       </Link>
                        <div className="flex-1 font-semibold capitalize">
                        {/*   <a href="#" className="text-black dark:text-gray-100"> */}
                          <Link to={`/viewinformation/${user.id}/${user.firstname}/${user.lastname}`} className="text-black dark:text-gray-100">
                            {user.firstname} {user.lastname}
                          </Link>
                          <div className="text-gray-700 flex items-center space-x-2" style={{ fontSize: 10 }}>
                            {format(user.xdatetime)} {/* <span> hrs </span> */} <ion-icon name="people" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <a href="#">
                          <i className="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700" /> </a>
                        <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small">
                          <ul className="space-y-1">
                            <li>
                              <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                <i className="uil-share-alt mr-1" /> Share
                              </a>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                    <div uk-lightbox>
                      <h3 style={{ padding: 10 }}>{user.description}</h3>
                      {
                        user.postimage == 'x' ?
                          ''
                          :
                          <img src={`${base.IMAGE_URL}/${user.postimage}`} style={{ padding: 5 }}
                          />

                      }




                    </div>
                    <div className="p-4 space-y-3">

{/* like and comments  */}
<div className="flex space-x-4 lg:font-bold">
<Viewpostpagedetails data={user.autoid } />
</div>

{/*                       <div className="flex space-x-4 lg:font-bold">
                        <Link to="#" className="flex items-center space-x-2" onClick={this.changelike.bind(this, user.autoid, user.countlike, user.xcommentscount)}>
                          <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                              <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                            </svg>
                          </div>
                          <div>  Like</div>
                        </Link>
                        <Link to={`/comments/${user.autoid}`} className="flex items-center space-x-2">
                          <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                              <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                            </svg>
                          </div>
                          <div> Comment</div>
                        </Link>

                      </div> */}
{/* like and comments */}


 
                      <Link to={`/comments/${user.autoid}`} className="hover:text-blue-600 hover:underline">{/* {user.autoid} */}  Veiw more Comments and likes </Link>

                    </div>

                   

                  </div>
                ))}

                <div
                  ref={loadingRef => (this.loadingRef = loadingRef)}
                  style={loadingCSS}
                >
                  <span style={loadingTextCSS}>Loading...</span>
                </div>


                {/*                 <div className="card lg:mx-0 uk-animation-slide-bottom-small">
                  <div className="flex justify-between items-center lg:p-4 p-2.5">
                    <div className="flex flex-1 items-center space-x-4">
                      <a href="#">
                        <img src="assets/images/avatars/avatar-2.jpg" className="bg-gray-200 border border-white rounded-full w-10 h-10" />
                      </a>
                      <div className="flex-1 font-semibold capitalize">
                        <a href="#" className="text-black dark:text-gray-100"> Johnson smith </a>
                        <div className="text-gray-700 flex items-center space-x-2"> 5 <span> hrs </span> <ion-icon name="people" /></div>
                      </div>
                    </div>
                    <div>
                      <a href="#"> <i className="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700" /> </a>
                      <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small">
                        <ul className="space-y-1">
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-share-alt mr-1" /> Share
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-edit-alt mr-1" />  Edit Post
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-comment-slash mr-1" />   Disable comments
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-favorite mr-1" />  Add favorites
                            </a>
                          </li>
                          <li>
                            <hr className="-mx-2 my-2 dark:border-gray-800" />
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                              <i className="uil-trash-alt mr-1" />  Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div uk-lightbox>
                    <div className="grid grid-cols-2 gap-2 p-2">
                      <a href="assets/images/post/img-4.jpg" className="col-span-2">
                        <img src="assets/images/post/img-4.jpg" alt className="rounded-md w-full lg:h-76 object-cover" />
                      </a>
                      <a href="assets/images/post/img-4.jpg">
                        <img src="assets/images/post/img-2.jpg" alt className="rounded-md w-full h-full" />
                      </a>
                      <a href="assets/images/post/img-4.jpg" className="relative">
                        <img src="assets/images/post/img-3.jpg" alt className="rounded-md w-full h-full" />
                        <div className="absolute bg-gray-900 bg-opacity-30 flex justify-center items-center text-white rounded-md inset-0 text-2xl"> + 15 more </div>
                      </a>
                    </div>
                  </div>
                  <div className="p-4 space-y-3">
                    <div className="flex space-x-4 lg:font-bold">
                      <a href="#" className="flex items-center space-x-2">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                          </svg>
                        </div>
                        <div> Like</div>
                      </a>
                      <a href="#" className="flex items-center space-x-2">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div> Comment</div>
                      </a>
                      <a href="#" className="flex items-center space-x-2 flex-1 justify-end">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                          </svg>
                        </div>
                        <div> Share</div>
                      </a>
                    </div>
                    <div className="flex items-center space-x-3 pt-2">
                      <div className="flex items-center">
                        <img src="assets/images/avatars/avatar-1.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900" />
                        <img src="assets/images/avatars/avatar-4.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2" />
                        <img src="assets/images/avatars/avatar-2.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2" />
                      </div>
                      <div className="dark:text-gray-100">
                        Liked <strong> Johnson</strong> and <strong> 209 Others </strong>
                      </div>
                    </div>
                    <div className="border-t py-4 space-y-4 dark:border-gray-600">
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                          <img src="assets/images/avatars/avatar-1.jpg" alt className="absolute h-full rounded-full w-full" />
                        </div>
                        <div>
                          <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12 dark:bg-gray-800 dark:text-gray-100">
                            <p className="leading-6">In ut odio libero vulputate <urna className="i uil-heart" /> <i className="uil-grin-tongue-wink"> </i> </p>
                            <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800" />
                          </div>
                          <div className="text-sm flex items-center space-x-3 mt-2 ml-5">
                            <a href="#" className="text-red-600"> <i className="uil-heart" /> Love </a>
                            <a href="#"> Replay </a>
                            <span> 3d </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                          <img src="assets/images/avatars/avatar-1.jpg" alt className="absolute h-full rounded-full w-full" />
                        </div>
                        <div>
                          <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12 dark:bg-gray-800 dark:text-gray-100">
                            <p className="leading-6"> sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. David !<i className="uil-grin-tongue-wink-alt" /> </p>
                            <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800" />
                          </div>
                          <div className="text-xs flex items-center space-x-3 mt-2 ml-5">
                            <a href="#" className="text-red-600"> <i className="uil-heart" /> Love </a>
                            <a href="#"> Replay </a>
                            <span> 3d </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="hover:text-blue-600 hover:underline">  Veiw 8 more Comments </a>
                    <div className="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t">
                      <input placeholder="Add your Comment.." className="bg-transparent max-h-10 shadow-none px-5" />
                      <div className="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
                        <a href="#">
                          <ion-icon name="happy-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                        <a href="#">
                          <ion-icon name="image-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                        <a href="#">
                          <ion-icon name="link-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/*                 <div className="card lg:mx-0 uk-animation-slide-bottom-small">
                  <div className="flex justify-between items-center lg:p-4 p-2.5">
                    <div className="flex flex-1 items-center space-x-4">
                      <a href="#">
                        <img src="assets/images/avatars/avatar-2.jpg" className="bg-gray-200 border border-white rounded-full w-10 h-10" />
                      </a>
                      <div className="flex-1 font-semibold capitalize">
                        <a href="#" className="text-black dark:text-gray-100"> Johnson smith </a>
                        <div className="text-gray-700 flex items-center space-x-2"> 5 <span> hrs </span> <ion-icon name="people" /></div>
                      </div>
                    </div>
                    <div>
                      <a href="#"> <i className="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700" /> </a>
                      <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small">
                        <ul className="space-y-1">
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-share-alt mr-1" /> Share
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-edit-alt mr-1" />  Edit Post
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-comment-slash mr-1" />   Disable comments
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-favorite mr-1" />  Add favorites
                            </a>
                          </li>
                          <li>
                            <hr className="-mx-2 my-2 dark:border-gray-800" />
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                              <i className="uil-trash-alt mr-1" />  Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 border-b dark:border-gray-700">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim laoreet dolore magna aliquam erat volutpat
                  </div>
                  <div className="p-4 space-y-3">
                    <div className="flex space-x-4 lg:font-bold">
                      <a href="#" className="flex items-center space-x-2">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600 ">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                          </svg>
                        </div>
                        <div> Like</div>
                      </a>
                      <a href="#" className="flex items-center space-x-2">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div> Comment</div>
                      </a>
                      <a href="#" className="flex items-center space-x-2 flex-1 justify-end">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                          </svg>
                        </div>
                        <div> Share</div>
                      </a>
                    </div>
                    <div className="flex items-center space-x-3 pt-2">
                      <div className="flex items-center">
                        <img src="assets/images/avatars/avatar-1.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900" />
                        <img src="assets/images/avatars/avatar-4.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2" />
                        <img src="assets/images/avatars/avatar-2.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2" />
                      </div>
                      <div className="dark:text-gray-100">
                        Liked <strong> Johnson</strong> and <strong> 209 Others </strong>
                      </div>
                    </div>
                    <div className="border-t py-4 space-y-4 dark:border-gray-600">
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                          <img src="assets/images/avatars/avatar-1.jpg" alt className="absolute h-full rounded-full w-full" />
                        </div>
                        <div>
                          <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12 dark:bg-gray-800 dark:text-gray-100">
                            <p className="leading-6">In ut odio libero vulputate <urna className="i uil-heart" /> <i className="uil-grin-tongue-wink"> </i> </p>
                            <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800" />
                          </div>
                          <div className="text-sm flex items-center space-x-3 mt-2 ml-5">
                            <a href="#" className="text-red-600"> <i className="uil-heart" /> Love </a>
                            <a href="#"> Replay </a>
                            <span> 3d </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                          <img src="assets/images/avatars/avatar-1.jpg" alt className="absolute h-full rounded-full w-full" />
                        </div>
                        <div>
                          <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12 dark:bg-gray-800 dark:text-gray-100">
                            <p className="leading-6"> sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. David !<i className="uil-grin-tongue-wink-alt" /> </p>
                            <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800" />
                          </div>
                          <div className="text-xs flex items-center space-x-3 mt-2 ml-5">
                            <a href="#" className="text-red-600"> <i className="uil-heart" /> Love </a>
                            <a href="#"> Replay </a>
                            <span> 3d </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="hover:text-blue-600 hover:underline">  Veiw 8 more Comments </a>
                    <div className="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t">
                      <input placeholder="Add your Comment.." className="bg-transparent max-h-10 shadow-none px-5" />
                      <div className="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
                        <a href="#">
                          <ion-icon name="happy-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                        <a href="#">
                          <ion-icon name="image-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                        <a href="#">
                          <ion-icon name="link-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/*                 <div className="card lg:mx-0 uk-animation-slide-bottom-small">
                  <div className="flex justify-between items-center lg:p-4 p-2.5">
                    <div className="flex flex-1 items-center space-x-4">
                      <a href="#">
                        <img src="assets/images/avatars/avatar-2.jpg" className="bg-gray-200 border border-white rounded-full w-10 h-10" />
                      </a>
                      <div className="flex-1 font-semibold capitalize">
                        <a href="#" className="text-black dark:text-gray-100"> Johnson smith xxxxxx</a>
                        <div className="text-gray-700 flex items-center space-x-2"> 5 <span> hrs </span> <ion-icon name="people" /></div>
                      </div>
                    </div>
                    <div>
                      <a href="#"> <i className="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700" /> </a>
                      <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small">
                        <ul className="space-y-1">
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-share-alt mr-1" /> Share
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-edit-alt mr-1" />  Edit Post
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-comment-slash mr-1" />   Disable comments
                            </a>
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                              <i className="uil-favorite mr-1" />  Add favorites
                            </a>
                          </li>
                          <li>
                            <hr className="-mx-2 my-2 dark:border-gray-800" />
                          </li>
                          <li>
                            <a href="#" className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                              <i className="uil-trash-alt mr-1" />  Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-full">
                    <iframe src="https://www.youtube.com/embed/pQN-pnXPaVg" frameBorder={0} uk-video="automute: true" allowFullScreen uk-responsive className="w-full lg:h-64 h-40" />
                  </div>
                  <div className="p-4 space-y-3">
                    <div className="flex space-x-4 lg:font-bold">
                      <a href="#" className="flex items-center space-x-2">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                          </svg>
                        </div>
                        <div> Like</div>
                      </a>
                      <a href="#" className="flex items-center space-x-2">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div> Comment</div>
                      </a>
                      <a href="#" className="flex items-center space-x-2 flex-1 justify-end">
                        <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                          </svg>
                        </div>
                        <div> Share</div>
                      </a>
                    </div>
                    <div className="flex items-center space-x-3 pt-2">
                      <div className="flex items-center">
                        <img src="assets/images/avatars/avatar-1.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900" />
                        <img src="assets/images/avatars/avatar-4.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2" />
                        <img src="assets/images/avatars/avatar-2.jpg" alt className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2" />
                      </div>
                      <div className="dark:text-gray-100">
                        Liked <strong> Johnson</strong> and <strong> 209 Others </strong>
                      </div>
                    </div>
                    <div className="border-t py-4 space-y-4 dark:border-gray-600">
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                          <img src="assets/images/avatars/avatar-1.jpg" alt className="absolute h-full rounded-full w-full" />
                        </div>
                        <div>
                          <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12 dark:bg-gray-800 dark:text-gray-100">
                            <p className="leading-6">In ut odio libero vulputate <urna className="i uil-heart" /> <i className="uil-grin-tongue-wink"> </i> </p>
                            <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800" />
                          </div>
                          <div className="text-sm flex items-center space-x-3 mt-2 ml-5">
                            <a href="#" className="text-red-600"> <i className="uil-heart" /> Love </a>
                            <a href="#"> Replay </a>
                            <span> 3d </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                          <img src="assets/images/avatars/avatar-1.jpg" alt className="absolute h-full rounded-full w-full" />
                        </div>
                        <div>
                          <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12 dark:bg-gray-800 dark:text-gray-100">
                            <p className="leading-6"> sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. David !<i className="uil-grin-tongue-wink-alt" /> </p>
                            <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800" />
                          </div>
                          <div className="text-xs flex items-center space-x-3 mt-2 ml-5">
                            <a href="#" className="text-red-600"> <i className="uil-heart" /> Love </a>
                            <a href="#"> Replay </a>
                            <span> 3d </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="hover:text-blue-600 hover:underline">  Veiw 8 more Comments </a>
                    <div className="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t">
                      <input placeholder="Add your Comment.." className="bg-transparent max-h-10 shadow-none px-5" />
                      <div className="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
                        <a href="#">
                          <ion-icon name="happy-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                        <a href="#">
                          <ion-icon name="image-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                        <a href="#">
                          <ion-icon name="link-outline" className="hover:bg-gray-200 p-1.5 rounded-full" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="flex justify-center mt-6">
                  <Link to="/findpeople" className="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                    Load more ..</Link>
                </div>
              </div>
              <div className="lg:w-72 w-full">
                <h3 className="text-xl font-semibold mb-2"> Build Website Design and Development </h3>
                <a href="https://webshopedia.dk/" className="uk-link-reset mb-2">
                  <div className="flex py-2 pl-2 mb-2 rounded-md hover:bg-gray-200">
                   {/*  <img src="assets/images/icons/gift-icon.png" className="w-9 h-9 mr-3" alt /> */}

                    <p className="line-clamp-2">
                       <strong>    $ 500  ( 50 Percent Off)</strong> 
                    </p>
                  </div>
                </a>
                <h3 className="text-xl font-semibold"> Say hi to your Friends </h3>
                <div className uk-sticky="offset:80">
                  <nav className="responsive-nav border-b extanded mb-2 -mt-2">
                    <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                      <li className="uk-active"><a className="active" href="#0">  Friends  <span> {this.state.datafavouritelist.length} </span> </a></li>
                      {/*  <li><a href="#0">Groups</a></li> */}
                    </ul>
                  </nav>
                  <div className="contact-list">
                    {/* here is the contact list friends */}
                     {this.state.datafavouritelist.map((result) => {
                      return (
                        <div><a href="#">
                          <div className="contact-avatar">
                            {
                              result.com_logo == null ?
                                <img src="assets/images/avatars/avatar-80.jpg" alt />
                                :
                                <Link to="/Editprofile"><img src={`${base.IMAGE_URL}/${result.com_logo}`} /></Link>
                            }
                            <span className="user_status status_online" />
                          </div>
                          <div className="contact-username"> {result.firstname}   {result.lastname}</div>
                        </a>
                          <div uk-drop="pos: left-center ;animation: uk-animation-slide-left-small">
                            <div className="contact-list-box">
                              <div className="contact-avatar">
                                {
                                  result.com_logo == null ?
                                    <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}> <img src="assets/images/avatars/avatar-80.jpg" alt /></Link>
                                    :
                                    <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}><img src={`${base.IMAGE_URL}/${result.com_logo}`} /></Link>
                                }
                                <span className="user_status status_online" />
                              </div>
                              <div className="contact-username"> {result.firstname}   {result.lastname}</div>
                              <p>
                                <ion-icon name="people" className="text-lg mr-1" /> Become friends
                              </p>
                              <div className="contact-list-box-btns">
                                <Link to={`/chat/${result.id}`} className="button primary flex-1 block mr-2">
                                  <i className="uil-envelope mr-1" /> Send message</Link>

                              </div>
                            </div>
                          </div></div>
                      )
                    })} 
                    {
                    this.state.datafavouritelist.length == '0'
                    ?
                    <Link to="/Findpeople">No More Friend Yet Find New Friend</Link>
                    :
                    ''
                    
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        {/* 

*/}

        <div>
          {/* open chat box */}
          <div uk-toggle="target: #offcanvas-chat" className="start-chat">
            <svg className="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
            </svg>
          </div>
          <div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true">
            <div className="uk-offcanvas-bar bg-white p-0 w-full lg:w-80 shadow-2xl">
              <div className="relative pt-5 px-4">
                <h3 className="text-2xl font-bold mb-2"> Chats here </h3>
                <div className="absolute right-3 top-4 flex items-center">
                  <button className="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok" 
                  type="button" uk-close />
                  <a href="#" uk-toggle="target: #search;animation: uk-animation-slide-top-small">
                    <ion-icon name="search" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                  </a>
                  <a href="#">
                    <ion-icon name="settings-outline" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                  </a>
                  <a href="#">
                    <ion-icon name="ellipsis-vertical" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                  </a>
                  <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small; offset:5">
                    <ul className="space-y-1">
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="checkbox-outline" className="pr-2 text-xl" /> Mark all as read
                        </a>
                      </li>
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="settings-outline" className="pr-2 text-xl" />  Chat setting
                        </a>
                      </li>
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="notifications-off-outline" className="pr-2 text-lg" />   Disable notifications
                        </a>
                      </li>
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="star-outline" className="pr-2 text-xl" />  Create a group chat
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="absolute bg-white z-10 w-full -mt-5 lg:-mt-2 transform translate-y-1.5 py-2 border-b items-center flex" id="search" hidden>
                <input type="text" placeholder="Search.." className="flex-1" />
                <ion-icon name="close-outline" className="text-2xl hover:bg-gray-100 p-1 rounded-full mr-4 cursor-pointer" uk-toggle="target: #search;animation: uk-animation-slide-top-small" />
              </div>
              <nav className="responsive-nav border-b extanded mb-2 -mt-2">
                <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
                  <li className="uk-active"><a className="active" href="#0"> Friends {this.state.datafavouritelist.length}</a></li>
                  <li><a href="#0">Groups <span> 0 </span> </a></li>
                </ul>
              </nav>
              <div className="contact-list px-2 uk-switcher" id="chats-tab">
                <div className="p-1">
                  {/* Chat popup here */}
                  {this.state.datafavouritelist.map((result) => {
                    return (
                      <Link to={`/chat/${result.id}`}>
                        <div className="contact-avatar">
                        {
                              result.com_logo == null ?
                                <img src="assets/images/avatars/avatar-80.jpg" alt />
                                :
                               <img src={`${base.IMAGE_URL}/${result.com_logo}`} />
                            }
                       
                        </div>
                        <div className="contact-username">{result.firstname} {result.lastname}</div>
                      </Link>
                    )
                  })}


                </div>
                <div className="p-1">
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* 

*/}
        {/* story-preview */}
        <div className="story-prev">
          <div className="story-sidebar uk-animation-slide-left-medium">
            <div className="md:flex justify-between items-center py-2 hidden">
              <h3 className="text-2xl font-semibold"> All Story </h3>
              <a href="#" className="text-blue-600"> Setting</a>
            </div>
            <div className="story-sidebar-scrollbar" data-simplebar>
              <h3 className="text-lg font-medium"> Your Story </h3>
              <Link className="flex space-x-4 items-center hover:bg-gray-100 md:my-2 py-2 rounded-lg hover:text-gray-700" to="/Createpost">
                <svg className="w-12 h-12 p-3 bg-gray-200 rounded-full text-blue-500 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                <div className="flex-1">
                  <div className="text-lg font-semibold"> Create a story </div>
                  <div className="text-sm -mt-0.5"> Share a photo or write something. </div>
                </div>
              </Link>
              <h3 className="text-lg font-medium lg:mt-3 mt-1"> Friends Story </h3>
              <div className="story-users-list" uk-switcher="connect: #story_slider ; toggle: > * ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium ">
                {this.state.datafiveuser.map((result) => {
                  return (
                    <a href="#">
                      <div className="story-media">
                        <img src={`${base.IMAGE_URL}/${result.com_logo}`} />

                      </div>
                      <div className="story-text">
                        <div className="story-username">{result.firstname} {result.lastname}</div>
                        {/*  <p> <span className="story-count"> 2 new </span> <span className="story-time"> 4Mn ago</span> </p> */}
                      </div>
                    </a>
                  )
                })}

              </div>
            </div>
          </div>
          <div className="story-content">
            <ul className="uk-switcher uk-animation-scale-up" id="story_slider">
              {this.state.datafiveuser.map((result) => {
                return (
                  <li className="relative">
                    <span uk-switcher-item="previous" className="slider-icon is-left"> </span>
                    <span uk-switcher-item="next" className="slider-icon is-right"> </span>
                    <div uk-lightbox>
                      <a href="assets/images/avatars/avatar-lg-2.jpg" data-alt="Image">
                        {
                          result.com_logo == null ?
                            <img src="assets/images/avatars/avatar-80.jpg" alt style={{ width: '100%', height: 250 }} />
                            :
                            <img src={`${base.IMAGE_URL}/${result.com_logo}`} className="story-slider-image" data-alt={result.firstname} style={{ width: '100%', height: 250 }} />
                          /*  <img src={`assets/images/profile/${result.com_logo}`} className="story-slider-image" data-alt={result.firstname} style={{ width: '100%', height: 250 }} /> */
                        }
                      </a>
                      <h3>{result.firstname}</h3>
                    </div>
                  </li>
                )
              })}

            </ul>
          </div>
          {/* story colose button*/}
          <span className="story-btn-close" uk-toggle="target: body ; cls: story-active" uk-tooltip="title:Close story ; pos: left">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </span>
        </div>

        {/* 

*/}



        {/* 

*/}





      </div>
    );
  }
}
