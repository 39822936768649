 import React, { Component } from 'react'

 
/*  export const BASE_URL = "http://192.168.0.107/datingapp_api/public/api";
export const IMAGE_URL = "http://192.168.0.107/datingapp_api/public/thumbnail/";
export const BASE_CHATURL = "http://192.168.0.107/datingapp_api2";   
export const payment_stripe = "http://localhost/dating_stripe"; */
  
//http://192.168.0.103/datingapp_api/public/api/inboxpage/tania@gmail.com

export const BASE_URL = "https://www.love4you2.com/datingapp_api/public/api"; 
export const IMAGE_URL = "https://www.love4you2.com/datingapp_api/public/thumbnail/"; 
export const BASE_CHATURL = "https://www.love4you2.com/datingapp_api2";      
export const payment_stripe = "https://www.love4you2.com/dating_stripe";  


//export const payment_stripe = "http://localhost/dating_stripe";  


/*
export default {
    STORE_KEY: 'a56z0fzrNpl^2',
    BASE_URL: 'http://192.168.0.103/Aced_mobileapp',
    COLOR: {
      ORANGE: '#C50',
      DARKBLUE: '#0F3274',
      LIGHTBLUE: '#6EA8DA',
      DARKGRAY: '#999',
    },
  };
  */