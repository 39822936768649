//import React, { Component } from 'react';
import React, {useEffect,useState} from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Comment from "./Comment";
import Loader from "./Loader";
import EndMsg from "./EndMsg";

import InfiniteScroll from 'react-infinite-scroll-component';

//https://www.codecheef.org/article/laravel-react-js-pagination-example-from-scratch
//export default class findpeople extends Component {
  function Findpeople() {
    let history = useHistory();
    const [state, setData] = useState({users: ''});
    const [email, setEmail] = useState(localStorage.getItem("emailaddress"));
   // const [pageNumber, setPageNumber] = useState({pageNumber: '1'});



    const [items, setItems] = useState([]);

    const [hasMore, sethasMore] = useState(true);
  
    const [page, setpage] = useState(1);

    //https://react-hook-form.com/get-started
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
    } = useForm();


    const onSubmit = async (data, pageNumber) => {
   //   alert(pageNumber);
      console.log(data);
    //  fetchData();
    //history.push("/Createpost");
    history.push("/Findbyage/" + data.fromage + "/" + data.toage);
    //return <Redirect to="/Createpost" />
    //window.location.href = "Findbyage/" + data.fromage + "/" + data.toage + "";
    }; // your form submit function which will invoke after successful validation



    const fetchData = async (pageNumber = 1) => {
      //  alert(email);
        //  const api = await fetch(base.BASE_URL + `/getalluser?page=${pageNumber}`);
          const api = await fetch(base.BASE_URL + `/findpeople/` + email + `?page=${pageNumber}`);
          setData({
              users: await api.json()
          });
          window.scrollTo(0, 0);
      };
    
      useEffect(() => {
          fetchData();
      }, [])
    



    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            {/* Main people List */}



            <div class="flex justify-between items-center relative md:mb-4 mb-3">
              <div class="flex-1" style={{ backgroundColor: '#e0ebeb', padding: 10 }}>
              <h2 class="text-2xl font-semibold"> Find Your Match </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
              <table style={{ width: '100%' }}>
                  <tr>
                    <td>From Age</td>
                    <td>To Age</td>
                  </tr>
                  <tr>
                    <td>
                    <input defaultValue="20" {...register("fromage",{ required: true })} 
                    style={{ marginLeft: 0, padding: 11 }}
                    />

                    </td>

                    <td>
                    <input defaultValue="40"  {...register("toage", { required: true })} 
                    style={{ marginLeft: 0, padding: 11 }} />

                    </td>
                    <td> <input type="submit" value="Find By Age" class="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb',  }} /></td>

                  </tr>
                </table>
                </form>

              </div>

            </div>

            <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">

{   
state?.users?.data ? 
    state?.users?.data?.map((result) => (
      <div className="card">
      <div className="card-media h-40">
        <div className="card-media-overly" />
        <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
        <img src={`${base.IMAGE_URL}/${result.com_logo}`} 
       style={{ width: '100%', height: '100%' }}/>
        </Link>
        <span className="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
        <Link to={`/chat/${result.id}`}>
        <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
          <i className="icon-feather-heart"> </i>
        </button>
        </Link>
      </div>
      <div className="card-body">
        <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
        {result.xage} yrs
        </div>
        <div className="text-xs font-semibold uppercase text-yellow-500">View {result.profileview}</div>
        <div className="ext-lg font-medium mt-1 t truncate"> {result.firstname}  {result.lastname} </div>
      </div>
    </div>
    )) : "Loading..."
}


           </div>

{/* <InfiniteScroll
      dataLength={items.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<Loader />}
      endMessage={<EndMsg />}
    >
         <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">
          {items.map((item) => {
            return <Comment key={item.id} item={item} />;
          })}
            </div>
    </InfiniteScroll> */}
           <div style={{ width: '100%', marginTop: 20, marginBottom: 70 }}>
<Pagination
activePage={state?.users?.current_page ? state?.users?.current_page : 0}
itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0 }
totalItemsCount={state?.users?.total ? state?.users?.total : 0}
onChange={(pageNumber) => {
fetchData(pageNumber)
}}
pageRangeDisplayed={8} 
itemClass="page-item"
linkClass="page-link"
//firstPageText="First Page"
//lastPageText="Last Lage"
/>

</div>
        

          </div>
    
      </div>
      </div>

    );
  }
  export default Findpeople;
