import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Editprofile from './editprofile'

import Header from '../Layout/Header';

export default class Verifyemail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loggdin: 'on',
      automemberid: this.props.match.params.id,
      verify: 'error'
    }



  }
  componentDidMount() {
    this.setState({'loggdin': 'on'});
   // alert(this.props.match.params.id);
   
     fetch(base.BASE_URL + '/userverifyemail', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          autoid: this.props.match.params.id
        })
      }).then((Response) => Response.json())
        .then((Result) => {
          if (Result.message == 'success') 
          {
              this.setState({'verify': 'success', 'loggdin': 'off'});

          }
          else 
          {
            this.setState({'verify': 'error', 'loggdin': 'off'});
             
          }
  
        }) 

  }
  render() {
 
     return (
        <div style={{ textAlign: 'center', alignContent: 'center' }}>

           <h1>
            {
                this.state.loggdin == 'on'
                ?
                'Processing'
                :
                'Email Verification Successfully'
            }
           </h1> 
            <Link to="/">GO to Deashboard ! Search to find your soul mate....</Link>
        </div>
     )
  }

}