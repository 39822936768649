import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem, } from 'react'
import { Redirect } from 'react-router';
import Header from '../Layout/Header'

import { Button, Row, Col, Container } from 'react-bootstrap';

import * as base from "./global";

import axios from 'axios';


export default class upgrade extends Component {

  constructor(props) {
    super(props);
    this.monthlypackage = this.monthlypackage.bind(this);
    this.yearlypackage = this.yearlypackage.bind(this);
    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      datafiveuser: [],
      show: false,
      email: localStorage.getItem("emailaddress"),
      packagenamecheck: ''
    }


  }
  componentDidMount() {
    // alert(this.state.email);
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datafiveuser: res.data });
    });

  }
  monthlypackage() {
    //alert();
    localStorage.setItem("packagename", 'Monthly');
    localStorage.setItem("packageprice", '10');
    this.setState({ packagenamecheck: 'next' })

  }
  yearlypackage() {
    //alert();
    localStorage.setItem("packagename", 'Yearly')
    localStorage.setItem("packageprice", '50')
    this.setState({ packagenamecheck: 'next' })

  }
  handleModal() {
    this.setState({ show: !this.state.show })
  }

  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }
    if (this.state.packagenamecheck == 'next') {
      return <Redirect to="/Paymentmethod/0" />
    }

    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            <div class="flex justify-between items-center relative md:mb-4 mb-3">
              <div class="flex-1">
                <h2 class="text-2xl font-semibold"> Pricing </h2>

              </div>

            </div>
            <div className="grid-cols-6">
                    <div style={{ width: '100%', borderWidth: 1, backgroundColor: 'rgb(249, 249, 249)', padding: 10 }}>
                    <Link to="#" onClick={this.monthlypackage}>
                      <p> <b>Monthly</b></p>
                      <p>  <b>10 USD</b></p>
                      <p style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'red' }}>  <b>20 USD ( 50% Discount )</b></p>

                      <p>Purchase to send unlimited message to member. you can renew any time.</p>

                      <div className="is_link" style={{
                        backgroundColor: '#e5edff', color: '#2563eb',
                        width: 82, borderRadius: 15, padding: 8, height: 40, marginTop: 20
                      }}>Buy Now</div>

                    </Link>
                  </div>
            </div>
            <div className="grid-cols-6">
              
            <div style={{ width: '100%', borderWidth: 1, backgroundColor: 'rgb(249, 249, 249)', padding: 10 }}>
                    <p> <b>1 Years</b></p>
                    <p>  <b>50 USD</b></p>
                    <p style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'red' }}>  <b>100 USD  ( 50% Discount )</b></p>
                    <p>Purchase to send unlimited message to member. you can renew any time.</p>

                    <div className="is_link" style={{
                      backgroundColor: '#e5edff', color: '#2563eb',
                      width: 82, borderRadius: 15, padding: 8, height: 40, marginTop: 20
                    }}>
                      <Link to="#" className="is_link" onClick={this.yearlypackage}>
                        Buy Now</Link></div>
                  </div>

            </div>




            {/*           <div className="mcontainer">
            <div class="flex justify-between items-center relative md:mb-4 mb-3">
              <div class="flex-1">
                <h2 class="text-2xl font-semibold"> Pricing </h2>

              </div>

            </div>
            <div className="grid-cols-6">
              <div style={{ width: '100%' }}>
              <Link to="/Payment/1"><b>Silver Package (Monthly 10 USD)</b>
              
              <p>Unlimited message send and use unlimited untill month is finish. you can renew any time.</p>
              <div className="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb', 
              width: 140, borderRadius: 15, padding: 8, height: 40 }}>10 USD Buy Now</div>
              </Link>
              </div>
              <div>
           
              </div>
            </div>
            <div className="grid-cols-6" style={{ padding: 20 }}>
            <Link to="/Payment/2" className="is_link"><p>Gold Package(6 Month 40 USD)</p>
               <p>Unlimited message send and use unlimited untill month is finish. you can renew any time.</p>

              <div className="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb', 
              width: 140, borderRadius: 15, padding: 8, height: 40 }}>40 USD Buy Now</div>
              </Link>
              <Link to="/Paypalpage/40" className="is_link">
              <div className="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb', 
              width: 140, borderRadius: 15, padding: 8, height: 40 }}>40 USD Pay by Paypal</div>
                </Link>
            </div>




          </div>
 */}

          </div>
        </div>
      </div>
    );
  }
}
