import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import GoogleLogin from './GoogleLogin';

//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation

export class Registration extends Component {

  constructor() {
    super();
   // const year = (new Date()).getFullYear();
    const year = 1921;
    this.years = Array.from(new Array(80),(val, index) => index + year);

    let loggdin = false;
    /*  this.email = this.email.bind(this);
        this.password = this.password.bind(this);
        this.firstname = this.firstname.bind(this);
        this.lastname = this.lastname.bind(this); 
    */
    this.register = this.register.bind(this);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {}
    }



  }

  /*   email(event) {
      this.setState({ email: event.target.value })
      // alert(this.state.email);
    }
  
    firstname(event) {
      this.setState({ firstname: event.target.value })
      //  alert(this.state.firstname);
    }
  
    lastname(event) {
      this.setState({ lastname: event.target.value })
    }
    password(event) {
      this.setState({ password: event.target.value })
    } */
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //alert(this.state.fields["email"]);
    //Name
    if (!this.state.fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = "First Name Required";
    }
    if (!this.state.fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "Surname Required";
    }
    if (!this.state.fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password Required";
    }
    if (!this.state.fields["gender"]) {
      formIsValid = false;
      errors["gender"] = "Select Gender";
    }
    if (!this.state.fields["date"]) {
      formIsValid = false;
      errors["date"] = "Date Required";
    }
    if (!this.state.fields["month"]) {
      formIsValid = false;
      errors["month"] = "Month Required";
    }
    if (!this.state.fields["year"]) {
      formIsValid = false;
      errors["year"] = "Year Required";
    }
    if (!this.state.fields["interestedin"]) {
      formIsValid = false;
      errors["interestedin"] = "Interested Required";
    }



    /*     if (typeof this.state.fields["email"] !== "undefined") {
          if (!this.state.email.match(/^[a-zA-Z]+$/)) {
            formIsValid = false;
            errors["email"] = "Only letters";
          }
        } */

    //Email
    if (!this.state.fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email Required";
    }

    if (typeof this.state.fields["email"] !== "undefined") {
      let lastAtPos = this.state.fields["email"].lastIndexOf('@');
      let lastDotPos = this.state.fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (this.state.fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  register() {
    if (this.handleValidation()) {
      // alert("Form submitted");
      this.setState({ isLoading: true });
      const formdata = {
        firstname: this.state.fields["firstname"],
        lastname: this.state.fields["lastname"],
        email: this.state.fields["email"],
        password: this.state.fields["password"],
        gender: this.state.fields["gender"],
        xdate: this.state.fields["date"],
        month: this.state.fields["month"],
        year: this.state.fields["year"],
        interestedin: this.state.fields['interestedin']
      }
      //////////////////////////////////////////////////////////////////////
      axios.post(base.BASE_URL + "/usersignup", formdata, {
      }).then(res => {
        console.log('KKKKKK :' + res);
        console.log('data....' + res.data)
        console.log('data..file..' + res.data.message);
        //res.statusText 
        if (res.data.message == 'Registration completed successfully') {
          localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
          localStorage.setItem("emailaddress", this.state.fields["email"])

          this.setState({ loggdin: true, isLoading: false })
        }
        else if (res.data.message == 'email already exits') {
          this.setState({ isLoading: false });
          alert('Email is Already exit, please try to login')
        }
        else {
          this.setState({ loggdin: false, isLoading: false })
          alert('Failed');
        }
      })

      /////////////////////////////////////////////////////////////////////
    } else {
      // alert("Form has errors.")
    }


  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    if (this.state.loggdin) {
     // return <Redirect to="/Dashboard" />
      return <Redirect to="/Dashboradimage" />
    }
    return (
      <div>

        <div className="lg:flex max-w-5xl min-h-screen mx-auto p-6 py-10">
          <div className="flex flex-col items-center lg: lg:flex-row lg:space-x-10">
            <div className="lg:mb-12 flex-1 lg:text-left text-center">
            <Link to="/">  <img src="assets/images/logo.png" alt className="lg:mx-0 lg:w-52 mx-auto w-40" /></Link>
              <p className="font-medium lg:mx-0 md:text-2xl mt-6 mx-auto sm:w-3/4 text-xl"> 
              Connect with million of single and women in the world around you on love4you2.</p>
            </div>
            <div className="lg:mt-0 lg:w-96 md:w-1/2 sm:w-2/3 mt-10 w-full">
              <div className="p-6 space-y-4 relative bg-white shadow-lg rounded-lg">
                {/*  */}
                <h1 className="lg:text-2xl text-xl font-semibold mb-6" style={{ fontSize: 16 }}> 
                
                Create your FREE profile & start metting today! 
                
                </h1>

                <div style={{ marginTop: 5 }}>
                  <GoogleLogin />

{/*                   <table style={{ width: '100%' }}>
                    <tr>
                      <td>
                        <select onChange={this.handleChange.bind(this, "gender")} 
                        value={this.state.fields["gender"]} className="with-border"
                          style={{ padding: 15, backgroundColor: '#f2f2f2', width: 130 }}
                        >
                          <option  value="" selected disabled>I am</option>
                          <option value="Male">Male</option>
                          <option value="Female" >Female</option>
                          <option value="Unknown">Unknown</option>
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["gender"]}</span>
                      </td>
                      <td>
                        <select onChange={this.handleChange.bind(this, "interestedin")} value={this.state.fields["interestedin"]} className="with-border"
                           style={{ padding: 6, backgroundColor: '#f2f2f2', width: 130 }}
                        >
                          <option value="" selected disabled>Looking for </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Unknown">Unknown</option>
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["iammen"]}</span>
                      </td>
                    </tr>
                  </table> */}


                </div>

                <div style={{ marginTop: 5 }}>
                  {/*  */}

{/*                   <table style={{ width: '100%' }}>
                    <tr>
                      <td>
                        <input type="text" placeholder="First Name" name="firstname" 
                          className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                          onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"]}
                        />
                        <span style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                      </td>
                      <td>
                        <input type="text" placeholder="Surname" name="lastname" 
                          onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"]}
                          className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                        <span style={{ color: "red" }}>{this.state.errors["lastname"]}</span>

                      </td>
                    </tr>
                  </table> */}

{/*                 </div>
                <div style={{ marginTop: 5 }}>

                  <input type="email" placeholder="Email Address" name="email" 
                    onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}
                    className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                  <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                </div>
                <div>

                  <input type="password" placeholder="Password" name="password" 
                    onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}
                    className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                  <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                </div>
                <div>
                  <label className="mb-0" style={{ marginLeft: 4 }}> Date of Birth </label> */}
{/*                   <table style={{ width: '100%' }}>
                    <tr>
                      <td>

                        <select name="date" style={{ width: 80 }}  onChange={this.handleChange.bind(this, "date")} value={this.state.fields["date"]}>
                        <option value="Date" selected disabled >Date</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>

                        </select>



                        <span style={{ color: "red" }}>{this.state.errors["date"]}</span>
                      </td>

                      <td>

                      <select name="date" style={{ width: 80 }}  
                       onChange={this.handleChange.bind(this, "month")} value={this.state.fields["month"]}
                      >
                        <option value="Month" selected disabled >Month</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["month"]}</span>
                      </td>
                      <td>

<select name="year" onChange={this.handleChange.bind(this, "year")} 
value={this.state.fields["year"]} style={{ width: 100 }}>
  <option value="0" selected disabled>Year</option>
{
this.years.map((year, index) => {
return <option key={`year${index}`} value={year}>{year}</option>
})
}
</select>

                        <span style={{ color: "red" }}>{this.state.errors["year"]}</span>
                      </td>

                    </tr>
                  </table> */}

                </div>



{/*                 <div style={{ marginTop: -5 }}>
                  <Button type="button" className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full" 
                  onClick={this.register}  disabled={this.state.isLoading ? true : false}>
                    Get Started {this.state.isLoading ? 'checking...' : ''}</Button>
                </div>
                <div style={{ marginTop: -7 }}>
                  <Link to="/">
                    <button type="button" className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
                      Back to login </button>
                  </Link>
                </div> */}


                {/*  */}
              </div>
            </div>
          </div>
        </div>


     </ div>
    )

  }
}

export default Registration