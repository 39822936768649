import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter, router, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router';
import Sliderbar from "./Sliderbar";
import Footer from "../Footer";


const Datingsitesforsingle = () => {
  return (

    <div>
        <div className="bg-white py-4 shadow dark:bg-gray-800">
          <div className="max-w-6xl mx-auto">
            <div className="flex items-center lg:justify-between justify-around">
              <Link to="/">
                <img src="./assets/images/logo.png" alt="" class="w-32" />
              </Link>
              <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                <Link to="/Login" className="py-3 px-4"> Login</Link>
                <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                  style={{ backgroundColor: '#b50a0a' }}
                >Register</Link>
              </div>
            </div>
          </div>
        </div>

          <div className=''>
            <div class="flex flex-row">
              <div style={{ width: '60%', marginLeft: '8%' }}>
                <h1 style={{ fontSize: 22, padding: 10 }}>Dating sites for single</h1>
                <p style={{ fontSize: 18, padding: 10 }}>
                  There are many people looking for free dating sites in EU.
                  So for you in this post, we will discuss about free dating site.</p>
                <p style={{ fontSize: 18, padding: 10 }}>
                  Everyone wants to choose the life partner of their choice. As a result,
                  many people are using dating sites to choose their spouses. However,
                  in most cases, the dating site is a paid version.

                </p>
                <p style={{ fontSize: 18, padding: 10 }}>
                  So many of you can’t use paid version dating sites. So I would say that we have published
                  some free dating sites for your convenience. You can use them for free if you want.
                </p>

                <p style={{ fontSize: 18, padding: 10 }}>
                  Are you looking for free dating sites in Bangladesh? But I will say that
                  you are in the right place because you can easily get the list of these sites from here.
                </p>
                {/*   https://banglamaster.com/en/dating-sites-bangladesh/ */}
                <p style={{ fontSize: 18, padding: 10 }}>
                  Dating Sites are currently gaining popularity for
                  choosing a spouse. As a result, people in all countries
                  of the world are now using dating sites to choose the life partner of their choice.
                </p>


              </div>
              <div style={{ width: '22%', padding: 10 }}>
                  <h3 style={{ marginBottom: 20, fontSize: 24 }}>Recent Blog</h3>
                  <Sliderbar/>
              </div>
            </div>

          </div> {/* End Contained */}
          <Footer />
      </div>

  );
};
export default Datingsitesforsingle;
