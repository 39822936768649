import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter, router, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router';
import * as base from "../global";
import axios from 'axios';
import { format } from "timeago.js";

import { toast } from 'react-toastify';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.flogout = this.flogout.bind(this);


    this.state = {
      adminemailaddress: localStorage.getItem("adminemailaddress"),
    }

  }
  flogout() {
    const token = localStorage.removeItem("token");
    const adminemailaddress = localStorage.removeItem("adminemailaddress");
    this.state = {
      adminemailaddress: adminemailaddress
    }

  }
  render() {
    if (this.state.adminemailaddress == "") {
      return <Redirect to="/adminlogin" />
    }
    
    return (
      <div>

        <header>
          <div className="header_wrap">
            <div className="header_inner mcontainer">
              <div className="left_side">
                <span className="slide_menu" uk-toggle="target: #wrapper ; cls: is-collapse is-active">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}
                    onClick={this.closemobilemenu}
                  >

                    <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="currentColor" /></svg>
                </span>
                <div id="logo">
                  <Link to="/dashboard">
                    <img src="/assets/images/logo.png" />
                    <img src="/assets/images/logo.png" className="logo_mobile" />
                  </Link>
                </div>
              </div>
              {/* search icon for mobile */}

              <div className="header-search-icon" uk-toggle="target: #wrapper ; cls: show-searchbox"
                onClick={this.onClicksearchmobile}> </div>

              <div className="mobileupgrademember"><Link to="/upgrade"> Upgrade </Link> </div>

              <div className="header_search"><i className="uil-search-alt" />
                {/*   https://www.digitalocean.com/community/tutorials/react-react-autocomplete */}
                <form onSubmit={this.finddata}>
                  <input type="text" name="txtsearch" id="txtsearch"
                    className="form-control" placeholder=" Search for Friends , Videos and more.." autoComplete="off"
                    value={this.state.txtsearch} onChange={this.handlefindtxtsearchChange}
                  />
                </form>

              </div>


            </div>
          </div>
        </header>

      

      </ div>
    )
  }
}

export default Header