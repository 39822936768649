//import React, { Component } from 'react';
import React, { useEffect, useState, useRef } from 'react';
//import { Redirect } from 'react-router';
import Headerchat from '../Layout/Headerchat'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./messenger.css";
import "./iphone.css"
import Conversation from "./Conversation";
import Message from "./Message";
//import { io } from "socket.io-client";
import ScrollToBottom from 'react-scroll-to-bottom';

//export default class chat extends Component {
export default function Chat(props) {


  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const [conversations, setConversations] = useState([]);
  const [profileuser, setProfileuser] = useState([]);
  //const [profileState, setProfileState] = useState(props);
  //const { userid } = this.props.match.params.id;
  const [userid, setuserid] = useState(props.match.params.id);
  const [senderid, setSenderid] = useState(localStorage.getItem("emailaddress"));
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentdate, setCurrentdate] = useState(`${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`);
  const [statuspayment, setStatusPayment] = useState('expair');
  const [loading, setLoading] = useState(false);
  //const [socket, setSocket] = useState(null);
  const socket = useRef();
  //const scrollRef = useRef(); // use for scrolling up
  const scrollRef = useRef();

  //const userid = this.props.match.params.id;
  //this.props.match.params.id localStorage.getItem("emailaddress"),




  useEffect(() => {

    let meetupId = localStorage.getItem("emailaddress");
    try {
      axios.get(base.BASE_URL + '/viewprofile/' + meetupId)
        .then(res => {
          const persons = res.data;
          let expairdate = persons[0]["memberexpairdate"];
          if (expairdate == null) {
            //  alert('expair');
            setStatusPayment('expair');

          }
          else if (expairdate <= currentdate) {
            setStatusPayment('expair');
          }
          else {
            setStatusPayment('paid');
          }          // setStatusPayment(persons);
          // this.setState({ persons });
        })

    } catch (err) {
      console.log(err);
    }

  }, []);

  useEffect(() => {
    //alert(userid);
    // socket.current = io("ws://192.168.0.103:8900");
    // socket.current = io("ws://datingwebsite.netlify.app:8900");
    ///////////////////////////////////////////////////////////////////////

    fetch(base.BASE_URL + '/updatenotseen', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        currentuser: senderid,
        friendid: userid
      })
    }).then((Response) => Response.json())
      .then((Result) => {
        // alert(Result);
      })




    //////////////////////////////////////////////////////////////////////
  }, []);
  //console.log(socket); // its showing here all connection with socket server


  useEffect(() => {
    //  alert(senderid);
    const getConversations = async () => {
      try {
        const res = await axios.get(base.BASE_URL + "/chatconversitonid/" + senderid);
        setConversations(res.data);
      //  console.log('H::::' + base.BASE_URL + "/chatconversitonid/" + senderid);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [senderid]);

  //console.log('current ' + currentChat);
  useEffect(() => {
    //  alert(senderid);
    const getprofileuser = async () => {
      try {

        const res = await axios.get(base.BASE_URL + "/viewanotherprofile/" + userid);
        setProfileuser(res.data);
        //console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getprofileuser();
  }, [senderid]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(base.BASE_CHATURL + "/messagelist.php?senderid=" + senderid + '&friendid=' + userid);
        //  const res = await axios.get(base.BASE_URL + "/message/" + senderid);
        if (res.data == "No Data Found") {

        }
        else {
          setMessages(res.data);
        }
        // console.log('yyyy' + res.data);
      } catch (err) {
        console.log(err);
      }
    };


    getMessages();

  }, []);



  useEffect(() => {
    //reload after 5 second and show message
    let isMounted = true
    const getMessages5sec = async () => {
      const res = await axios.get(base.BASE_CHATURL + "/messagelist.php?senderid=" + senderid + '&friendid=' + userid);
      //  const res = await axios.get(base.BASE_URL + "/message/" + senderid);
      if (res.data == "No Data Found") {
      }
      else {
        setMessages(res.data);
      }

    };

    const intervalId = setInterval(() => {
      getMessages5sec();
    }, 5000)
    return () => {
      clearInterval(intervalId); //This is important
      isMounted = false // Let's us know the component is no longer mounted.
    }

  }, [useState]);



  useEffect(() => {
    const insertconversion = async () => {

      fetch(base.BASE_URL + '/firsttimechat', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          currentuser: senderid,
          friendid: userid,
        })
      }).then((Response) => Response.json())
        .then((Result) => {


        })
      try {
     //   console.log(base.BASE_CHATURL + "/messagelist.php?senderid=" + senderid + '&friendid=' + userid)
        const res = await axios.get(base.BASE_CHATURL + "/messagelist.php?senderid=" + senderid + '&friendid=' + userid);
        // setMessages(res.data);
        if (res.data == "No Data Found") {

        }
        else {
          setMessages(res.data);
        }
        console.log('yyyy' + res.data);
      } catch (err) {
        console.log(err);
      }
    };
    insertconversion();
  }, [currentChat]);

  useEffect(() => {
    // scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  //  scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(newMessage == '')
    {
      alert('Write Some Message');
    }
    else
    {
      
    setLoading(true);

    fetch(base.BASE_URL + '/insertmessage', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        currentuser: senderid,
        friendid: userid,
        message: newMessage
      })
    }).then((Response) => Response.json())
      .then((Result) => {
        setNewMessage("");setLoading(false);
        axios.get(base.BASE_CHATURL + "/messagelist.php?senderid=" + senderid + '&friendid=' + userid)
          .then(response =>
            setMessages(response.data)
          );


      })
    }

  };



  return (
    <div>
      {profileuser.map((profileuser, index) => (  
      <Headerchat key={index} profileuser={profileuser} />
      ))}
      {/* Main Contents */}
      <div className="messages-container">
        <div className="messages-container-inner">
          <div className="messages-inbox">
            <div className="messages-headline">
              <div className="input-with-icon" hidden>
                <input id="autocomplete-input" type="text" placeholder="Search" />
                <i className="icon-material-outline-search" />
              </div>
             {/*  <h2 className="text-2xl font-semibold">Chats</h2> */}
              <span className="absolute icon-feather-edit mr-4 text-xl uk-position-center-right cursor-pointer" />
            </div>
            <div className="messages-inbox-inner" data-simplebar>
              <ul>
                {conversations.map((conversation) => (

                  <div onClick={() => setCurrentChat(conversation)}>
                    <Conversation conversation={conversation} currentUser={senderid} />
                  </div>
                ))}

              </ul>
            </div>
          </div>
          <div className="message-content" >

            <div  className="scrollbarheight" data-simplebar>

              {/* Message Content Inner height: 350, width: 340,*/}
              <div className="message-content-inner">
              <ScrollToBottom className="scrollbar">
                {messages.map((m) => (
                    <Message message={m} own={m.senderid === userid} />
                 
                ))}
                 </ScrollToBottom>
                <div className="chatBoxWrapper">
                  {currentChat ? (
                    <>
                     <ScrollToBottom className="scrollbar">
                      <div className="chatBoxTop">
                        {messages.map((m) => (
                          <div>
                            <Message message={m} own={m.senderid === userid} />
                          </div>
                        ))}
                      </div>
                      </ScrollToBottom>
                    </>
                  ) : (
                    <span className="noConversationText">
                      {/*  Open a conversation to start a chat. */}
                    </span>
                  )}
                </div>
              </div>
              {/* Message Content Inner / End */}
              {/* Reply Area */}



            </div>
           

              {
                statuspayment == 'expair' ?
                  <div className="message-reply"><Link to="/upgrade" className='is_link'>Purchase Package to send unlimited Message </Link></div>
                  :
                  <div className="message-reply">
                    <textarea cols={1} rows={1} placeholder="Your Message"
                      onChange={(e) => setNewMessage(e.target.value)} style={{ padding: 11 }}
                      value={newMessage}
                    />
                    <button className="button ripple-effect" onClick={handleSubmit}   disabled={loading ? true : false}>Send {loading ? 'sending...' : ''}</button>
                    </div>
              }



          </div> {/* message-content */}


        </div>
      </div>



      {/* End Main Div  */}
    </div>
  );

}
