//import React, { Component } from 'react';
import React, {useEffect,useState} from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';

//https://www.codecheef.org/article/laravel-react-js-pagination-example-from-scratch
//export default class findpeople extends Component {
  function Findbyage(props) {
    const [state, setData] = useState({users: ''});
    const [email, setEmail] = useState(localStorage.getItem("emailaddress"));
    const [fromage, setFromage] = useState(props.match.params.frmid);
    const [toage, setToage] = useState(props.match.params.toid);

  const fetchData = async (pageNumber = 1) => {
  //  alert(email);
    //  const api = await fetch(base.BASE_URL + `/getalluser?page=${pageNumber}`);
      const api = await fetch(base.BASE_URL + `/findpeoplefromagetoage/` + email + `/` + fromage + `/` + toage + `?page=${pageNumber}`);
      setData({
          users: await api.json()
      });
      window.scrollTo(0, 0);
  };

  useEffect(() => {
      fetchData();
  }, [])


    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            {/* Main people List */}
            <div class="flex justify-between items-center relative md:mb-4 mb-3">
                    <div class="flex-1">
                        <h2 class="text-2xl font-semibold"> Search Result here..... </h2>
                        <nav class="responsive-nav border-b md:m-0 -mx-4">
                            <ul>
                                <li class="active"><Link to="/findpeople" class="lg:px-2">  Back  </Link></li>
                           
                            </ul>
                        </nav>
                    </div>
                    <Link to="/findpeople" uk-toggle class="flex items-center justify-center z-10 h-10 w-10 rounded-full bg-blue-600 text-white absolute right-0"
                    data-tippy-placement="left" title="Search Again" style={{ width: 100 }}>
                       Search
                    </Link>
                </div>

                
            <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">

{   
state?.users?.data ? 
    state?.users?.data?.map((result) => (
      <div className="card">
      <div className="card-media h-40">
        <div className="card-media-overly" />
        <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
        <img src={`${base.IMAGE_URL}/${result.com_logo}`} 
       style={{ width: '100%', height: '100%' }}/>
        </Link>
        <span className="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
        <Link to={`/chat/${result.id}`}>
        <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
          <i className="icon-feather-heart"> </i>
        </button>
        </Link>
      </div>
      <div className="card-body">
        <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
        {result.xage} yrs
        </div>
        <div className="text-xs font-semibold uppercase text-yellow-500">View {result.profileview}</div>
        <div className="ext-lg font-medium mt-1 t truncate"> {result.firstname}  {result.lastname} </div>
      </div>
    </div>
    )) : "Loading..."
}


           </div>

           <div style={{ width: '100%', marginTop: 20, marginBottom: 70 }}>
<Pagination
activePage={state?.users?.current_page ? state?.users?.current_page : 0}
itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0 }
totalItemsCount={state?.users?.total ? state?.users?.total : 0}
onChange={(pageNumber) => {
fetchData(pageNumber)
}}
pageRangeDisplayed={8} 
itemClass="page-item"
linkClass="page-link"
//firstPageText="First Page"
//lastPageText="Last Lage"
/>

</div>



          </div>




        </div>
        
    
      </div>
    );
  }
  export default Findbyage;
