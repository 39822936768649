import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';


class Dashboardfavouritelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem("emailaddress"),
            datafavouritelist:[],
            loading: false,
        };
    }
    componentDidMount() {
        if (this.state.email == null) {

        }
        else {
           // alert(this.state.email)
            axios.get(base.BASE_URL + '/favouritlist_my_tbl/' + this.state.email).then(res => {
                this.setState({ datafavouritelist: res.data });
               // console.log('Console Log...' + res.data)
            });
        }
    }
    render() {
        return (
            <div>
                {/* here is the contact list friends */}
                <h3 className="text-xl font-semibold" style={{ marginBottom: 20, 
   backgroundColor: 'silver', borderRadius: 13, fontSize: 14, padding: 11 }}> Favourites List {this.state.datafavouritelist.length}</h3>
                {this.state.datafavouritelist.map((result, index) => {
                    return (
                        <div key={index}><a href="#">
                            <div className="contact-avatar" >
                            <table>
                                {
                                    result.com_logo == null ?
                                        <img src="assets/images/avatars/avatar-80.jpg" alt />
                                        :
                                      
                                        <tr style={{ height: 40 }}> 
                                        <td><Link to="/Editprofile"><img src={`${base.IMAGE_URL}/${result.com_logo}`} 
                                        style={{ width: 30, height: 30, borderRadius: '50%' }} /></Link></td>

                                        <td> <span className="user_status status_online" /> {result.firstname}   {result.lastname}</td>
                                        </tr>
                                }
                               {/*    */}
                               </table>
                            </div>
                             
                        </a>
                            <div uk-drop="pos: left-center ;animation: uk-animation-slide-left-small">
                                <div className="contact-list-box">
                                    <div className="contact-avatar">
                                        {
                                            result.com_logo == null ?
                                                <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
                                                     <img src="assets/images/avatars/avatar-80.jpg" alt  style={{ width: '100%', height: '100%' }}/></Link>
                                                :
                                                <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
                                                    <img src={`${base.IMAGE_URL}/${result.com_logo}`}
                                                     style={{ width: '100%', height: '100%' }} />
                                                     
                                                     </Link>
                                        }
                                        <span className="user_status status_online" /> 
                                    </div>
                                     <div className="contact-username"> {result.firstname}   {result.lastname}</div> 
                                    <p>
                                        <ion-icon name="people" className="text-lg mr-1" /> Become friends
                                    </p>
                                    <div className="contact-list-box-btns">
                                        <Link to={`/chat/${result.id}`} className="button primary flex-1 block mr-2">
                                            <i className="uil-envelope mr-1" /> Send message</Link>

                                    </div>
                                </div>
                            </div></div>
                    )
                })}
                {
                    this.state.datafavouritelist.length == '0'
                        ?
                        <Link to="/Findpeople">No More Friend Yet Find New Friend</Link>
                        :
                        ''

                }
            </div>
        );
    }
}

export default Dashboardfavouritelist;
