import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';



class aboutme extends React.Component {

  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.handlefirstname = this.handlefirstname.bind(this);
    this.handlelastname = this.handlelastname.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.handleformage = this.handleformage.bind(this);
    this.handletoage = this.handletoage.bind(this);
    this.handledate = this.handledate.bind(this);
    this.handlemonth = this.handlemonth.bind(this);
    this.handleyear = this.handleyear.bind(this);
    this.handlecountry = this.handlecountry.bind(this);
    this.handleseeking_gender = this.handleseeking_gender.bind(this);
    this.handlestate = this.handlestate.bind(this);
    this.handlegender = this.handlegender.bind(this);
    this.handlecity = this.handlecity.bind(this);
    this.handleinterestedin = this.handleinterestedin.bind(this);
    this.handleinterestedilookingfor = this.handleinterestedilookingfor.bind(this);
    this.handleweight = this.handleweight.bind(this);
    this.handleheight = this.handleheight.bind(this);
    this.handlemarriedstatus = this.handlemarriedstatus.bind(this);
    this.handlechildren = this.handlechildren.bind(this);

    this.handleReligion = this.handleReligion.bind(this);
    this.handleEthnicity = this.handleEthnicity.bind(this);

       
     
    this.state = {
      selectedFile: null,
      email: localStorage.getItem("emailaddress"),
      loggdin: '',
      marriedstatus:'',
      children:'',
      firstname: '',
      lastname: '',
      gender: '',
      seeking_gender: '',
      age_from: '',
      age_to: '',
      birth_month: '',
      birth_day: '',
      birth_year: '',
      birth_month: '',
      status: '',
      city: '',
      country: '',
      countrylist: [],
      statelist: [],
      states: '',
      citylist: [],
      statesname: '',
      countryname: '',
      cityname: '',
      interestedin:'',
      lookingfor:'',
      weight:'',
      height:'',
      Religion:'',
      Ethnicity:'',
      updatestatus:''
      

    }

  }
  handleEthnicity(event) {
    this.setState({
      Ethnicity: event.target.value
    })
  }

  handleReligion(event) {
    this.setState({
      Religion: event.target.value
    })
  }

  handlemarriedstatus(event) {
    this.setState({
      marriedstatus: event.target.value
    })
  }

  handlechildren(event) {
    this.setState({
      children: event.target.value
    })
  } 
  //    this.handleseeking_gender = this.handleseeking_gender.bind(this);
  //    this.handlegender = this.handlegender.bind(this);
  handleseeking_gender(event) {
    this.setState({
      seeking_gender: event.target.value
    })
  }
  handleweight(event) {
    this.setState({
      weight: event.target.value
    })
  }
  handleheight(event) {
    this.setState({
      height: event.target.value
    })
  } 
  handleinterestedin(event) {
    this.setState({
      interestedin: event.target.value
    })
  }
  handleinterestedilookingfor(event) {
    this.setState({
      lookingfor: event.target.value
    })
  }
  
  
  handlestate(event) {
    this.setState({
      states: event.target.value
    })
    this.setState({ citylist: [] });
    //////////////////////////////////
    axios.get(base.BASE_URL + '/citieslist/' + event.target.value).then(result => {
      this.setState({ citylist: result.data });
    });
    ////////////////////////////////

  }


  handlegender(event) {
    this.setState({
      gender: event.target.value
    })
  }

  handlecity(event) {
    this.setState({
      city: event.target.value
    })

  }
  handlecountry(event) {
    this.setState({
      country: event.target.value
    })

    this.setState({ statelist: [] });
    ///////////////////////////////
    axios.get(base.BASE_URL + '/statelist/' + event.target.value).then(result => {
      this.setState({ statelist: result.data });
    });
    ///////////////////////////////
  }
  handlefirstname(event) {
    this.setState({
      firstname: event.target.value
    })
  }
  handlelastname(event) {
    this.setState({
      lastname: event.target.value
    })
  }
  handleemail(event) {
    this.setState({
      email: event.target.value
    })
  }


  handleyear(event) {
    this.setState({
      birth_year: event.target.value
    })
  }

  handledate(event) {
    this.setState({
      birth_day: event.target.value
    })
  }

  handlemonth(event) {
    this.setState({
      birth_month: event.target.value
    })
  }

  handleformage(event) {
    this.setState({
      age_from: event.target.value
    })
  }

  handletoage(event) {
    this.setState({
      age_to: event.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault()

    // alert(this.state.states);
    const formData = new FormData()
    formData.append('firstname', this.state.firstname)
    formData.append('lastname', this.state.lastname)
    formData.append('email', this.state.email)
    formData.append('birth_day', this.state.birth_day)
    formData.append('birth_month', this.state.birth_month)
    formData.append('birth_year', this.state.birth_year)
    formData.append('seeking_gender', this.state.seeking_gender)
    formData.append('gender', this.state.gender)
    formData.append('age_from', this.state.age_from)
    formData.append('age_to', this.state.age_to)
    formData.append('country', this.state.country)
    formData.append('states', this.state.states)
    formData.append('city', this.state.city)

           
    formData.append('interestedin', this.state.interestedin)
    formData.append('lookingfor', this.state.lookingfor)
    formData.append('height', this.state.height)
    formData.append('weight', this.state.weight)
    formData.append('children', this.state.children)
    formData.append('marriedstatus', this.state.marriedstatus)
    formData.append('Religion', this.state.Religion)
    formData.append('Ethnicity', this.state.Ethnicity)

    axios.post(base.BASE_URL + "/profileedit", formData, {
    }).then(res => {
      console.log('KKKKKK :' + res);
      console.log(res.statusText)

      this.setState({ updatestatus: 'success' });

      if (res.data.message == 'update') {
        alert('Upload Successfully');
      }
      else {
        alert('Failed');
      }
    })
    //  }


  }
  componentDidMount() {
    axios.get(base.BASE_URL + '/countrylist').then(result => {
      this.setState({ countrylist: result.data });
    });

    axios.get(base.BASE_URL + '/viewprofile/' + this.state.email).then(res => {
      // this.setState({ datauserprofile: res.data });
      //  alert(res.data[0].firstname);
      this.setState({
        'firstname': res.data[0].firstname,
        'lastname': res.data[0].lastname,
        'gender': res.data[0].gender,
        'seeking_gender': res.data[0].seeking_gender,
        'age_from': res.data[0].age_from,
        'age_to': res.data[0].age_to,
        'birth_month': res.data[0].birth_month,
        'birth_day': res.data[0].birth_day,
        'birth_year': res.data[0].birth_year,
        'birth_month': res.data[0].birth_month,
        'status': res.data[0].status,
        'city': res.data[0].city,
        'cityname': res.data[0].cityname,
        'country': res.data[0].country,
        'countryname': res.data[0].countryname,
        'states': res.data[0].state,
        'statesname': res.data[0].statename,
        'interestedin': res.data[0].aboutme,
        'lookingfor': res.data[0].aboutlooking,
        'height': res.data[0].height,
        'Religion': res.data[0].religion,
        'children': res.data[0].children,
        'Ethnicity': res.data[0].ethnic,
        'weight': res.data[0].body,
        'marriedstatus': res.data[0].marriedstatus,

      });
    });
  }

  render() {
    if (this.state.loggdin) {
      return <Redirect to="/profile" />
    }
    //updatestatus
    //https://www.youtube.com/watch?v=7vVqMR96T5o  Realtime Notification App Using React and Socket.io

    if (this.state.updatestatus == 'success') {
      return <Redirect to="/profile" />
    }
    return (
      <div>
        <Header />
        <div className="main_content">
          <div className="mcontainer" style={{ backgroundColor: '#fff' }}>

            {/*  */}
            <form onSubmit={this.onSubmit} style={{ padding: 20 }}>
              <div>
                <h3 style={{ fontWeight: 'bold', fontSize: 18 }}>Profile Information</h3>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td>I am
                    </td>
                    <td>Looking for </td>
                  </tr>
                  <tr>
                    <td>
                      <select className="with-border" onChange={this.handlegender}>
                        <option selected value={this.state.gender}>{this.state.gender}</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Unknown">Unknown</option>
                      </select>
                    </td>
                    <td>
                      <select className="with-border" onChange={this.handleseeking_gender}>
                        <option selected value={this.state.seeking_gender}>{this.state.seeking_gender}</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Unknown">Unknown</option>
                      </select>

                    </td>
                  </tr>
                </table>


              </div>

              <div>
                <table>
                  <tr>
                    <td>First Name</td>
                    <td>Surename</td>
                    <td>Email Address</td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" placeholder="Your Name" name="firstname" value={this.state.firstname} onChange={this.handlefirstname}
                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"

                      />
                    </td>
                    <td>
                      <input type="text" placeholder="Last  Name" name="lastname" value={this.state.lastname} onChange={this.handlelastname}

                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                    </td>
                    <td>
                      <input type="email" placeholder="Info@example.com" name="email" value={this.state.email} onChange={this.handleemail} /* value={this.state.email}  onChange={this.email} */

                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                    </td>
                  </tr>
                </table>

              </div>


              <div>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td>From Age</td>
                    <td>To Age</td>
                  </tr>
                  <tr>
                    <td>
                      <input type="number" placeholder="Date" name="date" /* value={this.state.lastname}  onChange={this.lastname} */

                        className="bg-gray-100" value={this.state.age_from} onChange={this.handleformage} />

                    </td>

                    <td>
                      <input type="number" placeholder="month" name="month" /* value={this.state.lastname}  onChange={this.lastname} */

                        className="bg-gray-100" value={this.state.age_to} onChange={this.handletoage} />

                    </td>


                  </tr>
                </table>

              </div>
              <div>
                <label className="mb-0"> Date of Birth </label>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td>
                      <input type="number" placeholder="Date" name="date" /* value={this.state.lastname}  onChange={this.lastname} */

                        className="bg-gray-100" value={this.state.birth_day} onChange={this.handledate} />

                    </td>

                    <td>
                      <input type="number" placeholder="month" name="month" /* value={this.state.lastname}  onChange={this.lastname} */

                        className="bg-gray-100" value={this.state.birth_month} onChange={this.handlemonth} />

                    </td>

                    <td>
                      <input type="number" placeholder="year" name="year" /* value={this.state.lastname}  onChange={this.lastname} */

                        className="bg-gray-100" value={this.state.birth_year} onChange={this.handleyear} />

                    </td>

                  </tr>
                </table>

              </div>

              <div>

                <table style={{ width: '100%' }}>
                  <tr>
                    <td>Country</td><td>State</td><td>City</td>
                  </tr>
                  <tr>
                    <td>
                      <select className="with-border" onChange={this.handlecountry}>


                        <option selected value={this.state.country}>{this.state.countryname}</option>
                        {
                          this.state.countrylist.map((result) => {
                            return (

                              <option value={result.id}>{result.name}</option>

                            )
                          })}
                      </select>
                    </td>
                    <td>
                      <select className="with-border" onChange={this.handlestate}>
                        <option selected value={this.state.states}>{this.state.statesname}</option>
                        {
                          this.state.statelist.map((result) => {
                            return (
                              <option value={result.id}>{result.name}</option>
                            )
                          })}
                      </select>

                    </td>
                    <td>
                      <select className="with-border" onChange={this.handlecity}>
                        <option selected value={this.state.city}>{this.state.cityname}</option>
                        {
                          this.state.citylist.map((result) => {
                            return (
                              <option value={result.id}>{result.name}</option>
                            )
                          })}
                      </select>
                    </td>
                  </tr>
                </table>

              </div>

              <div>

                <table style={{ width: '100%' }}>
                  <tr>
                    <td colSpan="3">Interested In</td>
                  </tr>
                  <tr>
                    <td>
                    <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
rows={5} placeholder=" write your interest in " style={{ fontSize: 13, padding: 5 }}
onChange={this.handleinterestedin} value={this.state.interestedin}
/>
                    </td>

                  </tr>
                </table>

              </div>

              <div>

                <table style={{ width: '100%' }}>
                  <tr>
                    <td colSpan="3">Looking For </td>
                  </tr>
                  <tr>
                    <td>
                    <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
rows={5} placeholder=" what are you looking for ? " style={{ fontSize: 13, padding: 5 }}
onChange={this.handleinterestedilookingfor} value={this.state.lookingfor}
/> 
                    </td>

                  </tr>
                </table>

              </div>

              <div>

                <table style={{ width: '100%' }}>
                  <tr>
                    <td>Weight : </td>  <td>Height : </td>
                  </tr>
                  <tr>
                    <td>
                    <input type="text" placeholder=" 50 kilo" name="weight" value={this.state.weight} onChange={this.handleweight}
                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"

                      />
                    </td>
                    <td>
                    <input type="text" placeholder="5' 5'" name="height" value={this.state.height} onChange={this.handleheight}
                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"

                      />
                    </td>

                  </tr>
                </table>

              </div>

              <div>

                <table style={{ width: '100%' }}>
                  <tr>
                    <td>Religion : </td>  <td>Ethnicity : </td>
                  </tr>
                  <tr>
                    <td>
                    <input type="text" placeholder="Your Religion" name="height" value={this.state.Religion} onChange={this.handleReligion}
                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"

                      />
                    </td>
                    <td>
                    <input type="text" placeholder="Your Ethnicity" name="height" value={this.state.Ethnicity} onChange={this.handleEthnicity}
                        className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"

                      />
                    </td>
                  </tr>
                </table>

              </div>
              <div>

                <table style={{ width: '100%' }}>
                  <tr>
                    <td>Married Status : </td>  <td>Children : </td>
                  </tr>
                  <tr> 

                    <td>
                    <select className="with-border" onChange={this.handlemarriedstatus}>
                        <option selected value={this.state.marriedstatus}>{this.state.marriedstatus}</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorce">Divorce</option>
                        <option value="Seperate">Seperate</option>
                        <option value="withdrew">withdrew</option>
                      </select>
                    </td>
                    <td>
                    <select className="with-border" onChange={this.handlechildren}>
                        <option selected value={this.state.children}>{this.state.children}</option>
                        <option value="Dont have">Dont have</option>
                        <option value="I have children">I have children</option>
                        <option value="Interested">Interested</option>
                      </select>
                    </td>
                  </tr>
                </table>

              </div>

              <div>
                <button className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">Update</button>
              </div>
            </form>
            {/*  */}
          </div>
        </div>
      </div>
    )
  }
}

export default aboutme;