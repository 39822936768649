import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from 'reactstrap';
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';
//import SocialFlow from "./SocialFlow";
//npm install react-multi-carousel --save
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Footer from './Footer';

const responsive = {

  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 4000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export class Home extends Component {
  constructor() {
    super();
    //  let loggdin = false;
    this.register = this.register.bind(this);
    this.state = {
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {},
      datauserprofile: [],
      image: 'maleavter.png',
      loggdin: 'stop',
      show: false,
      opennextwindow: false,
      email: localStorage.getItem("emailaddress"),
      checkuserloginornot:''
    }
  }
  componentDidMount() {
  //   alert(this.state.email);
     if(this.state.email == null)
     {
      // alert(this.state.email)
     }
     else
     {
      this.setState({ checkuserloginornot: 'valid' });
     }
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {

      this.setState({ datauserprofile: res.data });
    });
    const formdata = {
      pagetype: "Homepage",
      gettracking: ''
    }
    axios.post(base.BASE_URL + "/visitor", formdata, {
    }).then(res => {
      console.log('data..file..' + res.data);
    })

  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    // alert(this.state.fields["email"]);
    /*     if (!this.state.fields["password"]) {
          formIsValid = false;
          errors["password"] = "Cannot be empty";
        } */

    if (!this.state.fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof this.state.fields["email"] !== "undefined") {
      let lastAtPos = this.state.fields["email"].lastIndexOf('@');
      let lastDotPos = this.state.fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (this.state.fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  register() {
    if (this.handleValidation()) {
      // alert("Form submitted");
      //////////////////////////////////////////////////////////////////////
      this.setState({ isLoading: true });
      fetch(base.BASE_URL + '/regstep1', {
        // fetch(base.BASE_URL + '/login', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.fields["email"] //,
          // password: this.state.fields["password"]
        })
      }).then((Response) => Response.json())
        .then((Result) => {
          if (Result.message == 'success') {
            localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
            localStorage.setItem("emailaddress", this.state.fields["email"])

            this.setState({ loggdin: 'start' })
          }
          else if (Result.message == 'email already exits') {
            this.setState({ isLoading: false });
            alert('Email is Already Exits')
          }
          else {
            //alert('Failed')
          }

        })

      /////////////////////////////////////////////////////////////////////
    } else {
      // alert("Form has errors.")
    }


  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    if (this.state.checkuserloginornot == 'valid') {
       return <Redirect to="/Dashboard" />
     // return <Redirect to="/Registration" />
    }
    return (
      /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/
      <div>
        <div style={{
          backgroundImage: "url('/assets/images/homebanner2.jpg')", width: '100%',
          backgroundSize: 'cover', backgroundPosition: 'center', height: 500, background: "linear-gradient(#e66465, #9198e5);"
        }}>

          <div className="bg-white py-4 shadow dark:bg-gray-800">
            <div className="max-w-6xl mx-auto">
              <div className="flex items-center lg:justify-between justify-around">
                <Link to="/">
                  <img src="./assets/images/logo.png" alt="" class="w-32" />
                </Link>
                <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                  <Link to="/Login" className="py-3 px-4"> Login</Link>
                  <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                    style={{ backgroundColor: '#b50a0a' }}
                  >Register</Link>
                </div>
              </div>
            </div>
          </div>


          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-" style={{ marginTop: 50, marginBottom: 5 }}>
            <Form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md">
              <h1 class="lg:text-2xl text-xl font-semibold mb-6" style={{ color: '#000' }}>
                Meeting with Millions of Man and Women
              </h1>

              {/*               <div>
                <label class="mb-0" style={{ color: '#fff', fontWeight: 'bold' }}> Email Address </label>
                <input type="email" placeholder="" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                  onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}
                />
                <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
              </div> */}

              <div>
                <Link to="/Registration">
                  {/*      
                  <button type="button" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full" 
                  onClick={this.register}
                  disabled={this.state.isLoading ? true : false}>
                Create New Account  {this.state.isLoading ? 'Loading...' : ''}
                </button> */}
                  <button type="button" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full"
                    style={{ backgroundColor: '#b50a0a' }}
                  >
                    Create New Account </button>
                </Link>
              </div>
            </Form>
            <div>
              <Link to="/Login">
                <button type="button" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full"
                  style={{ backgroundColor: '#b50a0a' }}
                >
                  Already have Account ? Login </button>
              </Link>
            </div>
          </div>
        </div>


        <div className="mb-12" style={{ borderWidth: 0, borderColor: 'red' }}>
          <div className="flex flex-col lg: lg:flex-row">

            <div className="mb-4 mobilediv" style={{ backgroundColor: '#1a5f78' }}>
              <div style={{ padding: 40 }}>
                <p style={{ fontWeight: 'bold', fontSize: 24 }}>
                  <span style={{ color: '#ffcb4a' }}>#1 Trusted</span>
                  <span style={{ color: '#fff' }}> Dating App </span>
                </p>
              </div>
            </div>
            <div className="mb-4 mobilediv" style={{ backgroundColor: '#617dc6' }}>
              <div style={{ padding: 40 }}>
                <p style={{ fontWeight: 'bold', fontSize: 24 }}> <span style={{ color: '#ffcb4a' }}> Every 10 Minitue </span>
                  <span style={{ color: '#fff' }}> Someone finds love on love4you2  </span> </p>
              </div>
            </div>

            <div className="mb-4 mobilediv" style={{ backgroundColor: '#0a9aa4' }}>
              <div style={{ padding: 40 }}>
                <p style={{ fontWeight: 'bold', fontSize: 24 }}> <span style={{ color: '#ffcb4a' }}>Highest Quality</span>
                  <span style={{ color: '#fff' }}> Dating pool </span> </p>
              </div>
            </div>

          </div>
        </div>







        {/* <div style={{ flex: 1,
flexDirection: 'row', display: 'flex',flexWrap: 'wrap', width: '100%', height: 147,justifyContent: 'space-between',
        }}>
          <div className="pt-4"  style={{ backgroundColor: "red", width: '33%', textAlign: 'center',  }}>
                <div className="" style={{ padding: 20 }}>
                       
                </div>
          </div>
          <div className="pt-4"  style={{ backgroundColor: "red", width: '33%', textAlign: 'center', backgroundColor: '#617dc6' }}>
          <div className="" style={{ padding: 20 }}>
                        
                </div>
          </div>
          <div className="pt-4"  style={{ backgroundColor: "red", width: '34%', textAlign: 'center', backgroundColor: '#0a9aa4' }}>
          <div className="" style={{ padding: 20 }}>

                </div>
          </div>

</div> */}

        {/* Start Feature Dating Site 
              backgroundImage: "url('/assets/images/background1.jpg')", width: '100%', marginTop: 20,
      backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', marginBottom: 20
        */}
        <div style={{
          width: '100%', marginTop: 20,
          height: '100%', marginBottom: 20
        }}>
          <div className="content">
            <div class="mcontainer">
              <h1 style={{ textAlign: 'center', fontSize: '2rem' }}><b>Our dating site helps millions find real love</b> </h1>
              {/* start featured profiles */}
              <div>
                <div className="my-6 flex items-center justify-between">
                  <div>
                    <h1 className="text-xl font-semibold"> Free denmark dating site, Meet with New People
                    </h1>
                  </div>
                  <Link to="/Registration" className="text-blue-500"> See all </Link>
                </div>
                <div className="relative_ -mt-3" uk-slider="finite: true">
                  <div className="uk-slider-container_ px-1 py-3">
                    {/*    <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-small uk-grid"> */}
                    <Carousel centerMode={true} responsive={responsive}>
                      {this.state.datauserprofile.map((result, slno) => {
                        return (
                          <Link to="/login" key={slno}>
                            <div className="card p-2 flex space-x-4 border border-gray-100" style={{ fontSize: 10, marginRight: 9 }}>
                              <div className="w-20 h-24 overflow-hidden rounded-lg">
                                <div className="card-media h-24">
                                  {/*   <img src="assets/images/product/13.jpg" alt /> */}
                                  {
                                    result.com_logo == null ?
                                      <img src={`${base.IMAGE_URL}/${this.state.image}`} alt={result.firstname}
                                        style={{ backgroundPosition: 'center', backgroundSize: 'cover' }} />
                                      :
                                      <img src={`${base.IMAGE_URL}/${result.com_logo}`}
                                        style={{ backgroundPosition: 'center', backgroundSize: 'cover' }} />
                                  }
                                </div>
                              </div>
                              <div className="flex-1 pt-2.5 relative">
                                {/*       <div className="text-xs font-semibold uppercase text-yellow-500" style={{ fontSize: 12 }}>Parfumdds </div> */}
                                <div className="text-lg mt-3 2.5 text-gray-700" style={{ fontSize: 14 }}>{result.firstname} {result.lastname}</div>
                                <div className="flex items-center space-x-2 text-sm text-gray-400 capitalize">
                                  <div style={{ fontSize: 12 }}> 15 likes</div>
                                  <div>·</div>
                                  <div style={{ fontSize: 12 }}> {result.profileview} views </div>
                                </div>
                                <div className="top-1.5 absolute bg-gray-100 font-semibold 
                              px-2.5 py-1 right-1 rounded-full text text-blude-500 text-sm" style={{ fontSize: 12 }}>
                                  {result.xage} yrs
                                </div>
                              </div>
                            </div>
                          </Link>

                        )
                      })}
                    </Carousel>
                    {/*  </ul> */}
                  </div>
                  {/*   <a className="absolute bg-white top-11 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i className="icon-feather-chevron-left" /></a>
                  <a className="absolute bg-white top-11 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i className="icon-feather-chevron-right" /></a> */}
                </div>
                {/*  */}

                <div>
                  <div className="my-6 flex items-center justify-between">
                    <div style={{ marginTop: 40 }}>
                      <h2 className="text-xl font-semibold"> Meet with Dasish Men and Women
                      </h2>
                    </div>
                    <a href="#" className="text-blue-500" style={{ marginTop: 40, color: '#000' }}> See all </a>
                  </div>
                  <div className="relative -mt-3" uk-slider="finite: true">
                    <div className="uk-slider-container px-1 py-3" style={{ fontSize: 15 }}>
                    love4you2 help you to find real man or women in the world basically from EU. its 100% percent trusted website in denmark and all EU.
                    </div>
                 
                  </div>
                  {/*   <h3 style={{ textAlign: 'center' }}>View more profiles</h3> */}
                </div>

                <div class="flex justify-between items-center relative md:mb-4 mb-3">
                      <div style={{ width: '30%' }}>
                        <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>Follow up free denmark dating site</h1>
                        <p>
                        love4you2 is a top-performing online dating site with members present in Denmark and many 
                        other countries. love4you2 has over a million registered singles and over 1000 new men 
                        and women are joining daily. With all these statistics 
                        you are almost guaranteed to meet your Danish match. love4you2 founded in 1998 has many 
                        options for singles looking for serious relationship. Registration with love4you2 is 
                        absolutely free with no credit card required. 
                        </p>
                      </div>
                      <div style={{ width: '30%' }}>
                      <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>100 percent free dating sites for seniors</h1>     
                           <p>Thankfully, plenty of dating websites have taken the needs of senior singles into 
                            consideration and created a friendly and accessible dating network just for them. 
                            We’ve reviewed the following six senior-friendly dating sites that are free to join 
                            and fun to use to let older adults know they’re not out of options, and it’s 
                            possible to meet someone new online.The online dating site is easy to join and easy to enjoy, 
                            so you can connect to a potential match in your area in just a few clicks.</p>                 
                      </div>
                      <div style={{ width: '30%' }}>
                        <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>Best Dating apps in denmark</h1>
                        <p>
                        The online dating scene in Denmark is quite effective even though it is small. 
                        The good news is that the best dating apps in Denmark have thousands of members, 
                        so you will have an excellent chance of finding true love in this welcoming and hospitable country.
                        Copenhagen has the distinction of having the highest number of singles compared to any other city 
                        in Denmark. So, your chances of success will be higher if you reside in Copenhagen. Nonetheless, 
                        you should not give up.
                        </p>
                      </div>
                </div>  
                <div class="flex justify-between items-center relative md:mb-4 mb-3">
                      <div style={{ width: '30%' }}>
                        <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>How you meet with women in dating site</h1>
                        <p>
                        On love4you2, mature dating is easier than ever before. The senior dating service helps single 
                        men and women start a conversation and find a senior match in the dating scene.
                        Senior daters can search for matches and mingle for free. With more than 85,000 
                        visitors a month, and counting, the love4you2 dating platform ensures that
                         you never run out of senior people to meet.
                        </p>
                      </div>
                      <div style={{ width: '30%' }}>
                      <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>How you propose a girl in dating ?</h1>     
                           <p>There was once a time in my life when I was so frustrated with dating that I seriously wanted to give up. 

I was hitting the gym, losing weight, reading, working on myself, building my business, and doing everything that I thought I was supposed to be doing to attract a high-value girlfriend. 

And yet, I just wasn’t meeting any women. 

I rarely seemed to meet anyone I considered ‘date-worthy.’ 

And even on those rare occasions when I did, nothing ever seemed to come of it. </p>                 
                      </div>
                      <div style={{ width: '30%' }}>
                        <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>Meet with Single ?</h1>
                        <p>
                        Our first tip is pretty obvious — you need to get online if you want to change your dating 
                        situation. Dating sites and apps have become the best way to meet people for many reasons. 
                        Not only can you quickly and easily find local single women, but you can also narrow down 
                        other preferences like age, appearance, education, religion, ethnicity, and interests.
                        </p>
                      </div>
                </div>  
              </div>
            </div>

          </div>

        </div> 

          <Footer />

      </div>
    )
  }
}

export default Home