import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem, } from 'react'
import { Redirect } from 'react-router';
import Header from '../Layout/Header'

import { Button, Row, Col, Container } from 'react-bootstrap';

import * as base from "./global";

import axios from 'axios';


export default class Visasuccess extends Component {

  constructor(props) {
    super(props);

    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      datafiveuser: [],
      show: false,
      email: localStorage.getItem("emailaddress"),
      pcakageprice: localStorage.getItem("packageprice"),
      paymenttypecheck:'',
      isLoading: false
    }


  }
  componentDidMount() {

    this.setState({ isLoading: true });
    const formdata = {
      email: this.state.email,
      transaction: this.props.match.params.id,
      customerid: this.props.match.params.ids

    }
    //////////////////////////////////////////////////////////////////////
    axios.post(base.BASE_URL + "/confirmupdatememberpay", formdata, {
    }).then(res => {
      console.log('KKKKKK :' + res);
      console.log('data....' + res.data)
      console.log('data..file..' + res.data.message);
      alert(res.data.message);
      //res.statusText 
/*       if (res.data.message == 'Registration completed successfully') {
        localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
        localStorage.setItem("emailaddress", this.state.fields["email"])

        this.setState({ loggdin: true, isLoading: false })
      }
      else if (res.data.message == 'email already exits') {
        this.setState({ isLoading: false });
        alert('Email is Already exit, please try to login')
      }
      else {
        this.setState({ loggdin: false, isLoading: false })
        alert('Failed');
      } */
    })




  }
  

  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }


    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">

          <Container>
            <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
              <h3 style={{ justifyContent: 'center', textAlign: 'center', marginTop: 20,
               fontWeight: 'bold' }}>
                Payment Created Successfully 
              </h3>
            </Row>
            <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
              <p style={{ justifyContent: 'center', textAlign: 'center' }}>
          
          Transaction ID: {this.props.match.params.id}   {this.props.match.params.ids} USD

        </p>

            </Row>
            <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
        <p style={{ justifyContent: 'center', textAlign: 'center', marginBottom: 20 }}>
          
          Package info:   {this.props.match.params.ids} USD

        </p>
            </Row>
          </Container>

        </div>
      </div>
    );
  }
}
