import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem,  } from 'react'
import { Redirect } from 'react-router';
import Header from '../Layout/Header'

import { Button, Row, Col, Container } from 'react-bootstrap';

import * as base from "./global";

import axios from 'axios';


export default class Paymentsuccessfully extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      show: false,
      email: localStorage.getItem("emailaddress"),
      packagenamecheck:''
    }


  }
  componentDidMount() {
 

  }
 
  
  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }
  

    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">

          <Container>
          <Row>
            
              <Col>
             
              <div style={{ width: '100%', borderWidth: 1, backgroundColor: 'rgb(249, 249, 249)', padding: 10 }}>
        
              <p>Payment Receive Successfully.</p>

           
              </div>
              <div>
    
              </div>
       
              </Col>
           
            </Row>
          </Container>

        </div>
      </div>
    );
  }
}
