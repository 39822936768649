import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter, router, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router';
import Sliderbar from "./Sliderbar";
import Footer from "../Footer";


const Bfreeseriousrelations = () => {
  return (

    <div>
      <div className="bg-white py-4 shadow dark:bg-gray-800">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center lg:justify-between justify-around">
            <Link to="/">
              <img src="./assets/images/logo.png" alt="" class="w-32" />
            </Link>
            <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
              <Link to="/Login" className="py-3 px-4"> Login</Link>
              <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                style={{ backgroundColor: '#b50a0a' }}
              >Register</Link>
            </div>
          </div>
        </div>
      </div>

      <div className=''>
        <div class="flex flex-row">
          <div style={{ width: '60%', marginLeft: '8%' }}>
            <h1 style={{ fontSize: 22, padding: 10 }}>best-free-dating-sites-for-serious-relations </h1>
            <p style={{ fontSize: 18, padding: 10 }}>
            There are more ways to meet someone than awkward blind dates or heading out to the local bars. 
            If you've been having difficulty finding someone you're compatible with, an online dating 
            site or mobile dating apps may be able to help you make a connection. There are tons of 
            options out there, whether you've looking for a long-term relationship or even a fun fling. 
            Yes, the internet is a wonderful thing.</p>
            <p style={{ fontSize: 18, padding: 10 }}>1. OkCupid</p>
            <p style={{ fontSize: 18, padding: 10 }}>


              OkCupid is one of the most popular online dating apps. With its free version, you can browse
              unlimited profiles and connect with anyone you want. It also has a
              premium version with more advanced services, like viewing the profiles
              “liking you,” which is not available with the free version.
            </p>
            <p style={{ fontSize: 18, padding: 10 }}>
              Overall, OkCupid is a dating app for all ages. When signing up, you’ll be prompted to enter your age and your preference matches. That way, you’ll get better-customized profiles to help you find your perfect match.

            </p>
            <p style={{ fontSize: 18, padding: 10 }}>2. Tinder</p>
            <p style={{ fontSize: 18, padding: 10 }}>
            Tinder is another popular dating app with an incredible interface. It also has a desktop version that boosts its usability. Like all the other apps, it has a free and paid version. You don’t necessarily 
            need the premium version to find a date — the free version is a good choice for that.
            </p>
            <p style={{ fontSize: 18, padding: 10 }}>
            Tinder’s free version allows you to swipe through profiles looking for your potential match. 
            The downside is that you’re given limited information about the profiles. 
            For the profiles you like, select a heart icon, X or swipe to the right. 
            If you don’t, swipe to the left and continue. Whenever you like a person 
            who likes you back, you’ll receive a notification and you can message 
            each other for a potential date.
            </p>
            <p style={{ fontSize: 18, padding: 10 }}>3. Love4you2.com</p>
            <p style={{ fontSize: 18, padding: 10 }}>
         Love4you2.com is best online dating plaftofrm in denmark. you can found real men and women there.

            </p>
            <p style={{ fontSize: 18, padding: 10 }}>
            Love4you2 is another popular dating app with an incredible interface. It also has a desktop version that boosts its usability. Like all the other apps, it has a free and paid version. You don’t necessarily 
            need the premium version to find a date — the free version is a good choice for that.
            </p>
            <p style={{ fontSize: 18, padding: 10 }}>4. Pre-Mates.com</p>
            <p style={{ fontSize: 18, padding: 10 }}>5. Bumble</p>
            <p style={{ fontSize: 18, padding: 10 }}>6. Her</p>
            <p style={{ fontSize: 18, padding: 10 }}>7. Match</p>
            <p style={{ fontSize: 18, padding: 10 }}>8. Our Time</p>
            <p style={{ fontSize: 18, padding: 10 }}>9. How About We</p>
            <p style={{ fontSize: 18, padding: 10 }}>10. The League </p>
           


          </div>
          <div style={{ width: '22%', padding: 10 }}>
            <h3 style={{ marginBottom: 20, fontSize: 24 }}>Recent Blog</h3>
            <Sliderbar />
          </div>
        </div>

      </div> {/* End Contained */}
      <Footer />
    </div>

  );
};
export default Bfreeseriousrelations;
