import React, { useEffect, useRef } from 'react'
import "./message.css";
import { format } from "timeago.js";
import * as base from "./global";

export default function Message({ message, own }) {
  const listRef = useRef(null);
  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [message])

  console.log('own.......' + message);
  return (
    <div className={own ? "message own" : "message"} ref={listRef}>
      <div className="messageTop">
      <img  className="messageImg" src={`${base.IMAGE_URL}/${message.com_logo}`} />
{/*         <img
          className="messageImg"
          src="https://images.pexels.com/photos/3686769/pexels-photo-3686769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          alt=""
        /> */}
        <p className="messageText">{/* {message.senderid} */} {message.message} {/* {message.friendid} */}</p>
      </div>
      <div className="messageBottom">{format(message.enteredby)}</div>
      <div className="messageBottom"> {message.status}</div>
    </div>
  );
}
