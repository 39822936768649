import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormGroup, FormControl, InputLabel, makeStyles} from '@mui/material';


const Post =() => {

  return (
    <div>
      <Header />
      <Grid container spacing={2} style={{ marginTop: 60 }}>
   
    <Grid item xs={6} md={6}>
      <h3>Create New Post</h3>
      <Paper>
      <h2>Form Demo</h2>
      <FormGroup>
          <FormControl>
          {/*   <InputLabel>Title</InputLabel> */}
            <TextField label={"Title"}/>
          </FormControl>
          <FormControl>
          {/*   <InputLabel>Title</InputLabel> */}
            <TextField label={"Image"}/>
          </FormControl>
          <FormControl>
          {/*   <InputLabel>Title</InputLabel> */}
            <TextField label={"Description"}/>
          </FormControl>
      </FormGroup>


      <Button>Submit</Button>
      <Button>Reset</Button>
    </Paper>
    </Grid>
    </Grid>
    </div>
  )
}
export default Post;