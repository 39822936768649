import React, { useState} from 'react';

 
const Testturorial = () => {
const [color, setColor] = useState("Test");


    return (
        <div>
            {color}
        </div>
    );
}
 

 
export default Testturorial;