//import React, { Component } from 'react';
import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Dashboradpostmore from "./Dashboradpostmore";
import Loader from "./Loader";
import EndMsg from "./EndMsg";

import InfiniteScroll from 'react-infinite-scroll-component';

//https://www.codecheef.org/article/laravel-react-js-pagination-example-from-scratch
//export default class findpeople extends Component {
function Dashboardpost() {
    let history = useHistory();
    const [email, setEmail] = useState(localStorage.getItem("emailaddress"));
    // const [pageNumber, setPageNumber] = useState({pageNumber: '1'});

    const [items, setItems] = useState([]);

    const [hasMore, sethasMore] = useState(true);

    const [page, setpage] = useState(1);
    useEffect(() => {
        const getComments = async () => {
            const res = await fetch(
                base.BASE_CHATURL + `/postlist.php?page=${page}&limit=18&email=${email}`
            );
            const data = await res.json();
            setItems(data);
        };

        getComments();
    }, []);

    const fetchComments = async () => {
        const res = await fetch(base.BASE_CHATURL + `/postlist.php?page=${page}&limit=18&email=${email}`);
        const data = await res.json();
        return data;
    };

    const fetchData = async () => {
        const commentsFormServer = await fetchComments();
        // alert(commentsFormServer.length);
        setItems([...items, ...commentsFormServer]);

        //if (commentsFormServer.length === 0 || commentsFormServer.length < 20) {
        if (commentsFormServer.length === 0 || commentsFormServer.length < 18) {
            sethasMore(false);
        }
        setpage(page + 1);
    };

    return (

        <InfiniteScroll
            dataLength={items.length} //This is important field to render the next data
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            endMessage={<EndMsg />}
            >
{/* <div className="card lg:mx-12 p-10"> */}
                    {items.map((item) => {
                        return <Dashboradpostmore key={item.id} item={item} />;
                    })}
{/* </div> */}
        </InfiniteScroll>

    );
}
export default Dashboardpost;
