import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format } from "timeago.js";


export default class Viewpostpagedetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postid: this.props.data,
            totallikes: '0',
            checkedclick: '0',
            totalcomments: '0',
            isloading: false,
            emailaddress: localStorage.getItem("emailaddress")
        }
    }
    componentDidMount() {
       // alert(this.state.postid);
        this.setState({ isLoading: true });
        this._likestotal();
        this._commentstotal();
       }

    _likestotal = async () => {

        try {
            let response = await fetch(base.BASE_URL + '/likecount/' + this.state.postid);
            console.log(response);
            let res = await response.text();
            if (response.status >= 200 && response.status < 300) {
                let userData = JSON.parse(res);
                 //  alert(userData[0]["totallikes"]);  //studentimage
                //alert(res); 
                this.setState({
                    'totallikes': userData[0]["totallikes"]
                });
               
                this.setState({ isLoading: false });
   
            } else {
                let error = res;
                this.setState({ isLoading: false });
                throw error;
               
            }
        } catch (error) {
        }
   
    };
   
    _commentstotal = async () => {
   
     try {
         let response = await fetch(base.BASE_URL + '/commentscount/' + this.state.postid);
         console.log(response);
         let res = await response.text();
         if (response.status >= 200 && response.status < 300) {
             let userData = JSON.parse(res);
               // alert(userData[0]["totalcomments"]);  //studentimage
             //alert(res); 
             this.setState({
                 'totalcomments': userData[0]["totalcomments"]
             });
             this.setState({ isLoading: false });
   
         } else {
             let error = res;
             this.setState({ isLoading: false });
             throw error;
            
         }
     } catch (error) {
     }
   
   };
   
   //onlikesclick
   onlikesclick = () => {
  // alert(this.state.totallikes);
   if(this.state.checkedclick == '1')
    {
     this.setState({ totallikes: parseInt(this.state.totallikes) - 1, checkedclick: 1 });
    }
    else
    {
       if(this.state.totallikes == null)
       {
       this.setState({ totallikes: 1, checkedclick: 1 });
       }
       else
       {
        this.setState({ totallikes: parseInt(this.state.totallikes) + 1, checkedclick: 1 });
       }
    }
////insert like

fetch(base.BASE_URL + '/insertlikes', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: this.state.emailaddress,
      autoid: this.state.postid
    })
  }).then((Response) => Response.json())
    .then((Result) => {
      if (Result.message == 'success') {
        //  alert('LIke has been submited successfully');
       // this.setState({ xlike: likep, likeshow: true });
        // this.getPhotos(this.state.page);
      }
      else {
        //  alert('Failed to insert like')
      }

    })

////end insert like
   }

    render() {
        return (
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Link to="#" className="flex items-center space-x-2" onClick={this.onlikesclick.bind(this)}>
                    <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                        </svg>
                    </div>
                    <div>  Like </div>
                </Link>
                <Link to={`/comments/${this.state.postid}`} className="flex items-center space-x-2">
                    <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={22} height={22} className="dark:text-gray-100">
                            <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div> Comment</div>
                </Link>

                <div className="flex items-center space-x-3 pt-2">
                        <div className="flex items-center">
                        </div>
                        <div className="dark:text-gray-100">
                          Like <strong>  {
                          
                          this.state.totallikes == null ?
                          '0'
                          :
                          this.state.totallikes
                          
                          
                          } {/* {user.countlike} + {this.state.xlike} */}</strong> and comments<strong> {this.state.totalcomments} Others </strong>
                        </div>
                      </div>
                      <div className="border-t py-4 space-y-4 dark:border-gray-600">
                      </div>
            </div>


        );
    }

}

