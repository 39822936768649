import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from 'reactstrap';
import Footer from '../Footer'
import * as base from "../global";
import { Redirect } from 'react-router';
import axios from 'axios';
//import SocialFlow from "./SocialFlow";
//npm install react-multi-carousel --save
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default class Contactus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  onNameChange(event) {
   // alert(event.target.value)
    this.setState({name: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit(e) {
    e.preventDefault();
  //  alert(this.state.email)
  if(this.state.email == "" || this.state.name=="" || this.state.message=="")
  {
    alert('Insert the Required Fields')
  }
  else
  {
    fetch(base.BASE_URL + '/sendcontactform', {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        name: this.state.name,
        message: this.state.message
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(
    (response) => (response.json())
      ).then((response)=> {
    if (response.message === 'success') {
      alert("Message Sent.");
      
      this.resetForm()

    } else if(response.message === 'required') {
      alert("Message failed to send.")
      this.resetForm()
    } else if(response.message === 'failed') {
      alert("Message failed to send.")
      this.resetForm()
    }
  })
  }

  }
  resetForm(){
    this.setState({name: '', email: '', message: ''})
  }
  render() {
    return (
      <div>
        <div style={{
          backgroundImage: "url('/assets/images/homebanner2.jpg')", width: '100%',
          backgroundSize: 'cover', backgroundPosition: 'center', height: 500, background: "linear-gradient(#e66465, #9198e5);"
        }}>

          <div className="bg-white py-4 shadow dark:bg-gray-800">
            <div className="max-w-6xl mx-auto">
              <div className="flex items-center lg:justify-between justify-around">
                <Link to="/">
                  <img src="./assets/images/logo.png" alt="" class="w-32" />
                </Link>
                <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                  <Link to="/Login" className="py-3 px-4"> Login</Link>
                  <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                    style={{ backgroundColor: '#b50a0a' }}
                  >Register</Link>
                </div>
              </div>
            </div>
          </div>


          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-" style={{ marginTop: 50, marginBottom: 5 }}>

          </div>
        </div>



        <div style={{
          width: '100%', marginTop: 20,
          height: '100%', marginBottom: 20
        }}>
          <div className="content">
            <div class="mcontainer">
              <h1 style={{ textAlign: 'center', fontSize: '2rem' }}><b>
                Contact Us
              </b> </h1>
            </div>

          </div>

        </div> {/* End Fature Profile */}
        {/* About Dating Site */}

        {/* Responsive div */}

        <div className="lg:flex max-w-5xl mx-auto mb-12" style={{ borderWidth: 0, borderColor: 'red' }}>
          <div className="flex flex-col items-center lg: lg:flex-row">

            <div className="mb-12" style={{ padding: 20 }}>

              <b> Office Address </b>
              <p>HC Oerstedsvej 41C, 1879, Frederiksberg, Denmark</p>
              <p> Live Support </p>


            </div>



          </div>


          <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ padding: 20 }}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control"  value={this.state.name} onChange={this.onNameChange.bind(this)}/>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" className="form-control" aria-describedby="emailHelp"  value={this.state.email} onChange={this.onEmailChange.bind(this)} />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
            </div>
            <button type="submit" className="bg-blue-600 font-semibold p-3 rounded-md text-center text-white w-full"
            style={{ padding: 20, marginTop: 20 }}
            >Submit</button>
          </form>
        </div>
        <Footer />

      </div>
    );
  }
}
