import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter, router, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router';
import * as base from "../global";
import axios from 'axios';

const Sliderbar = () => {
    return (

        <div>

                <ul>
                    <li>
                        <Link to="/Best-Free-Dating-sites">Best Free Dating sites </Link>
                    </li>
                    <li>
                        <Link to="/best-for-marriage-seekers">Best for marriage seekers</Link>
                    </li>
                    <li>
                        <Link to="/best-free-dating-sites-for-serious-relations">Best Free Dating Sites for Serious Relationships </Link>
                    </li>
                    <li>
                        <Link to="/top-10-free-dating">10 of the Best Free Dating Apps of 2022 </Link>
                    </li>
                    <li>
                        <Link to="/dating-sites-for-single">Dating sites for singles </Link>
                    </li>

                </ul>
        </div>

    );
};
export default Sliderbar;
