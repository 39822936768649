import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from 'reactstrap';
import Footer from '../Footer'

import { Redirect } from 'react-router';
import axios from 'axios';
//import SocialFlow from "./SocialFlow";
//npm install react-multi-carousel --save
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default class Contactus extends Component {
  render() {
    return (
        <div>
        <div style={{
          backgroundImage: "url('/assets/images/homebanner2.jpg')", width: '100%',
          backgroundSize: 'cover', backgroundPosition: 'center', height: 500, background: "linear-gradient(#e66465, #9198e5);"
        }}>

          <div className="bg-white py-4 shadow dark:bg-gray-800">
            <div className="max-w-6xl mx-auto">
              <div className="flex items-center lg:justify-between justify-around">
                <Link to="/">
                  <img src="./assets/images/logo.png" alt="" class="w-32" />
                </Link>
                <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                  <Link to="/Login" className="py-3 px-4"> Login</Link>
                  <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                    style={{ backgroundColor: '#b50a0a' }}
                  >Register</Link>
                </div>
              </div>
            </div>
          </div>


          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-" style={{ marginTop: 50, marginBottom: 5 }}>

          </div>
        </div>



        <div style={{
          width: '100%', marginTop: 20,
          height: '100%', marginBottom: 20
        }}>
          <div className="content">
            <div class="mcontainer">
              <h1 style={{ textAlign: 'center', fontSize: '2rem' }}><b>
                  About Us
                  </b> </h1>
            </div>

          </div>

        </div> {/* End Fature Profile */}
        {/* About Dating Site */}

        {/* Responsive div */}

        <div className="lg:flex max-w-5xl mx-auto mb-12" style={{ borderWidth: 0, borderColor: 'red' }}>
          <div className="flex flex-col items-center lg: lg:flex-row">

            <div className="mb-12" style={{ padding: 20 }}>

        

            </div>



          </div>
        </div>
            <Footer />

      </div>
    );
  }
}
