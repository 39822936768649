//import React, { Component } from 'react';
import React, {useEffect,useState} from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';
import { io } from "socket.io-client";

//https://www.codecheef.org/article/laravel-react-js-pagination-example-from-scratch
//export default class findpeople extends Component {
  function Notificationcheck() {
    const [state, setData] = useState({users: ''});
  //  const [email, setEmail] = useState(localStorage.getItem("emailaddress"));
  //  const [fromage, setFromage] = useState(props.match.params.frmid);
  //  const [toage, setToage] = useState(props.match.params.toid);



  useEffect(() => {
    const socket = io("http://localhost:5000");

console.log(socket.on("fistmessage", (msg) => 
{
    console.log(msg);
}
))

  }, [])


    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            {/* Main people List */}


                
            <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">



           </div>
        </div>




        </div>
        
    
      </div>
    );
  }
  export default Notificationcheck;
