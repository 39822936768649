import React, { useRef, useEffect, useState } from "react";
import * as base from "./global";
import { useHistory } from "react-router-dom";

export default function Paypal() {
  const paypal = useRef();
  const history = useHistory()
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: localStorage.getItem("packagename"),
                amount: {
                  currency_code: "USD",
                  value: localStorage.getItem("packageprice")                                                                                                                                                                           ,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {

          const order = await actions.order.capture();
        //  alert(order.status);
          console.log(order.id);  
          console.log(order.status);
          console.log('dddd: ' + data); 
          console.log('orderlogs' + actions);
        //  alert('Payment Approve Successfully');
////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////
      fetch(base.BASE_URL + '/paypalpayment', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paypalemail: localStorage.getItem("emailaddress"),
          transactionid: order.id,
          price: localStorage.getItem("packageprice"),
          currency: 'USD',
          packagename: localStorage.getItem("packagename"),
          paymentstatus: order.status

        })
      }).then((Response) => Response.json())
        .then((Result) => {
        //  if (Result.message == 'success') { COMPLETED+
        if (order.status == 'COMPLETED') { 
           // alert('Email send successfully');
          //  this.setState({ loggdin: true })
          setMessage('complete');
          history.push("/Paymentsuccessfully");
          }
          else {
            alert('Failed to make Transaction')
          }

        })

      /////////////////////////////////////////////////////////////////////



        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);

  return (

    <div>
      <div ref={paypal}></div>
    </div>
  );
}