
import * as base from "./global";
import { Link } from 'react-router-dom';
import { format } from "timeago.js";


function Inboxdetails({item: { id, firstname, lastname, com_logo, profileview, xage, senderid, enteredby }}) {
    return (
/*         <div className="col-sm-4 my-2" >
      <div className="card shadow-sm w-100" style={{ minHeight: 225 }}>
        <div className="card-body">
          <h5 className="card-title text-center h2">Id : {id}</h5>
          <h6 className="card-subtitle mb-2 text-muted text-center">{firstname}</h6>

        </div>
      </div>
    </div> */

    <div className="card">
    <div className="card-media h-40">
      <div className="card-media-overly" style={{ padding: 10 }} />
      <Link to={`/viewinformation/${id}/${firstname}/${lastname}`}>
      <img src={`${base.IMAGE_URL}/${com_logo}`} 
      style={
      {
        flex: 1,
        resizeMode: 'contain',
        backgroundColor: 'yellow'
      }          
    }/>
      </Link>
      <span className="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
      <Link to={`/chat/${id}`}>
      <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
        <i className="icon-feather-heart"> </i>
      </button>
      </Link>
    </div>
    <div className="card-body">
      <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
      {xage} yrs
      </div>
      <div className="text-xs font-semibold uppercase text-yellow-500">View {profileview}</div>
      <div className="ext-lg font-medium mt-1 t truncate"> {firstname}  {lastname} </div>

      <div className="ext-lg font-medium mt-1 t truncate" style={{ backgroundColor: 'green', color: 'white', padding: 10 }}>
      <Link to={`/chat/${id}`}>View Message</Link>
      </div>
      <div className="ext-lg font-medium mt-1 t truncate" style={{ backgroundColor: 'red', color: 'white', padding: 10 }}>
      <Link to={`/Sendemail/${id}`}>Send Email</Link>
      </div>
      <div className="ext-lg font-medium mt-1 t truncate" style={{ backgroundColor: 'yellow', color: '#000', padding: 10 }}>
      <Link to={`/Sendemail/${id}`}> {format(enteredby)}</Link>
      </div>
      
    </div>
  </div>



    )
}

export default Inboxdetails