import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem, } from 'react'
import { Redirect } from 'react-router';
import Header from '../Layout/Header'

import { Button, Row, Col, Container } from 'react-bootstrap';

import * as base from "./global";

import axios from 'axios';


export default class Paymentmethod extends Component {

  constructor(props) {
    super(props);
    this.paypalpackage = this.paypalpackage.bind(this);
    this.visapackage = this.visapackage.bind(this);
    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      datafiveuser: [],
      show: false,
      email: localStorage.getItem("emailaddress"),
      pcakageprice: localStorage.getItem("packageprice"),
      paymenttypecheck: ''
    }


  }
  componentDidMount() {
    //  alert(this.state.email);
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datafiveuser: res.data });
    });

  }
  handleModal() {
    this.setState({ show: !this.state.show })
  }
  visapackage() {

    localStorage.setItem("paymenttypeselect", 'visa');
    this.setState({ paymenttypecheck: 'visa' })

  }
  paypalpackage() {
    //alert();
    localStorage.setItem("paymenttypeselect", 'visa');
    this.setState({ paymenttypecheck: 'paypal' })

  }

  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }
    if (this.state.paymenttypecheck == 'visa') {
      return <Redirect to="/Payment/10" />
      /*  return <Redirect to="/Paymentvisa" /> */

    }
    if (this.state.paymenttypecheck == 'paypal') {
      return <Redirect to="/Paypalpage" />
    }

    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            <div className="flex justify-between items-center relative md:mb-4 mb-3">
              <div className="flex-1">
                <h2 className="text-2xl font-semibold"> Payment Method </h2>
              <h3  className="text-2xl font-semibold" style={{ fontSize: 16 }}>

                  Your Selected {localStorage.getItem("packagename")}   {localStorage.getItem("packageprice")} USD

                </h3>

              </div>

            </div>
            <div className="grid-cols-6">
            {
                    this.state.pcakageprice == "10" ?
                      <a href={base.payment_stripe + '/index.php?custid=' + this.state.email + '&pacid=' + this.state.pcakageprice} className="is_link" style={{ justifyContent: 'center', textAlign: 'center' }} target="_blank">
                        {/* onClick={this.visapackage} */}

                        <img src="/assets/images/visa.jpg" alt />
                      </a>
                      :
                      <a href={base.payment_stripe + '/index.php?custid=' + this.state.email + '&pacid=' + this.state.pcakageprice} className="is_link" style={{ justifyContent: 'center', textAlign: 'center' }} target="_blank">
                        {/* onClick={this.visapackage} */}

                        <img src="/assets/images/visa.jpg" alt />
                      </a>
                  }
            </div>
            <div className="grid-cols-6">
            <Link to="#" className="is_link" style={{ justifyContent: 'center', textAlign: 'center' }}
                    onClick={this.paypalpackage}
                  >
                    <img src="/assets/images/paypal.jpg" style={{ borderRadius: 20 }} alt />
                  </Link>
            </div>






            {/*           <div className="mcontainer">
            <div class="flex justify-between items-center relative md:mb-4 mb-3">
              <div class="flex-1">
                <h2 class="text-2xl font-semibold"> Pricing </h2>

              </div>

            </div>
            <div className="grid-cols-6">
              <div style={{ width: '100%' }}>
              <Link to="/Payment/1"><b>Silver Package (Monthly 10 USD)</b>
              
              <p>Unlimited message send and use unlimited untill month is finish. you can renew any time.</p>
              <div className="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb', 
              width: 140, borderRadius: 15, padding: 8, height: 40 }}>10 USD Buy Now</div>
              </Link>
              </div>
              <div>
           
              </div>
            </div>
            <div className="grid-cols-6" style={{ padding: 20 }}>
            <Link to="/Payment/2" className="is_link"><p>Gold Package(6 Month 40 USD)</p>
               <p>Unlimited message send and use unlimited untill month is finish. you can renew any time.</p>

              <div className="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb', 
              width: 140, borderRadius: 15, padding: 8, height: 40 }}>40 USD Buy Now</div>
              </Link>
              <Link to="/Paypalpage/40" className="is_link">
              <div className="is_link" style={{ backgroundColor: '#e5edff', color: '#2563eb', 
              width: 140, borderRadius: 15, padding: 8, height: 40 }}>40 USD Pay by Paypal</div>
                </Link>
            </div>




          </div>
 */}

          </div>
        </div>
      </div>
    );
  }
}
