import React, { Component } from "react";
import FacebookLogin from "react-facebook-login"; //npm install react react-dom react-facebook-login --save --force
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Button, Form } from 'reactstrap';


export default class Loginwithfacebook extends Component {

  constructor() {
    super();

  const year = 1921;
  this.years = Array.from(new Array(80),(val, index) => index + year);

  const token = localStorage.getItem("token")
  let loggdin = true;
  if (token == null) {
    loggdin = false
  }

  this.state = {
    isLoggedIn: false,
    userID: "",
    name: "",
    email: "",
    picture: "",
    Password: '999793',
    isLoading: false,
    fields: {},
    errors: {},
    datauserprofile: [],
    image: 'maleavter.png',
    loggdin: 'stop',
    firstname: '',
    lastname: '',
    file: null,
    country: '',
    countrylist: [],
    statelist: [],
    states: '',
    citylist: [],
    statesname: '',
    countryname: '',
    cityname: '',
    city: '',
    uploadstatus:''
  }
  this.register = this.register.bind(this);
  this.handlecity = this.handlecity.bind(this);
  this.handlecountry = this.handlecountry.bind(this);
  this.handlestate = this.handlestate.bind(this);
}
componentDidMount() {

  if (this.state.email == null) {
    this.state = {

    }
  }
  else {
  }
   
  // alert(this.state.email)
  axios.get(base.BASE_URL + '/countrylist').then(result => {
   this.setState({ countrylist: result.data });
 });

}
handlecountry(event) {
  this.setState({
    country: event.target.value
  })

  this.setState({ statelist: [] });
  ///////////////////////////////
  axios.get(base.BASE_URL + '/statelist/' + event.target.value).then(result => {
    this.setState({ statelist: result.data });
  });
  ///////////////////////////////
}
handlestate(event) {
  this.setState({
    states: event.target.value
  })
  this.setState({ citylist: [] });
  //////////////////////////////////
  axios.get(base.BASE_URL + '/citieslist/' + event.target.value).then(result => {
    this.setState({ citylist: result.data });
  });
  ////////////////////////////////

}
handlecity(event) {
  this.setState({
    city: event.target.value
  })

}

fileSelect = event => {
  this.setState({ selectedFile: event.target.files[0], file : URL.createObjectURL(event.target.files[0]) })
  console.log(URL.createObjectURL(event.target.files[0]))
}

register() {

if(this.state.fields["gender"] == '' || this.state.fields["interestedin"] =='' || this.state.fields["date"] == '' || this.state.fields["month"] == '' || this.state.fields["year"] == '')
{
alert('Insert the Required Fields');
}
else
{
  this.setState({ isLoading: true });

  const fd = new FormData();
  fd.append('image', this.state.selectedFile, this.state.selectedFile.name);
  fd.append('email', this.state.email);
  fd.append('aboutme', this.state.fields["aboutme"]);
  fd.append('lookingfor', this.state.fields["lookingfor"]);
  fd.append('country', this.state.country)
  fd.append('states', this.state.states)
  fd.append('city', this.state.city)
  fd.append('gender', this.state.fields["gender"])
  fd.append('interestedin', this.state.fields["interestedin"])
  fd.append('date',  this.state.fields["date"])
  fd.append('month',  this.state.fields["month"])
  fd.append('year',  this.state.fields["year"])

   


  axios.post(base.BASE_URL + '/registration_facebookimage', fd
  ).then(res => {
      // alert(res.message);
      if (res.status == '200') {

          this.setState({ loggdin: true, uploadstatus: 'ok' })
         // alert('successfully');
      }
      else {
          alert('Failed To Upload')
      }
      console.log(res);
  }
  );

}
 
    /////////////////////////////////////////////////////////////////////

}
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  responseFacebook = response => {
    // console.log('facebookurl' + response);

    this.setState({
      isLoggedIn: true,
      userID: response.userID,
      name: response.name,
      email: response.email,
      picture: response.picture.data.url
    });

    /////////////////////////////////////////////////////

    const formdata = {
      firstname: response.name,
      lastname: '',
      email: response.email,
      password: this.state.Password,
      gender: 'x',
      xdate: '',
      month: '',
      year: '',
      interestedin: ''
    }


    axios.post(base.BASE_URL + "/facebooksignup", formdata, {
    }).then(res => {
      console.log('KKKKKK :' + res);
      console.log('data....' + res.data)
      console.log('data..file..' + res.data.message);
      //res.statusText 
      if (res.data.message == 'Registration completed successfully') {
        console.log('Monir iii' + response.email)
        localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
        localStorage.setItem("emailaddress", response.email)

        this.setState({ isLoading: false, loggdin: 'start' })
      }
      else if (res.data.message == 'email already exits') {
        localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
        localStorage.setItem("emailaddress", response.email)
        this.setState({ isLoading: false, loggdin: 'start', uploadstatus: 'ok' })
        console.log('Monir' + response.email)
      }
      else {
        this.setState({ loggdin: false, isLoading: false })
        alert('Failed');
      }
    })

    /////////////////////////////////////////////////////
  };

  componentClicked = () => console.log("clicked");

  render() {

         if (this.state.uploadstatus == 'ok') {
            return <Redirect to="/Dashboard" />
          }
          if (this.state.loggdin == true )
          {
            return <Redirect to="/Dashboard" />
          }

    let fbContent;




    if (this.state.isLoggedIn) {
      fbContent = (
        <div>


      
      
        <Form className="p-6 space-y-4 relative bg-white shadow-lg rounded-lg">
          <div style={{ marginTop: 5 }}>
    
            <table style={{ width: '100%' }}>
              <tr>
                <td>
                <img src={this.state.picture} alt={this.state.name} />
                </td>
              </tr>
              <tr>
                <td>
                <h2>Welcome {this.state.name}   Email: {this.state.email}</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <select onChange={this.handleChange.bind(this, "gender")}
                    value={this.state.fields["gender"]} className="with-border" 
                    style={{ padding: 15, backgroundColor: '#f2f2f2' }}
                  >
                    <option value="" selected disabled>I am</option>
                    <option value="Male">Male</option>
                    <option value="Female" >Female</option>
                    <option value="Unknown">Unknown</option>
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["gender"]}</span>
                </td>
                <td>
                  <select onChange={this.handleChange.bind(this, "interestedin")} value={this.state.fields["interestedin"]} className="with-border"
                    style={{ padding: 6, backgroundColor: '#f2f2f2' }}
                  >
                    <option value="" selected disabled>Looking for </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Unknown">Unknown</option>
                  </select>
                  <span style={{ color: "red" }}>{this.state.errors["iammen"]}</span>
                </td>
              </tr>
            </table>
    
    
          </div>

                <div>
                  <label className="mb-0"> Date of Birth </label>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <td>
{/*                         <input type="number" placeholder="Date" name="date" 
                         
                          className="bg-gray-100" /> */}
                        <select name="date" style={{ width: 80 }}  onChange={this.handleChange.bind(this, "date")} value={this.state.fields["date"]}>
                        <option value="Date" selected disabled >Date</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>

                        </select>



                        <span style={{ color: "red" }}>{this.state.errors["date"]}</span>
                      </td>

                      <td>

                      <select name="date" style={{ width: 80 }}  
                       onChange={this.handleChange.bind(this, "month")} value={this.state.fields["month"]}
                      >
                        <option value="Month" selected disabled >Month</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["month"]}</span>
                      </td>
                      <td>

<select name="year" onChange={this.handleChange.bind(this, "year")} 
value={this.state.fields["year"]} style={{ width: 100 }}>
  <option value="0" selected disabled>Year</option>
{
this.years.map((year, index) => {
return <option key={`year${index}`} value={year}>{year}</option>
})
}
</select>



{/*                         <input type="number" maxlength="4" minLength="4" placeholder="year" name="year" 
                         
                          className="bg-gray-100" /> */}
                        <span style={{ color: "red" }}>{this.state.errors["year"]}</span>
                      </td>

                    </tr>
                    <tr>
                      <td colSpan={2}>
                      <label className="mb-0"> Upload Profile Picture </label>
                      <input type="file" accept="image/*" onChange={this.fileSelect} title="Choose your profile picture" />
                      </td>
                      <td>
                      <img src={this.state.file} style={{ height: 50, width: 50 }}/>

                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>   <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                                    rows={5} placeholder=" write something about your self"
                                    style={{ borderWidth: 1, backgroundColor: '#eaeaea' }}
                                    onChange={this.handleChange.bind(this, "aboutme")} value={this.state.fields["aboutme"]}
                                />
</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                      <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                                    rows={5} placeholder=" What are you looking for  "
                                    style={{ borderWidth: 1, backgroundColor: '#eaeaea' }}
                                    onChange={this.handleChange.bind(this, "lookingfor")} value={this.state.fields["lookingfor"]}
                                />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                            <table style={{ width: '100%' }}>
                  <tr>
                    <td>Country</td><td>State</td><td>City</td>
                  </tr>
                  <tr>
                    <td>
                      <select className="with-border" onChange={this.handlecountry}>


                        <option selected value={this.state.country}>{this.state.countryname}</option>
                        {
                          this.state.countrylist.map((result) => {
                            return (

                              <option value={result.id}>{result.name}</option>

                            )
                          })}
                      </select>
                    </td>
                    <td>
                      <select className="with-border" onChange={this.handlestate}>
                        <option selected value={this.state.states}>{this.state.statesname}</option>
                        {
                          this.state.statelist.map((result) => {
                            return (
                              <option value={result.id}>{result.name}</option>
                            )
                          })}
                      </select>

                    </td>
                    <td>
                      <select className="with-border" onChange={this.handlecity}>
                        <option selected value={this.state.city}>{this.state.cityname}</option>
                        {
                          this.state.citylist.map((result) => {
                            return (
                              <option value={result.id}>{result.name}</option>
                            )
                          })}
                      </select>
                    </td>
                  </tr>
                </table>

                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                      <Button type="button" className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full" 
                  onClick={this.register}  disabled={this.state.isLoading ? true : false}>
                    Continue {this.state.isLoading ? 'checking...' : ''}</Button>

                      </td>
                    </tr>
                  </table>

                </div>

    
        </Form>

        </div>
      );
    } else {
      fbContent = (

        <div
        style={{
          width: "400px",
          margin: "auto",
          background: "#f4f4f4",
          padding: "20px"
        }}
      >
        <FacebookLogin
          appId="3274995669453011"
          autoLoad={true}
          fields="name,email,picture"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
        />
    </div>



      );
    }

    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>{fbContent}</div>;
  }
}