import React from 'react'
import { Link } from 'react-router-dom';




const Footer = () => {
	return (
		<div style={{ width: '100%', backgroundColor: '#dddede' }}>
			<div className='halfdiv'>
				<div className='firstdiv'>

					<ul>
						<li>
							<Link to="/Contactus">Contact Us</Link>
						</li>
						<li>
							<Link to="/Aboutus">About Us</Link>
						</li>
						<li>
							<Link to="/Blog">Blog</Link>
						</li>
						<li>
							<Link to="/Privatepolicy">Private Policy</Link>
						</li>
						<li>
							<Link to="/Privatepolicy">Live Support</Link>
						</li>
					</ul>
				</div>
				<div className='firstdiv'>
					<ul>
						<li>
							<Link to="/free-dating">free dating </Link>
						</li>
						<li>
							<Link to="/free-dating-website-in-denmark">free dating website in denmark, </Link>
						</li>
						<li>
							<Link to="/100-percent-dating-website">100% Free Dating Website, </Link>
						</li>
						<li>
							<Link to="/single-mom-dating">Single Mom Dating </Link>
						</li>
						<li>
							<Link to="/old-men-dating-website">Old Men Dating Website </Link>
						</li>

					</ul>
				</div>
				<div className='firstdiv'>

					<ul>
						<li>
							<Link to="/Best-Free-Dating-sites">Best Free Dating sites </Link>
						</li>
						<li>
							<Link to="/best-for-marriage-seekers">Best for marriage seekers</Link>
						</li>
						<li>
							<Link to="/best-free-dating-sites-for-serious-relations">Best Free Dating Sites for Serious Relationships </Link>
						</li>
						<li>
							<Link to="/top-10-free-dating">10 of the Best Free Dating Apps of 2022 </Link>
						</li>
						<li>
							<Link to="/dating-sites-for-single">Dating sites for singles </Link>
						</li>

					</ul>

				</div>
				<div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
					<div>



					</div>

					<p>Copyright © 2022 Webshopedia, Denmark </p>
				</div>

			</div>

		</div>

	);
};
export default Footer;
