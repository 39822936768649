import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter, router, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router';
import * as base from "../page/global";
import Popup from './Popup'
import axios from 'axios';
import { format } from "timeago.js";

//https://www.geeksforgeeks.org/reactjs-toast-notification/
// Importing toastify module
import { toast } from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import Chatlist from '../page/Chatlist';
toast.configure();
//import Autocomplete from '../page/Autocomplete';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.flogout = this.flogout.bind(this);
    this.handlefindtxtsearchChange = this.handlefindtxtsearchChange.bind(this);
    this.onClicksearchmobile = this.onClicksearchmobile.bind(this);
    this.finddata = this.finddata.bind(this);
    this.checknotification = this.checknotification.bind(this);
    this.closefriend = this.closefriend.bind(this);
    this.closemobilemenu = this.closemobilemenu.bind(this);
    this.togglePopup = this.togglePopup.bind(this);

    this.state = {
      emailaddress: localStorage.getItem("emailaddress"),
      datayourmatch: [],
      datauserprofile: [],
      allcurrentuserinbox: [],
      myprofilevisitorview: [],
      image: 'maleavter.png',
      fullname: '',
      txtsearch: '',
      txtglobelsearch: 'No',
      onclickmobilesearchvalue: 'NO',
      mobilemenuopen: 'off'
    }

  }
  togglePopup() {
    this.setState({
      mobilemenuopen: 'off'
    });
  }
  closefriend() {
    ///alert('dddd');
    window.location.reload();
  }
  closemobilemenu() {
    ///alert('dddd');
    if(this.state.mobilemenuopen == 'on')
    {
      this.setState({ 'mobilemenuopen': 'off' })
    }
    else
    {
      this.setState({ 'mobilemenuopen': 'on' })
    }
   
  }
  onClicksearchmobile() {
    this.setState({ onclickmobilesearchvalue: 'Yes' });

  }



  handlefindtxtsearchChange(event) {
    // alert(event.target.value)
    this.setState({ txtsearch: event.target.value });
  }
  flogout() {
    const token = localStorage.removeItem("token");
    const emailaddress = localStorage.removeItem("emailaddress");
    this.state = {
      emailaddress: emailaddress
    }

  }
  /*   componentDidMount() {
      // alert(this.state.email);
  
      setTimeout(
        function() {
            this.checknotification();
        }
        .bind(this),
        3000
    ); */

  componentDidMount() {

    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datayourmatch: res.data });
    });

    axios.get(base.BASE_URL + '/myprofilevisitorview/' + this.state.emailaddress).then(res => {
      this.setState({ myprofilevisitorview: res.data });
    });

    axios.get(base.BASE_URL + '/viewprofile/' + this.state.emailaddress).then(res => {

      //  alert(res.data.firstname)

      if (res.data.firstname == 'undefined') {
        alert('insert the Profile');

      }
      else {
        this.setState({ datauserprofile: res.data, fullname: res.data.firstname });
      }



    });
    //currentuserinbox
    axios.get(base.BASE_URL + '/currentuserinbox/' + this.state.emailaddress).then(res => {
      this.setState({ allcurrentuserinbox: res.data });
    });


    //this is for messsage notification popup    
    const intervalId = setInterval(() => {
      this.checknotification();
    }, 10000)
    return () => {
      clearInterval(intervalId); //This is important

    }
    //End this is for messsage notification popup  
    //end currentuserinbox
  }
  /*   viewinformationclick(id, firstname, lastname){
           this.props.history.push('/viewinformation/' + id + '/' + firstname + '/' + lastname)
          
    } */
  handlefindtxtsearchChange(event) {
    this.setState({ txtsearch: event.target.value });
  }
  finddata(event) {
    // alert('An essay was submitted: ' + this.state.txtsearch);
    event.preventDefault();
    this.setState({
      txtglobelsearch: 'Yes'
    })
    //  alert(this.state.txtglobelsearch); 
    // this.setState({

    //    txtglobelsearch: 'Yes'
    //  })
  }
  checknotification() {
    let isMounted = true;


    axios.get(base.BASE_URL + '/checkinboxmessage/' + this.state.emailaddress).then(res => {
      // alert(res.data);
      if (res.data > 0) {
        toast.dark('Some one send you message check your inbox', {
          position: toast.POSITION.BOTTOM_LEFT, autoClose: 15000
        }) //// Set to 15sec

        toast.dark('Some one send you message check your inbox',
          { position: toast.POSITION.TOP_RIGHT })
      }
    });
    /*   toast('Hello Geeks 4',
      {position: toast.POSITION.BOTTOM_LEFT})
    toast('Hello Geeks 6',
      {position: toast.POSITION.BOTTOM_RIGHT})
    toast('Hello Geeks 5',
      {position: toast.POSITION.BOTTOM_CENTER})
    toast('Hello Geeks 1',
      {position: toast.POSITION.TOP_LEFT}) */

    /* toast.dark('Hello Geeks 2',
      {position: toast.POSITION.TOP_CENTER})
     */


    // toast.dark("Hey 👋, see how easy!");
    //toast('Hello Geeks')// Default
    //alert('ddddd');
    //toast.warning('Danger', {autoClose:10000})

    // Set to 3sec
    //toast.success('successful', {autoClose:3000})
    // User have to close it
    //toast.info('GeeksForGeeks', {autoClose:false})


  }
  render() {
    if (this.state.emailaddress == "") {
      return <Redirect to="/" />
    }
    /* 
            if (this.state.txtglobelsearch == "Yes") {
              const url = this.state.txtsearch;
              return <Redirect to={`/Findbyname/${url}`} />
            } */
    if (this.state.txtglobelsearch == "Yes") {
      const url = this.state.txtsearch;
      //return <Redirect to='/Findmovie' data={this.state.txtsearch} />
      return <Redirect to={`/Findbyname/${url}`} />
    }
    //onclickmobilesearchvalue
    if (this.state.onclickmobilesearchvalue == "Yes") {

      return <Redirect to={`/Findpeople`} />
    }

    return (
      <div>

        <header>
          <div className="header_wrap">
            <div className="header_inner mcontainer">
              <div className="left_side">
                <span className="slide_menu" uk-toggle="target: #wrapper ; cls: is-collapse is-active">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}
                    onClick={this.closemobilemenu}
                  >

                    <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="currentColor" /></svg>
                </span>
                <div id="logo">
                  <Link to="/dashboard">
                    <img src="/assets/images/logo.png" />
                    <img src="/assets/images/logo.png" className="logo_mobile" />
                  </Link>
                </div>
              </div>
              {/* search icon for mobile */}

              <div className="header-search-icon" uk-toggle="target: #wrapper ; cls: show-searchbox"
                onClick={this.onClicksearchmobile}> </div>

              <div className="mobileupgrademember"><Link to="/upgrade"> Upgrade </Link> </div>

              <div className="header_search"><i className="uil-search-alt" />
                {/*   https://www.digitalocean.com/community/tutorials/react-react-autocomplete */}
                <form onSubmit={this.finddata}>
                  <input type="text" name="txtsearch" id="txtsearch"
                    className="form-control" placeholder=" Search for Friends , Videos and more.." autoComplete="off"
                    value={this.state.txtsearch} onChange={this.handlefindtxtsearchChange}
                  />
                </form>

              </div>

              <div className="right_side">
                <div className="header_widgets">
                  <Link to="/upgrade" className="is_link">  Upgrade </Link>
                  <Link to="/Profilevisitor" className="is_icon" uk-tooltip="title: Notifications">
                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" /></svg>
                    <span> {this.state.myprofilevisitorview.length}</span>
                  </Link>
                  <div uk-drop="mode: click" className="header_dropdown">
                    <div className="dropdown_scrollbar" data-simplebar>
                      <div className="drop_headline">
                        <h4>Notifications </h4>
                        <div className="btn_action">
                          <a href="#" data-tippy-placement="left" title="Notifications">
                            <ion-icon name="settings-outline" />
                          </a>
                          <a href="#" data-tippy-placement="left" title="Mark as read all">
                            <ion-icon name="checkbox-outline" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <Link to="/Profilevisitor" className="see-all"> See all in Notifications</Link>
                  </div>
                  {/* Message */}
                  <Link to="/Profilevisitor" className="is_icon" uk-tooltip="title: Message">
                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" /></svg>
                    <span>{this.state.allcurrentuserinbox.length}</span>
                  </Link>
                  <div uk-drop="mode: click" className="header_dropdown is_message">
                    <div className="dropdown_scrollbar" data-simplebar>
                      <div className="drop_headline">
                        <h4>Messages </h4>
                        <div className="btn_action">
                          <a href="#" data-tippy-placement="left" title="Notifications">
                            <ion-icon name="settings-outline" uk-tooltip="title: Message settings ; pos: left" />
                          </a>
                          <a href="#" data-tippy-placement="left" title="Mark as read all">
                            <ion-icon name="checkbox-outline" />
                          </a>
                        </div>
                      </div>
                      {/*  <input type="text" className="uk-input" placeholder="Search in Messages" /> */}
                      <ul>
                        {this.state.allcurrentuserinbox.map((result) => {
                          return (
                            <li className="un-read">
                              <Link to={`/chat/${result.id}`}>

                                <div className="drop_avatar">
                                  {/*  <img src="assets/images/avatars/avatar-7.jpg" alt /> */}
                                  <img src={`${base.IMAGE_URL}/${result.com_logo}`} style={{ width: 50, height: 50, borderRadius: 25 }} />
                                </div>
                                <div className="drop_text">
                                  <strong> {result.firstname} {result.lastname} </strong> <time>{format(result.enteredby)}</time>
                                  <p>  {result.message} ...</p>
                                </div>
                              </Link>
                            </li>
                          )
                        })}

                      </ul>
                    </div>
                    <Link to="/Inbox" className="see-all"> See all in Messages</Link>
                  </div>
                  <a href="#">
                    {this.state.datauserprofile.map((result) => {
                      return (
                        <div>
                          {
                            result.com_logo == null ?

                              <img src={`${base.IMAGE_URL}/${this.state.image}`} className="is_avatar" alt={result.firstname} />
                              :

                              <img src={`${base.IMAGE_URL}/${result.com_logo}`} className="is_avatar" />
                          }
                        </div>


                      )
                    })}
                  </a>
                  <div uk-drop="mode: click;offset:5" className="header_dropdown profile_dropdown">
                    {this.state.datauserprofile.map((result) => {
                      return (
                        <Link to="/Profile" className="user">

                          <div className="user_avatar">
                            {/*   <img src="../assets/images/avatars/avatar-2.jpg" alt /> */}
                            {
                              result.com_logo == null ?

                                <img src={`${base.IMAGE_URL}/${this.state.image}`} alt />
                                :

                                <img src={`${base.IMAGE_URL}/${result.com_logo}`} className="w-full h-full object-cover absolute" />
                            }


                          </div>
                          <div className="user_name">
                            <div style={{ fontSize: 12 }}>{this.state.emailaddress} </div>
                            <span>{result.firstname} {result.lastname}</span>
                          </div>

                        </Link>
                      )
                    })}
                    <hr />
                    <Link to="/Upgrade" className="is-link" uk-toggle="target: #create-post-modal">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clipRule="evenodd" /></svg>
                      Upgrade To Premium
                    </Link>
                    <hr />
                    <Link to="/Findpeople">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg>
                      Find People
                    </Link>
                    <Link to="/Createpost">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg>
                      Create Post
                    </Link>
                    <Link to="/Photopost">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg>
                      Post image
                    </Link>

                    <Link to="/profile">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg>
                      My Account
                    </Link>
                    {/*                     <Link to="group-feed.html">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                      </svg>
                      Manage Pages
                    </Link> */}
                    <Link to="/Mybilling">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" /><path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" /></svg>
                      My Billing
                    </Link>
                    <Link to="/changepassword">
                      <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" /><path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" /></svg>
                      Change Password
                    </Link>

                    <Link to="/" onClick={this.flogout}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                      Log Out
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* sidebar */}
        <div className="sidebar">
          <div className="sidebar_header">
            <img src="/assets/images/logo.png" alt />
            <img src="" className="logo-icon" alt />
            <span className="btn-mobile" uk-toggle="target: #wrapper ; cls: is-collapse is-active" />
          </div>
          <div className="sidebar_inner" data-simplebar>
            <ul>
              <li className="active">
                <Link to="/profile">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-blue-600">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                  <span> Profile </span>
                </Link>
              </li>
              <li><Link to="/Findpeople">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-yellow-500">
                  <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                </svg>
                <span> Find Member </span> </Link>
              </li>
              {/*   <li id="more-veiw" hidden><Link to="/Myphotoshow"> */}
              <li id="more-veiw"><Link to="/Myphotoshow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-blue-500">
                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                </svg>  <span>  Photos </span></Link>
              </li>
              <li><Link to="/AddMyvideo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-red-500">
                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm3 2h6v4H7V5zm8 8v2h1v-2h-1zm-2-2H7v4h6v-4zm2 0h1V9h-1v2zm1-4V5h-1v2h1zM5 5v2H4V5h1zm0 4H4v2h1V9zm-1 4h1v2H4v-2z" clipRule="evenodd" />
                </svg>
                <span> Video</span></Link>
              </li>
              <li id="more-veiw" hidden><Link to="/Games">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-red-500">
                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                </svg>  <span>  Games </span></Link>
              </li>
              <li><Link to="/Viewpostpage">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-blue-500">
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg><span> View Post </span></Link>
              </li>
              <li>
                {/*      <Link to="/Course"> */}
                <Link to="/Createpost">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-green-500">
                    <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
                  </svg>
                  <span> Write Something </span></Link>
              </li>
              <li>
                {/*      <Link to="/Course"> */}
                <Link to="/" onClick={this.flogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                  </svg>
                  <span> Log Out </span></Link>
              </li>
              {/*               <li><Link to="/Jobs">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-pink-500">
                  <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
                  <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                </svg> <span> Jobs</span> </Link>
              </li> */}
              {/*    <li><a href="blogs.html">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-red-500">
                  <path fillRule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clipRule="evenodd" />
                  <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                </svg>
                <span> Blog</span></a>
              </li>
              <li id="more-veiw" hidden><a href="products.html">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-green-500">
                  <path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
                </svg>
                <span> Products</span></a>
              </li>
              <li id="more-veiw" hidden><a href="events.html">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-yellow-500">
                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg><span>  Events </span></a>
              </li> */}


              {/*               <li id="more-veiw" hidden><a href="forums.html">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-blue-500">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                  <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                </svg>
                <span> forum</span> </a>
              </li> */}
            </ul>
            <a href="#" className="see-mover h-10 flex my-1 pl-2 rounded-xl text-gray-600" uk-toggle="target: #more-veiw; animation: uk-animation-fade">
              <span className="w-full flex items-center" id="more-veiw">
                <svg className="  bg-gray-100 mr-2 p-0.5 rounded-full text-lg w-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                See More
              </span>
              <span className="w-full flex items-center" id="more-veiw" hidden>
                <svg className="bg-gray-100 mr-2 p-0.5 rounded-full text-lg w-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" /></svg>
                See Less
              </span>
            </a>
            <hr />
            <h3 className="text-lg mt-3 font-semibold ml-2 is-title"> {/* Your Match */} Message </h3>
            <div className="contact-list my-2 ml-1">
              {/*               {this.state.datayourmatch.map((result) => {
                return (
                  <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
                    <div className="contact-avatar">
                      {
                        result.com_logo == null ?

                          <img src="assets/images/avatars/avatar-80.jpg" alt />
                          :
                          <img src={`${base.IMAGE_URL}/${result.com_logo}`} />
                      }

                      <span className="user_status status_online" />
                    </div>
                    <div className="contact-username"> {result.firstname} {result.lastname}</div>
                  </Link>
                )
              })} */}
              <Link to="/Inbox"> Inbox <span
                style={{
                  width: 20, height: 20, backgroundColor: '#ec5252', borderRadius: 25, fontSize: 10
                  , color: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 4
                }}>
                {this.state.allcurrentuserinbox.length} </span>
              </Link>
              <Link to="/Profilevisitor"> Profile visitor
                <span
                  style={{
                    width: 20, height: 20, backgroundColor: '#ec5252', borderRadius: 25, fontSize: 10
                    , color: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 4
                  }}>
                  {this.state.myprofilevisitorview.length} </span>

              </Link>
            </div>
            <hr />
            <h3 className="text-lg mt-3 font-semibold ml-2 is-title"> Find people </h3>
            <ul className="-space-y-1.5">
              <li><Link to="/findpeople"> Find More People </Link> </li>

            </ul>
            <hr />
            <div className="footer-links">
              <Link to="/Aboutus">About</Link>
              <Link to="/Blog">Blog </Link>
              <Link to="/Privatepolicy">Private policy</Link>
              <Link to="/Support">Support</Link>
              <Link to="/Contactus">Contact Us </Link>
              <Link to="/Aboutus">Terms of service</Link>
            </div>
          </div>
        </div>
        {/* this is for header chat icon */}
        <div uk-toggle="target: #offcanvas-chat" className="start-chat">
          <svg className="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
          </svg>
        </div>

        <div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true" style={{ width: '80%' }}>
          <div className="uk-offcanvas-bar bg-white p-0 w-full lg:w-80 shadow-2xl">
            <div className="relative pt-5 px-4">
              <div style={{ flex: 1, display: 'flex', alignContent: 'space-between' }}>
                <h3 className="text-2xl font-bold"> Chats </h3>
                <h3 className="text-2xl font-bold mb-1" onClick={this.closefriend} style={{ marginLeft: 100 }}> Close</h3>
              </div>

              <div className="absolute right-3 top-4 flex items-center">
                <button className="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                  type="button" uk-close />
                <a href="#" uk-toggle="target: #search;animation: uk-animation-slide-top-small">
                  <ion-icon name="search" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                </a>
                <a href="#">
                  <ion-icon name="settings-outline" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                </a>
                <a href="#">
                  <ion-icon name="ellipsis-vertical" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                </a>
                <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small; offset:5">
                  <ul className="space-y-1">
                    <li>
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                        <ion-icon name="checkbox-outline" className="pr-2 text-xl" /> Mark all as read
                      </a>
                    </li>
                    <li>
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                        <ion-icon name="settings-outline" className="pr-2 text-xl" />  Chat setting
                      </a>
                    </li>
                    <li>
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                        <ion-icon name="notifications-off-outline" className="pr-2 text-lg" />   Disable notifications
                      </a>
                    </li>
                    <li>
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                        <ion-icon name="star-outline" className="pr-2 text-xl" />  Create a group chat
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="absolute bg-white z-10 w-full -mt-5 lg:-mt-2 transform translate-y-1.5 py-2 border-b items-center flex" id="search" hidden>
              <input type="text" placeholder="Search.." className="flex-1" />
              <ion-icon name="close-outline" className="text-2xl hover:bg-gray-100 p-1 rounded-full mr-4 cursor-pointer" uk-toggle="target: #search;animation: uk-animation-slide-top-small" />
            </div>
            <nav className="responsive-nav border-b extanded mb-2 -mt-2">
              <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
                <li className="uk-active"><a className="active" href="#0"> Chat</a></li>
               {/*  <li><a href="#0">Groups <span> 0 </span> </a></li> */}
              </ul>
            </nav>
            <div className="contact-list px-2 uk-switcher" id="chats-tab">
              <div className="p-1">
                {/* Chat popup here */}
                   <Chatlist />
              </div>
              <div className="p-1">

              </div>
            </div>
          </div>
        </div>


        {/* End this is for header chat icon */}

        {this.state.mobilemenuopen == 'on' ?
          <Popup
            content={<>
              <b>Menu</b>
              <div className="menu">
                <ul>
                <li><a href="/profile">Profile</a></li>
                <li><a href="/Upgrade">Upgrade Member</a></li>
                  <li><a href="/findpeople">Find People</a></li>
                  <li><a href="/Inbox">Inbox</a></li>
                  <li><a href="/Profilevisitor">Profile Visitor</a></li>

                  <li><a href="/Createpost">Create Post</a></li>
                  <li><a href="/Viewpostpage">View Post</a></li>
                  <li><a href="/changepassword">Change Password</a></li>
                  <li><a href="/changepassword">How to use</a></li>
                  
                  

                </ul>
              </div>
             {/*  <button>Test button</button> */}
            </>}
            handleClose={this.togglePopup.bind(this)}
          />
          :
          ''
        }

      </ div>
    )
  }
}

export default Header