import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'reactstrap';

export default class comments extends Component {

  constructor(props) {
    super(props);
    this.createpostsubmit = this.createpostsubmit.bind(this);
    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      commentslist: [],
      show: false,
      email: localStorage.getItem("emailaddress"),
      fields: {},
      errors: {},
      autoid: this.props.match.params.id
    }


  }
  componentDidMount() {
    // alert(this.state.email);
    axios.get(base.BASE_URL + '/viewcomments/' + this.state.autoid).then(res => {
      this.setState({ commentslist: res.data });
    });

  }
  handleModal() {
    this.setState({ show: !this.state.show })
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //alert(this.state.fields["email"]);
    //Name
    if (!this.state.fields["notes"]) {
      formIsValid = false;
      errors["notes"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  createpostsubmit() {
    if (this.handleValidation()) {
      // alert("Form submitted");
      //////////////////////////////////////////////////////////////////////
      fetch(base.BASE_URL + '/insertcomments', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          notes: this.state.fields["notes"],
          email: this.state.email,
          autoid: this.state.autoid
        })
      }).then((Response) => Response.json())
        .then((Result) => {
          if (Result.message == 'success') {
            //  alert('Post has been submited successfully');
            this.setState({ loggdin: true, commentslist: [] })
            axios.get(base.BASE_URL + '/viewcomments/' + this.state.autoid).then(res => {
              this.setState({ commentslist: res.data });
            });




          }
          else {
            alert('Failed to post')
          }

        })

      /////////////////////////////////////////////////////////////////////
    } else {
      // alert("Form has errors.")
    }


  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }

    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            <div style={{ flex: 1, flexDirection: 'row' }}>
              <Link to="/Dashboard" style={{ fontSize: 18 }}><h3>Back</h3></Link>
              <h3><b>View Comments</b></h3>
            </div>

            <Form style={{ marginTop: 5 }} >
              <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                rows={5} placeholder=" write your own comments " style={{ fontSize: 13, padding: 5 }}
                onChange={this.handleChange.bind(this, "notes")} value={this.state.fields["notes"]}
              />
              <Button type="button" style={{ marginTop: 5 }} className="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold"
                onClick={this.createpostsubmit}>
                POST </Button>
            </Form>
            <div style={{ padding: 15 }}>
              {this.state.commentslist.map((result) => {
                return (
                  <p style={{ borderBottomWidth: 1, padding: 5 }}> {result.names} : {result.xcomments}</p>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
