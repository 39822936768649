import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';


class Chatlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem("emailaddress"),
            chatfriendlist:[],
            loading: false,
        };
    }
    componentDidMount() {
        if (this.state.email == null) {

        }
        else {
           // alert(this.state.email)
           console.log(base.BASE_URL + '/chatconversitonid/' + this.state.email)
            axios.get(base.BASE_URL + '/chatconversitonid/' + this.state.email).then(res => {
                this.setState({ chatfriendlist: res.data });
               // console.log('Console Log...' + res.data)
            });
        }
    }
    render() {
        return (
            <div>
                {/* here is the contact list friends */}
                <h3 className="text-xl font-semibold" style={{ marginBottom: 20 }}> Favourites List {this.state.chatfriendlist.length}</h3>
                {this.state.chatfriendlist.map((result, index) => {
                    return (
                        <div key={index}> <Link to={`/chat/${result.id}`}>
                            <div className="contact-avatar">
                                {
                                    result.com_logo == null ?
                                        <img src="assets/images/avatars/avatar-80.jpg" alt />
                                        :
                                       <img src={`${base.IMAGE_URL}/${result.com_logo}`} 
                                        style={{ width: 50, height: 50, borderRadius: '50%' }} />
                                }
                                <span className="user_status status_online" />
                            </div>
                             <div className="contact-username"> {result.firstname}   {result.lastname}</div> 
                        </Link>
                            <div uk-drop="pos: left-center ;animation: uk-animation-slide-left-small">
                                <div className="contact-list-box">
                                    <div className="contact-avatar">
                                        {
                                            result.com_logo == null ?
                                                <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
                                                     <img src="assets/images/avatars/avatar-80.jpg" alt  style={{ width: 50, height: 50 }}/></Link>
                                                :
                                                <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
                                                    <img src={`${base.IMAGE_URL}/${result.com_logo}`}
                                                     style={{ width: 50, height: 50 }} /></Link>
                                        }
                                        <span className="user_status status_online" />
                                    </div>
                                    <div className="contact-username"> {result.firstname}   {result.lastname}</div>
                                    <p>
                                        <ion-icon name="people" className="text-lg mr-1" /> Become friends
                                    </p>
                                    <div className="contact-list-box-btns">
                                        <Link to={`/chat/${result.id}`} className="button primary flex-1 block mr-2">
                                            <i className="uil-envelope mr-1" /> Send message</Link>

                                    </div>
                                </div>
                            </div></div>
                    )
                })}
                {
                    this.state.chatfriendlist.length == '0'
                        ?
                        <Link to="/Findpeople">No More Friend Yet Find New Friend</Link>
                        :
                        ''

                }
            </div>
        );
    }
}

export default Chatlist;
