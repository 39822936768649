import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Button } from 'reactstrap';
import ReactCrop from 'react-image-crop' // crop image
import 'react-image-crop/dist/ReactCrop.css'// crop image css
import { render } from "react-dom";
import "react-responsive-modal/styles.css";
import axios from 'axios';
import * as base from "./global";


const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};


const Uploadpage =() => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("emailaddress"));

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 150,
    height: 150
  })
  const [imagedetails, setImagedetails] = useState('')
	const [croppedImageUrl, setCroppedImageUrl] = useState("");

 
const handlefilechange = event => {
    const files = event.target.files
    if (files && files.length > 0){
             const currentFile = files[0]
             const myFileItemReader = new FileReader()
             myFileItemReader.addEventListener("load", ()=>{
                 const myResult = myFileItemReader.result
                 setImage(myResult)
             }, false)

             myFileItemReader.readAsDataURL(currentFile)
    }
}

const saveimage= async () => {
  const canvas = document.createElement("canvas");
  canvas.width = imagedetails.naturalWidth;
      canvas.height = crop.naturalHeight;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width /2, canvas.height / 2)
      ctx.drawImage(
        imagedetails,
        -canvas.width / 2,
        -canvas.height / 2,
        canvas.width,
        canvas.height
      )
      //download image
    //  const link = document.createElement('a')
     // link.download = "p.jpge";
    //  link.href = canvas.toDataURL();
    //  link.click();
    //end download
      //image upload into the server
      var data = canvas.toDataURL();
      var image = new Image();
      image.setAttribute("crossorigin", "anonymous");
      image.src = data;
      const blob = await (await fetch(image.src)).blob();
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobURL;
      console.log('Link....' + link.download);
      const file = new File([blob], 'fileName.jpg', { type: "image/jpeg", lastModified: new Date() });
      const fd = new FormData();
      fd.append('image', file);
      fd.append('email', email);
      axios.post(base.BASE_URL + '/profileimageupload', fd
      ).then(res => {
         // alert(res.message);
          if (res.status == '200')  
          {
          
            this.setState({ loggdin : true })
          }
          else
          {
            alert('Failed To Upload')  
          }  
          console.log(res);
      }
      );

}
   const getCroppedImg = () => {
      const canvas = document.createElement("canvas");
      const scaleX = imagedetails.naturalWidth / imagedetails.width;
      const scaleY = imagedetails.naturalHeight / imagedetails.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
      imagedetails,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
      );
      const base64Url = canvas.toDataURL('image/jpg')
      setImage(base64Url)

    /*
		try {
			return new Promise((resolve) => {
				canvas.toBlob((file) => {
					resolve(URL.createObjectURL(file));
				}, "image/jpeg");
			});
		} catch (error) {
			console.log(error);
			return null;
		} */
	
  };

  const handlecropchange=(crop) => {
    setCrop(crop)
  }
console.log('monir' + imagedetails)
  return (
    
    <div style={styles}>
      <Button onClick={onOpenModal}>Open modal</Button>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>Image Upload</h2>
        <input type="file" accept='image/*' onChange={handlefilechange} />
          <p>
        {
          image == null ?
          ''
          :
          <div>
             <ReactCrop crop={crop} onChange={c => setCrop(c)}>
              <img onLoad={(e) => setImagedetails(e.currentTarget)}  src={image} />
            </ReactCrop>
          </div>
        }

        </p>
          {
            image && <div style={{ display: 'flex' }}><Button onClick={getCroppedImg}
            className="bg-green-600 hover:bg-green-500 hover:text-white font-semibold py-3 px-5 rounded-md text-center text-white mx-auto">
              Crop Image 
            </Button> 
            <Button onClick={saveimage} 
            className="bg-green-600 hover:bg-green-500 hover:text-white font-semibold py-3 px-5 rounded-md text-center text-white mx-auto">
              Save 
            </Button></div>
          }
           </Modal>
    </div>
    
  );
}

export default Uploadpage;