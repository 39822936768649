import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'reactstrap';

export default class Likes extends Component {

  constructor(props) {
    super(props);

    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      commentslist: [],
      show: false,
      email: localStorage.getItem("emailaddress"),
      fields: {},
      errors: {},
      autoid: this.props.match.params.id
    }


  }
  componentDidMount() {
    // alert(this.state.email);
    axios.get(base.BASE_URL + '/viewlikes/' + this.state.autoid).then(res => {
      this.setState({ commentslist: res.data });
    });

//////////////////////////////////////////////////////////////////////////////////

//let likep = countlike + this.state.xlike;
fetch(base.BASE_URL + '/insertlikes', {
  method: 'post',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    email: this.state.email,
    autoid: this.state.autoid
  })
}).then((Response) => Response.json())
  .then((Result) => {
    if (Result.message == 'success') {
      this.setState({ likeshow: true });

    }
    else {
      //  alert('Failed to insert like')
    }

  })

//////////////////////////////////////////////////////////////////////////////////
  }
  handleModal() {
    this.setState({ show: !this.state.show })
  }


  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }

    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
            <div style={{ flex: 1, flexDirection: 'row' }}>
              <Link to="/Dashboard" style={{ fontSize: 18 }}><h3>Back</h3></Link>
              <h3><b>View Likes</b></h3>
            </div>

            <div style={{ padding: 15 }}>
              {this.state.commentslist.map((result) => {
                return (
                  <Link to="/Dashboard" style={{ borderBottomWidth: 1, padding: 5 }}> {result.firstname} {result.lastname} 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" 
                  width="22" height="22" class="dark:text-gray-100">
                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"></path></svg>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
