import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Dashboardpost from "./Dashboardpost";
import InfiniteScroll from 'react-infinite-scroll-component';

import Dashboardfavouritelist from './Dashboardfavouritelist'
import Vipdata from './Vipdata'
import Dashgeneralmember from './Dashgeneralmember'

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.resendemail = this.resendemail.bind(this);

    // this.changecomment = this.changecomment.bind(this);
    // this.changelike = this.changelike.bind(this);
    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      datafiveuser: [],
      show: false,
      count: '0',
      email: localStorage.getItem("emailaddress"),
      photos: [],
      loading: false,

      page: 1,

      prevY: 0,
      autoid: 0,
      xlike: 1,
      xcomments: 0,
      likeshow: false,
      checkuserverify: ''
    }


  }
  componentDidMount() {
    if (this.state.email == null) {
      this.state = {

      }
    }
    else {
      axios.get(base.BASE_URL + '/viewprofile/' + this.state.email).then(res => {
        this.setState({ checkuserverify: res.data[0].status });
      });

    }
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datafiveuser: res.data });
    });

  }
  //changelike = ev => {
  changelike(autoid, countlike, xcommentscount) {
    //   alert(autoid); user.countlike, user.xcommentscount
    let likep = countlike + this.state.xlike;
    fetch(base.BASE_URL + '/insertlikes', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        autoid: autoid
      })
    }).then((Response) => Response.json())
      .then((Result) => {
        if (Result.message == 'success') {
          //  alert('LIke has been submited successfully');
          this.setState({ xlike: likep, likeshow: true });
          // this.getPhotos(this.state.page);
        }
        else {
          //  alert('Failed to insert like')
        }

      })

  }



  // //start post pagination with scroll
  routeChange() {
    this.setState({ count: '1' });
  }
  resendemail() {
    //////////////////////////////////////////////////////////////////

    fetch(base.BASE_URL + '/resendemail', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email
      })
    }).then((Response) => Response.json())
      .then((Result) => {
        if (Result.message == 'success') {
          alert('Check Your email and verify your email, check the spam or inbox');

        }
        else {
          alert('Failed to send Request')
        }

      })


    /////////////////////////////////////////////////////////////////
  }

  /*   EditData(result) {
      alert(result.email);
      this.setState({ name: result.name, address: result.address, contact: result.contact, 
        email: result.email, id: result._id, Buttontxt: 'Update' });
    }
   */
  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }
    if (this.state.count == '1') {
      return <Redirect to="/Createpost" />
    }




    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          {
            this.state.checkuserverify == 'notverify'
              ?
              <p style={{
                backgroundColor: '#fff1d9', color: '#000', padding: 10
              }}>Check Your Inbox ( {localStorage.getItem("emailaddress")} ) and verify your email address
                <Link to="#" onClick={this.resendemail} 
                style={{  width: 100, height: 50, 
                marginLeft: 10, color: '#ff7941' }}>Not Receive mail ?</Link>
              </p>
              :
              ''
          }
          <div className="mcontainer">
            {/*  Feeds  */}
            <div className="lg:flex lg:space-x-10">
              <div className="lg:w-3/4 lg:px-20 space-y-7">
                <div className="user_story grid md:grid-cols-5 grid-cols-3 gap-2 lg:-mx-20 relative">
                  {this.state.datafiveuser.map((result) => {
                    return (
                      <a href="#create-post" uk-toggle="target: body ; cls: story-active">
                        <div className="single_story">
                          <img src={`${base.IMAGE_URL}/${result.com_logo}`}

                          />
                          <div className="story-avatar">

                            <img src={`${base.IMAGE_URL}/${result.com_logo}`}
                              style={{
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              }}
                            />


                          </div>
                          <div className="story-content"> <h4> {result.firstname}</h4> </div>
                        </div>
                      </a>
                    )
                  })}

                  <span className="absolute bg-white lg:flex items-center justify-center p-2 rounded-full 
                    shadow-md text-xl w-9 z-10 uk-position-center-right -mr-4 hidden" uk-toggle="target: body ; cls: story-active">
                    <i className="icon-feather-chevron-right" /></span>
                </div>

                <div className="card lg:mx-0 p-4"
                  uk-toggle="target: #create-post-modal"
                  style={{ backgroundColor: 'silver', alignContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                  <Link to="/findpeople" style={{ marginRight: 20 }}>  View Member </Link>
                  <Link to="/Inbox" style={{ marginRight: 20 }}> Inbox </Link>
                  <Link to="/Profilevisitor" style={{ marginRight: 20 }}>  Profile Visitor</Link>
                  <Link to="/Createpost" style={{ marginRight: 20 }}>  Article </Link>
                </div>

                {/* data list of VIP  */}
                <Vipdata />

                {/* data list of VIP */}



                {/* data list Near To You  */}
               {/*  <Vipdata /> */}

                {/* data list Near To You */}


                <div className="card lg:mx-0 p-4" uk-toggle="target: #create-post-modal">
                  <div className="flex space-x-3">
                    {/* <img src="assets/images/avatars/avatar-2.jpg" className="w-10 h-10 rounded-full" /> */}
                    <input placeholder="What's Your Mind ? Hamse!"
                      className="bg-gray-100 hover:bg-gray-200 flex-1 h-10 px-6 rounded-full"
                      onClick={this.routeChange}
                    /*  onClick={() => this.handleModal()} */
                    />
                  </div>
                  <div className="grid grid-flow-col pt-3 -mx-1 -mb-1 font-semibold text-sm">
                    <Link to="/Photopost" className="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer">
                      <svg className="bg-blue-100 h-9 mr-2 p-1.5 rounded-full text-blue-600 w-9 -my-0.5 hidden lg:block"
                        data-tippy-placement="top" title="Tooltip" fill="none" stroke="currentColor"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                      Photo/Video
                    </Link>
                    <div className="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer" onClick={this.routeChange}>
                      <svg className="bg-green-100 h-9 mr-2 p-1.5 rounded-full text-green-600 w-9 -my-0.5 hidden lg:block" uk-tooltip="title: Messages ; pos: bottom ;offset:7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" title aria-expanded="false"> <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" /></svg>
                      Tag Friend
                    </div>
                    <div className="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer" onClick={this.routeChange}>
                      <svg className="bg-red-100 h-9 mr-2 p-1.5 rounded-full text-red-600 w-9 -my-0.5 hidden lg:block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                      Fealing /Activity
                    </div>
                  </div>
                </div>

                {/*  <div className="card lg:mx-0 p-4" uk-toggle="target: #create-post-modal"> */}
            {/*     <Dashboardpost /> */}
                {/*  </div> */}



              <Dashgeneralmember />

                <div className="flex justify-center mt-6">
                  <Link to="/findpeople" className="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                    Search More Profile ..</Link>
                </div>
              </div>
              <div className="lg:w-72 w-full">
                <h3 className="text-xl font-semibold mb-2"> Build Website Design and Development </h3>
                <a href="https://webshopedia.dk/" className="uk-link-reset mb-2">
                  <div className="flex py-2 pl-2 mb-2 rounded-md hover:bg-gray-200">
                    {/*  <img src="assets/images/icons/gift-icon.png" className="w-9 h-9 mr-3" alt /> */}

                    <p className="line-clamp-2">
                      <strong>    $ 500  ( 50 Percent Off)</strong>
                      <strong>   Webshopedia.dk</strong>
                    </p>

                  </div>
                </a>

                <Dashboardfavouritelist />

                {/*                 <div className uk-sticky="offset:80">
                  <nav className="responsive-nav border-b extanded mb-2 -mt-2">
                    <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                      <li className="uk-active"><a className="active" href="#0">  Favourites List  <span>  </span> </a></li>
                     <li><a href="#0">Groups</a></li> 
                    </ul>
                  </nav>
                  <div>
                 
                  </div>
                </div> */}



              </div>
            </div>
          </div>
        </div>




        {/* 

*/}

        <div>
          {/* open chat box */}
          <div uk-toggle="target: #offcanvas-chat" className="start-chat">
            <svg className="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
            </svg>
          </div>
          <div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true" style={{ width: '80%' }}>
            <div className="uk-offcanvas-bar bg-white p-0 w-full lg:w-80 shadow-2xl">
              <div className="relative pt-5 px-4">
                <div style={{ flexDirection: 'row' }}>
                  <h3 className="text-2xl font-bold" style={{ width: '50%', flexDirection: 'row' }}> Chats here</h3>
                  <h3 className="text-2xl font-bold mb-1" style={{ width: '48%', flexDirection: 'row', color: 'red' }} onClick={this.closefriend}> Close</h3>
                </div>

                <div className="absolute right-3 top-4 flex items-center">
                  <button className="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                    type="button" uk-close />
                  <a href="#" uk-toggle="target: #search;animation: uk-animation-slide-top-small">
                    <ion-icon name="search" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                  </a>
                  <a href="#">
                    <ion-icon name="settings-outline" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                  </a>
                  <a href="#">
                    <ion-icon name="ellipsis-vertical" className="text-xl hover:bg-gray-100 p-1 rounded-full" />
                  </a>
                  <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small; offset:5">
                    <ul className="space-y-1">
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="checkbox-outline" className="pr-2 text-xl" /> Mark all as read
                        </a>
                      </li>
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="settings-outline" className="pr-2 text-xl" />  Chat setting
                        </a>
                      </li>
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="notifications-off-outline" className="pr-2 text-lg" />   Disable notifications
                        </a>
                      </li>
                      <li>
                        <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                          <ion-icon name="star-outline" className="pr-2 text-xl" />  Create a group chat
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="absolute bg-white z-10 w-full -mt-5 lg:-mt-2 transform translate-y-1.5 py-2 border-b items-center flex" id="search" hidden>
                <input type="text" placeholder="Search.." className="flex-1" />
                <ion-icon name="close-outline"
                  className="text-2xl hover:bg-gray-100 p-1 rounded-full mr-4 cursor-pointer" uk-toggle="target: #search;animation: uk-animation-slide-top-small" />
              </div>
              <nav className="responsive-nav border-b extanded mb-2 -mt-2">
                <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
                  <li className="uk-active">
                    <a className="active" href="#0">
                      Favourites List </a></li>
                  {/*  <li><a href="#0">Groups <span> 0 </span> </a></li> */}
                </ul>
              </nav>
              <div className="contact-list px-2 uk-switcher" id="chats-tab">
                <div className="p-1">
                  <Dashboardfavouritelist />

                </div>
                <div className="p-1">

                </div>
              </div>
            </div>
          </div>
        </div>


        {/* 

*/}
        {/* story-preview */}
        <div className="story-prev">
          <div className="story-sidebar uk-animation-slide-left-medium">
            <div className="md:flex justify-between items-center py-2 hidden">
              <h3 className="text-2xl font-semibold"> All Story </h3>
              <a href="#" className="text-blue-600"> Setting</a>
            </div>
            <div className="story-sidebar-scrollbar" data-simplebar>
              <h3 className="text-lg font-medium"> Your Story </h3>
              <Link className="flex space-x-4 items-center hover:bg-gray-100 md:my-2 py-2 rounded-lg hover:text-gray-700" to="/Createpost">
                <svg className="w-12 h-12 p-3 bg-gray-200 rounded-full text-blue-500 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                <div className="flex-1">
                  <div className="text-lg font-semibold"> Create a story </div>
                  <div className="text-sm -mt-0.5"> Share a photo or write something. </div>
                </div>
              </Link>
              <h3 className="text-lg font-medium lg:mt-3 mt-1"> Friends Story </h3>
              <div className="story-users-list" uk-switcher="connect: #story_slider ; toggle: > * ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium ">
                {this.state.datafiveuser.map((result) => {
                  return (
                    <a href="#">
                      <div className="story-media">
                        <img src={`${base.IMAGE_URL}/${result.com_logo}`} />

                      </div>
                      <div className="story-text">
                        <div className="story-username">{result.firstname} {result.lastname}</div>
                        {/*  <p> <span className="story-count"> 2 new </span> <span className="story-time"> 4Mn ago</span> </p> */}
                      </div>
                    </a>
                  )
                })}

              </div>
            </div>
          </div>
          <div className="story-content">
            <ul className="uk-switcher uk-animation-scale-up" id="story_slider">
              {this.state.datafiveuser.map((result) => {
                return (
                  <li className="relative">
                    <span uk-switcher-item="previous" className="slider-icon is-left"> </span>
                    <span uk-switcher-item="next" className="slider-icon is-right"> </span>
                    <div uk-lightbox>
                      <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`} data-alt="Image">
                        {
                          result.com_logo == null ?
                            <img src="assets/images/avatars/avatar-80.jpg" alt style={{ width: '100%', height: 250 }} />
                            :
                            <img src={`${base.IMAGE_URL}/${result.com_logo}`} className="story-slider-image" data-alt={result.firstname} style={{ width: '100%', height: '100%' }} />
                          /*  <img src={`assets/images/profile/${result.com_logo}`} className="story-slider-image" data-alt={result.firstname} style={{ width: '100%', height: 250 }} /> */
                        }
                      </Link>
                      <h3>{result.firstname}</h3>
                    </div>
                  </li>
                )
              })}
              {/*               <li className="relative">
                <span uk-switcher-item="previous" className="slider-icon is-left"> </span>
                <span uk-switcher-item="next" className="slider-icon is-right"> </span>
                <div uk-lightbox>
                  <a href="assets/images/avatars/avatar-lg-1.jpg" data-alt="Image">
                    <img src="assets/images/avatars/avatar-lg-1.jpg" className="story-slider-image" data-alt="Image" />
                  </a>
                </div>
              </li>
              <li className="relative">
                <span uk-switcher-item="previous" className="slider-icon is-left"> </span>
                <span uk-switcher-item="next" className="slider-icon is-right"> </span>
                <div uk-lightbox>
                  <a href="assets/images/avatars/avatar-lg-4.jpg" data-alt="Image">
                    <img src="assets/images/avatars/avatar-lg-4.jpg" className="story-slider-image" data-alt="Image" />
                  </a>
                </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li>
              <li className="relative">
                <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse"> </div>
              </li> */}

            </ul>
          </div>
          {/* story colose button*/}
          <span className="story-btn-close" uk-toggle="target: body ; cls: story-active" uk-tooltip="title:Close story ; pos: left">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </span>
        </div>

      </div>
    );
  }
}
