import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import Header from '../Layout/Header'
//import Router from 'next/router';
import * as base from "./global";
import Iframe from '@trendmicro/react-iframe'; //npm install --save react @trendmicro/react-iframe
import axios from 'axios';

//stripe api
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
// const { Option } = Select;
const promise = loadStripe("pk_test_51Imd3KJdRrXqreQhttm6S8O7tFE5Mn3h0YCRYClNXgWx7amyebYrx7qVlIkAaHKfkqvQt150xKqrBdsGGJA0sEhx0078PnUDi3");



export default class payment extends Component {

  constructor(props) {
    super(props);
    this.handlecardholdername = this.handlecardholdername.bind(this);
    this.handlecountry = this.handlecountry.bind(this);
    this.handlepostalCode = this.handlepostalCode.bind(this);
    this.handlecardno = this.handlecardno.bind(this);
    this.handlecardexpairdate = this.handlecardexpairdate.bind(this);
    this.handlecardexpairdmonth = this.handlecardexpairdmonth.bind(this);
    this.handlecardcardcvc = this.handlecardcardcvc.bind(this);
    const token = localStorage.getItem("token")
    let loggdin = true;
    if (token == null) {
      loggdin = false
    }
    this.state = {
      loggdin,
      datafiveuser: [],
      show: false,
      email: localStorage.getItem("emailaddress"),
      method: 1,
      cname: '',
      cfirstname: '',
      clastname: '',
      caddress: '',
      capartment: '',
      ccity: '',
      isLoading: false,
      cardNumber: '',
      cardcvc: '',
      cardExpMonth: '',
      cardexpairdate: '',
      cardholdername: '',
      country: '',
      postalCode: '',
      cardExpYear: '',
      packageid: localStorage.getItem("packageprice")
    //  packageid: props.match.params.id
     // localStorage.getItem("emailaddress")
    }


  }
  componentDidMount() {
    // alert(this.state.email);
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datafiveuser: res.data });
    });

  }
  handleModal() {
    this.setState({ show: !this.state.show })
  }
  handlecardholdername(event) {
    this.setState({
      cardholdername: event.target.value
    })
  }
  handlecountry(event) {
    this.setState({
      country: event.target.value
    })
  }
  handlepostalCode(event) {
    this.setState({
      postalCode: event.target.value
    })
  }
  handlecardexpairdate(event) {
    this.setState({
      cardExpMonth: event.target.value
    })
  }
  handlecardexpairdmonth(event) {
    this.setState({
      cardExpYear: event.target.value //cardExpMonth
    })
  }
  handlecardcardcvc(event) {
    this.setState({
      cardCVC: event.target.value
    })
  }
  handlecardno(event) {
    this.setState({
      cardNumber: event.target.value
    })
  }
 // handlestripecard = () => {
    onSubmit(e) {
      e.preventDefault()
  

  //  alert('dddd');
    this.setState({ isLoading: true });

    const { amount, cartItems } = this.props;
    // alert(amount);                                       
    const totalamount = 5;
   // localStorage.setItem("totalamount", totalamount)
    // Router.push('/account/paypalpayment');
    //  alert(this.state.cname);
    ///////////////////////////////////////////////////////////////////   
    axios.post(base.payment_stripe + '/insertpayment.php', {
      email: this.state.email,
      amount: totalamount,
      cardNumber: this.state.cardNumber,
      cardExpMonth: this.state.cardExpMonth,
      cardExpYear: this.state.cardExpYear,
      cardCVC: this.state.cardCVC,
      country: this.state.country,
      postalCode: this.state.postalCode
    })
      .then((response) => {

        console.log(response);
        console.log('test ' + response.data.Success.paid);
        console.log('test ' + response.data.Success.id);

        //////////////insert payment_stripe
/*         axios.post(base.BASE_URL + '/xpaymentpaypal', {
          email: this.state.cname,
          orderid: this.state.orderid,
          paymentid: response.data.Success.id,
          price: localStorage.getItem('totalamount'),
          receivestatus: 'Paid',
          paymenttype: 'Visa Card'
        })
          .then((response) => {
            alert('Payment Receive Successfully');
           // this.props.dispatch(removeItem());
          }, (error) => {
            console.log(error);
          }); */

        //localStorage.removeItem('persist:martfury')
        //Router.push('/account/paymentsuccess');


        //////////////end 

      }, (error) => {
        console.log(error);
        alert(error);
      });


    /////////////////////////////////////////////////////////////////// 
  }
  render() {

    if (this.state.email == null) {
      return <Redirect to="/" />
    }
  //  alert(this.state.packageid)
    return ( 
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">


          <Iframe src={base.payment_stripe + '/index.php?custid=' + this.state.email + '&pacid=' + this.state.packageid } style={{ width: '100%', height: 500 }} />

{/*             <form onSubmit={this.handlestripecard}  style={{ padding: 20 }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td colSpan="3" style={{ height: 50 }}>
                    <h3><b>Payment Accept form VISA/MASTER</b></h3>

                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Card Holder Name
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.cardholdername}
                      onChange={this.handlecardholdername} required
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan="3">Country
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.country}
                      onChange={this.handlecountry} required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Postal Code
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.postalCode}
                      onChange={this.handlepostalCode} required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Card Number
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.cardNumber}
                      onChange={this.handlecardno} required
                    />
                  </td>
                </tr>

                <tr>
                  <td>Expiration Month
                  </td>
                  <td>Expiration Year
                  </td>
                  <td>CCV
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>
                    <input
                      type="text"
                      placeholder="Expair Month"
                      className="form-control"
                      value={this.state.cardExpMonth}
                      onChange={this.handlecardexpairdate} required
                    />
                  </td>
                  <td style={{ width: 100 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Expair Year"
                      value={this.state.cardExpYear}
                      onChange={this.handlecardexpairdmonth} required
                    />
                  </td>
                  <td style={{ width: 100 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="CVC"
                      value={this.state.cardCVC}
                      onChange={this.handlecardcardcvc} required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <button className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">Conifrm Payment</button>
                  </td>
                </tr>
              </table>

            </form> */}

          </div>
        </div>
      </div>
    );
  }
}
