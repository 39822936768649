import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'reactstrap';

export default class Sendemail extends Component {

  constructor(props) {
    super(props);
    this.createpostsubmit = this.createpostsubmit.bind(this);

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();


  //alert(date);
    this.state = {
      datafiveuser: [],
      currentuserdata: [],
      show: false,
      com_logo: '',
      id: '',
      fields: {},
      errors: {},
      email: localStorage.getItem("emailaddress"),
      toemailid: this.props.match.params.id,
      memberexpairdate:'',
      insertmessage:'',
      currentdate : `${year}-${month<10?`0${month}`:`${month}`}-${date}`,
      statuspayment:'expair'
    //  curDT : new Date().toLocaleString()
     // currentdate : `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`
    }


  }
  componentDidMount() {
    // alert(this.state.email);
    this.getuserdata();
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datafiveuser: res.data });
    });
    this._loadname();

  }
  handleModal() {
    this.setState({ show: !this.state.show })
  }
  getuserdata() {

    axios.get(base.BASE_URL + '/viewprofile/' + this.state.email).then(res => {
      this.setState({ currentuserdata: res.data });
    });
  }

  _loadname = async () => {
    let meetupId = this.state.email;
    try {
      let response = await fetch(base.BASE_URL + '/viewprofile/' + meetupId);
      // let response = await fetch(base.BASE_URL + '/profile.php?ID=' + username);
      console.log(response);
      let res = await response.text();
      if (response.status >= 200 && response.status < 300) {
        let userData = JSON.parse(res);
         // alert(userData[0]["memberexpairdate"]);  //studentimage
          let expairdate = userData[0]["memberexpairdate"];
          if(expairdate == null)
          {
          //  alert('expair');
            this.setState({
              'statuspayment': 'expair'
            });
          }
          else if(expairdate <= this.state.currentdate)
          {
            this.setState({
              'statuspayment': 'expair'
            });
          }
          else
          {
            this.setState({
              'statuspayment': 'paid'
            });
          }
        this.setState({
          'firstname': userData[0]["firstname"],
          'lastname': userData[0]["lastname"],
          'com_logo': userData[0]["com_logo"],
          'memberexpairdate': userData[0]["memberexpairdate"],
          'id': userData[0]["id"]
        });


      } else {
        let error = res;
        throw error;
      }
    } catch (error) {
    }

  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //alert(this.state.fields["email"]);
    //Name
    if (!this.state.fields["notes"]) {
      formIsValid = false;
      errors["notes"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  createpostsubmit() {
    if (this.handleValidation()) {
      // alert("Form submitted");
      //////////////////////////////////////////////////////////////////////
      fetch(base.BASE_URL + '/sendemail', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          notes: this.state.fields["notes"],
          email: this.state.email,
          id: this.state.id,
          toemailid: this.state.toemailid
        })
      }).then((Response) => Response.json())
        .then((Result) => {
        //  alert(Result.message);
          if (Result.message == 'success') {
            alert('Email has been submited successfully');
            this.setState({ loggdin: true, insertmessage: 'save' })
          }
          else if (Result.message == 'expaired') {
            alert('Purchase Premium package to send unlimited message');
            this.setState({ loggdin: true, insertmessage: 'expair' })
          }
          else {
            alert('Failed to post')
          }

        })

      /////////////////////////////////////////////////////////////////////
    } else {
      // alert("Form has errors.")
    }


  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  /*   EditData(result) {
      alert(result.email);
      this.setState({ name: result.name, address: result.address, contact: result.contact, 
        email: result.email, id: result._id, Buttontxt: 'Update' });
    }
   */
  render() {
//insertmessage


if (this.state.insertmessage == 'save') {
    return <Redirect to="/Findpeople" />
  }
  if (this.state.insertmessage == 'expair') {
    return <Redirect to="/upgrade" />
  }
  
    return (
      <div>
        <Header />
        {/* Main Contents */}
        <div className="main_content">
          <div className="mcontainer">
          <Form>
            <div className="" style={{ backgroundColor: '#fff' }}>
              <div className="text-center py-3 border-b">
                <h3 className="text-lg font-semibold" style={{ textAlign: 'left', marginLeft: 10 }}>
                 Send Message               {/*  {this.state.currentdate}  */} </h3>
                 {
                   this.state.statuspayment == 'expair' ?
                   <div style={{ textAlign: 'left', marginLeft: 15 }}><Link to="/upgrade" className='is_link'>Purchase Package</Link></div>
                   :
                   <div><h3 className="text-lg font-semibold">Premium Member Expairy Date : {this.state.memberexpairdate}</h3></div>
                 }
               
                <button className="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" />
              </div>
              <div className="flex flex-1 items-start space-x-4 p-5">

                {/*     <img src="assets/images/avatars/avatar-2.jpg" 
    className="bg-gray-200 border border-white rounded-full w-11 h-11" /> */}

                    <img src={`${base.IMAGE_URL}/${this.state.com_logo}`} className="bg-gray-200 border border-white rounded-full w-11 h-11" />

                <div className="flex-1 pt-2">

                {
                   this.state.statuspayment == 'expair' ?
                   <div style={{ textAlign: 'left', marginLeft: 15 }}><Link to="/upgrade" className='is_link'>Purchase Package to send unlimited Message </Link></div>
                   :
                   
                   <textarea className="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none"
                   rows={5} placeholder="Write Something here about him/her" autoFocus
                   onChange={this.handleChange.bind(this, "notes")} value={this.state.fields["notes"]}

                 />
                 }
               
                
                  <span style={{ color: "red" }}>{this.state.errors["notes"]}</span>
                </div>
              </div>
              <div className="bsolute bottom-0 p-4 space-x-4 w-full">

              </div>
              <div className="flex items-center w-full justify-between border-t p-3">
                {/*                 <select className="selectpicker mt-2 story">
                  <option>Only me</option>
                  <option>Every one</option>
                  <option>People I Follow </option>
                  People Follow Me
                </select>  */}
{/*                 <div className="flex space-x-2">
               
                  <a href="#" className="bg-red-100 flex font-medium h-9 items-center justify-center px-5 rounded-md text-red-600 text-sm">
                    <svg className="h-5 pr-1 rounded-full text-red-500 w-6 fill-current" id="veiw-more" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style={{}}> <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                    Live </a>
                  <a href="#" className="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white px-5 font-medium">
                    Share </a>
                </div>
                <a href="#" hidden className="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
                  Share </a> */}
 {
                   this.state.statuspayment == 'expair' ?
                   ''
                   :
                   <Button type="button" className="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold"

                   onClick={this.createpostsubmit}>
                   Send </Button>
                 }


              </div>
            </div>

            </Form>



          </div>
        </div>
      </div>
    );
  }
}
