import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter, router, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router';



const Singlemomdating = () => {
	return (
		<div style={{ width: '100%', backgroundColor: '#dddede' }}>
           <div className="bg-white py-4 shadow dark:bg-gray-800">
            <div className="max-w-6xl mx-auto">
              <div className="flex items-center lg:justify-between justify-around">
                <Link to="/">
                  <img src="./assets/images/logo.png" alt="" class="w-32" />
                </Link>
                <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                  <Link to="/Login" className="py-3 px-4"> Login</Link>
                  <Link to="/Registration" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                    style={{ backgroundColor: '#b50a0a' }}
                  >Register</Link>
                </div>
              </div>
            </div>
          </div>


            <h1 style={{ fontSize: 18,  }}>Top 10 free dating</h1>
		</div>

	);
};
export default Singlemomdating;
