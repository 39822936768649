import React, { useEffect, useState } from "react";
import './App.css';
import Home from './page/Home'
import Registration from './page/Registration'
import Dashboard from './page/Dashboard'
import Profile from './page/profile'
import Findpeople from './page/findpeople'
import Createpost from './page/createpost'
import Course from './page/course'
import Jobs from './page/jobs'
import Games from './page/games'
import Video from './page/video'
import Mybilling from './page/mybilling'
import Upgrade from './page/upgrade'
import Editprofile from './page/editprofile'
import Myphotoupload from './page/myphotoupload'
import Chat from './page/chat'
import Chatanother from './page/Chatanother'
import Viewinformation from './page/viewinformation'
import comments from './page/comments'
import Aboutme from './page/aboutme'
import Seeallfriend from './page/seeallfriend'
import Changepassword from './page/changepassword'
import Login from './page/Login'
import Findbyname from './page/Findbyname'
import Forgetpassword from './page/Forgetpassword';
import Payment from './page/payment'
import Inbox from './page/Inbox'
import Profilevisitor from './page/Profilevisitor'
import Photopost from './page/Photopost'
import Myphotoshow from './page/Myphotoshow'
import Verifyemail from './page/Verifyemail'
import Sendemail from './page/Sendemail'
import AddMyvideo from './page/AddMyvideo'
import Dashboradimage from './page/Dashboradimage'
import Findbyage from './page/Findbyage'
import Notificationcheck from './page/Notificationcheck'
import Videoupload from './page/Videoupload'
import Paypalpage from './page/Paypalpage'
import Paymentmethod from './page/Paymentmethod'
import Paymentsuccessfully from './page/Paymentsuccessfully'
import Viewpostpage from './page/Viewpostpage'
import Paymentvisa from './page/Paymentvisa'
import Visasuccess from './page/Visasuccess'
import Mybillingdetails from './page/Mybillingdetails'
import Likes from './page/Likes'
import Findfavourites from './page/Findfavourites'

import Contactus from './page/web/Contactus'
import Blog from './page/web/Blog'
import Aboutus from './page/web/Aboutus'
import Privatepolicy from './page/web/Privatepolicy'
import Freedatingwebsiteindenmark from './page/web/freedatingwebsiteindenmark'
import Freedating from './page/web/Freedating'
import Datingsitesforsingle from './page/web/Datingsitesforsingle'
import Top10freedating from './page/web/Top10freedating'
import Bfreeseriousrelations from './page/web/Bfreeseriousrelations'
import BFMarriageseekers from './page/web/BFMarriageseekers'
import BFDatingsite from './page/web/BFDatingsite'
import Oldmendatingwebsite from './page/web/Oldmendatingwebsite'
import Singlemomdating from './page/web/Singlemomdating'
import Percentdatingwebsite from './page/web/Percentdatingwebsite'
import Dashgeneralmember from './page/Dashgeneralmember'
import Loginwithfacebook from './page/Loginwithfacebook'
import Testturorial from './page/Testturorial'
import Uploadpage from './page/Uploadpage'
import Uploadprofile from './page/Uploadprofile'
import Adminlogin from './page/admin/AdminLogin'
import Admindashboard from './page/admin/Admindashboard'
import Post from './page/admin/Post'
import GoogleLogin from './page/GoogleLogin'
//import Testpage from './page/Testpage'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import OneSignal from 'react-onesignal';

//firebase for get token and read message
import { messaging, onMessageListener, RequestForToken } from './page/firebase';
import { getToken, getMessaging } from "firebase/messaging";
//import { onMessageListener } from "./page/firebase";
import {Button, Row, Col, Toast} from 'react-bootstrap';


function App() {
    // this is for notification
    const [notification, setNotification] = useState({title: '', body: ''}); 
    // this is for notification
    const [show, setShow] = useState(false);

    async function requestPermission() {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          // Generate Token loveforyou in firebase
          const token = await getToken(messaging, {
            vapidKey:
              "BNZtC28oF64ZvDemWgPaVmUS5lalNGsLOJE8xf6Pol2cS-oCv01r-SCoCHzuwdmYXUetg8z5aHhkdRzQsy5nIME",
          });
          console.log("Token Gen", token);
          localStorage.setItem("pushtoken", token)
         // onMessageListener();
          // Send this token  to server ( db)
        } else if (permission === "denied") {
          alert("You denied for the notification");
        }
      }

    /*   onMessageListener()
      .then((payload) => {
        setNotification({title: payload?.notification?.title, body: payload?.notification?.body});  
        alert(payload.notification.title)   
      })
      .catch((err) => console.log('failed: ', err)); */
   /*    onMessageListener()
      .then((payload) => {
        setShow(true);
        alert(payload.notification.title)
         setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        }); 
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err)); */

   

    useEffect(() => {
        requestPermission();
        OneSignal.init({
            appId: "cd5fd70e-1e88-4e95-b021-7cce639b4b2f"
        });

//alert(notification.title)



    }, []);

    return (
        
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/registration">
                    <Registration />
                </Route>
                <Route exact path="/Login">
                    <Login />
                </Route>

                <Route exact path="/Loginwithfacebook">
                    <Loginwithfacebook />
                </Route>
                
                {/* 
      <Route exact path="/Paypalpage">
          <Paypalpage />
      </Route>
 */}

                <Route exact path="/Paypalpage" component={Paypalpage}>
                    <Paypalpage />
                </Route>

                
                <Route exact path="/Paymentsuccessfully" component={Paymentsuccessfully}>
                    <Paymentsuccessfully />
                </Route>

                <Route exact path="/Viewpostpage" component={Viewpostpage}>
                    <Viewpostpage />
                </Route>

                

                <Route exact path="/Forgetpassword">
                    <Forgetpassword />
                </Route>
                
                <Route exact path="/Findfavourites/:id" component={Findfavourites}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/Paymentmethod/:id" component={Paymentmethod}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/Visasuccess/:id/:ids" component={Visasuccess}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

                <Route exact path="/Mybillingdetails/:id" component={Mybillingdetails}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                

                

                <Route exact path="/Inbox">
                    <Inbox />
                </Route>
                <Route exact path="/Profilevisitor">
                    <Profilevisitor />
                </Route>
                <Route exact path="/Photopost">
                    <Photopost />
                </Route>
                <Route exact path="/Myphotoshow">
                    <Myphotoshow />
                </Route>

                <Route exact path="/Dashboradimage">
                    <Dashboradimage />
                </Route>

                <Route exact path="/Videoupload">
                    <Videoupload />
                </Route>



                {/*       <Route exact path="/Autocomplete">
          <Autocomplete />
      </Route>     */}

                <Route exact path="/Dashboard">
                    <Dashboard />
                </Route>

                <Route exact path="/Sendemail/:id" component={Sendemail}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

                
                <Route exact path="/Paymentvisa">
                    <Paymentvisa />
                </Route>


                <Route exact path="/Findbyage/:frmid/:toid" component={Findbyage}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

                <Route exact path="/Profile">
                    <Profile />
                </Route>


                <Route exact path="/Notificationcheck">
                    <Notificationcheck />
                </Route>

                <Route exact path="/AddMyvideo">
                    <AddMyvideo />
                </Route>


                <Route exact path="/Findpeople">
                    <Findpeople />
                </Route>

                <Route exact path="/Createpost">
                    <Createpost />
                </Route>
                <Route exact path="/Course">
                    <Course />
                </Route>

                <Route exact path="/Jobs">
                    <Jobs />
                </Route>

                <Route exact path="/Games">
                    <Games />
                </Route>
                <Route exact path="/Video">
                    <Video />
                </Route>
                <Route exact path="/Mybilling">
                    <Mybilling />
                </Route>
                <Route exact path="/Upgrade">
                    <Upgrade />
                </Route>
                <Route exact path="/Editprofile">
                    <Editprofile />
                </Route>
                <Route exact path="/myphotoupload">
                    <Myphotoupload />
                </Route>
                <Route exact path="/aboutme">
                    <Aboutme />
                </Route>
                <Route exact path="/Seeallfriend">
                    <Seeallfriend />
                </Route>
                <Route exact path="/Changepassword">
                    <Changepassword />
                </Route>


                <Route exact path="/Payment/:id" component={Payment}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/Chatanother/:id" component={Chatanother}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/Chat/:id" component={Chat}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/Verifyemail/:id" component={Verifyemail}>
                    {/*  <Add_new_product_edit/> */}
                </Route>



                <Route exact path="/comments/:id" component={comments}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

                <Route exact path="/Likes/:id" component={Likes}>
                    {/*  <Add_new_product_edit/> */}
                </Route>


                
                <Route exact path="/Findbyname/:id" component={Findbyname}>
                    {/*  <Add_new_product_edit/> */}
                </Route>



                <Route exact path="/Contactus">
                    <Contactus />
                </Route>

                <Route exact path="/Aboutus">
                    <Aboutus />
                </Route>

                <Route exact path="/Blog">
                    <Blog />
                </Route>
                <Route exact path="/Testturorial">
                    <Testturorial />
                </Route>
                <Route exact path="/Uploadpage">
                    <Uploadpage />
                </Route>

                <Route exact path="/Uploadprofile">
                    <Uploadprofile />
                </Route>
                          
                              
                
                <Route exact path="/Privatepolicy">
                    <Privatepolicy />
                </Route>
                
                <Route exact path="/free-dating-website-in-denmark">
                    <Freedatingwebsiteindenmark />
                </Route>
               {/*  <Route exact path="/free-dating"> */}
                <Route exact path="/Freedating/:id/:seourl" component={Freedating}>
                   
                </Route>
                <Route exact path="/Viewinformation/:id/:firstname/:lastname" component={Viewinformation}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/dating-sites-for-single">
                    <Datingsitesforsingle />
                </Route>

                <Route exact path="/top-10-free-dating">
                    <Top10freedating />
                </Route>

                <Route exact path="/best-free-dating-sites-for-serious-relations">
                    <Bfreeseriousrelations />
                </Route>
                <Route exact path="/best-for-marriage-seekers">
                    <BFMarriageseekers />
                </Route>
                <Route exact path="/Best-Free-Dating-sites">
                    <BFDatingsite />
                </Route>
                <Route exact path="/old-men-dating-website">
                    <Oldmendatingwebsite />
                </Route>                  
                <Route exact path="/single-mom-dating">
                    <Singlemomdating />
                </Route>                      
                <Route exact path="/100-percent-dating-website">
                    <Percentdatingwebsite />
                </Route> 

                <Route exact path="/adminlogin">
                <Adminlogin />
                </Route>   

                <Route exact path="/Admindashboard">
                <Admindashboard />
                </Route>  
                <Route exact path="/Post">
                <Post />

                
                </Route>  

                <Route exact path="/GoogleLogin">
                <GoogleLogin />
                </Route> 
                          
 


            </Switch>
        </Router>
    );
}

export default App;
