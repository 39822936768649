import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import * as base from "../page/global";
import axios from 'axios';

export class Headerchat extends Component {
  constructor(props) {
    super(props);
    this.flogout = this.flogout.bind(this);
    this.state = {
      emailaddress: localStorage.getItem("emailaddress"),
      datayourmatch: [],
      datauserprofile: [],
      image: 'maleavter.png',
      expairdate:'',
      profileimage:''
    }
   
  }
  flogout() {
    const token = localStorage.removeItem("token");
    const emailaddress = localStorage.removeItem("emailaddress");
    this.state = {
      emailaddress: emailaddress
    }

  }
  componentDidMount() {
   //  alert(this.state.emailaddress);
    axios.get(base.BASE_URL + '/frontuserfive').then(res => {
      this.setState({ datayourmatch: res.data });
    });

    axios.get(base.BASE_URL + '/viewprofile/' + this.state.emailaddress).then(res => {
     // alert(res.data[0]["memberexpairdate"]);
      this.setState({ datauserprofile: res.data, expairdate :  res.data[0]["memberexpairdate"], profileimage :  res.data[0]["com_logo"]});
    });
  }
  render() {
    const { profileuser } = this.props;
        if (this.state.emailaddress == null) {
          return <Redirect to="/" />
        }
        if (this.state.expairdate == null) {
          return <Redirect to="/upgrade" />
        }  
    return (
      <div>

        <header>
          <div className="header_wrap">
            <div className="header_inner mcontainer">
              <div className="left_side">

                <div id="logop">

                  <Link to="/dashboard"> 
                  <img src={`${base.IMAGE_URL}/${profileuser.com_logo}`} className="w-full h-full object-cover absolute"
                 style={{ width: 35, height: 35, borderRadius: 15, marginTop: -15  }}
                 /> 
                  </Link>
                </div>
                <div style={{ marginLeft: 36, width: 199}}>
                {profileuser.firstname} {profileuser.lastname}
                </div>
              </div>

              <div className="right_side">

                <div id="logo">
                  <Link to="/dashboard">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>


      </ div>
    )
  }
}

export default Headerchat