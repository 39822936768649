import React, { Component } from 'react';
import * as base from "./global";
import axios from 'axios';
import { Link } from 'react-router-dom';
export default class Vipdata extends Component {
  constructor(props) {
    super(props);
    this.state = {
        datafiveuser: [],
        page: 1,
    };
  }


  componentDidMount() 
  {

   axios.get(base.BASE_URL + '/vipdata').then(res => {
     this.setState({ datafiveuser: res.data }); //vipdata
   });

 }


  render() {
    return (
      <div>
<div className="user_story grid md:grid-cols-5 grid-cols-3 gap-2 lg:-mx-20 relative">
                  {this.state.datafiveuser.map((result) => {
                    return (
                      <Link to={`/viewinformation/${result.id}/${result.firstname}/${result.lastname}`}>
                        <div className="single_story">
                           
                           <img src={`${base.IMAGE_URL}/${result.com_logo}`}

                          /> 
                          <div className="story-avatar">

                            <h3 style={{
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat', backgroundColor: 'red', borderRadius: '50%', color: 'white', padding: 10
                              }}>VIP</h3>

                          </div>
                          <div className="story-content"> <h4> {result.firstname}</h4> </div>
                        </div>
                      </Link>
                    )
                  })}
                 <Link to="/findpeople">
                  <span className="absolute bg-white lg:flex items-center justify-center p-2 rounded-full 
                    shadow-md text-xl w-9 z-10 uk-position-center-right -mr-4 hidden">
                    <i className="icon-feather-chevron-right" /></span>
                    </Link>
</div>
      </div>
    );
  }
}
